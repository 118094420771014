import React from 'react';
import TagProductAndCompany from './TagProductAndCompany';
import { Dropdown, Button, Modal, Form } from 'semantic-ui-react';
import { apiStatus } from '../../common/constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { commonActions } from '../../actions';
import { Col, Row, SvgIcon } from '../common';

class TagModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false
        }
    }

    componentDidUpdate() {
        if (this.props.common.saveStatus === apiStatus.COMPLETED) {
            this.setState({ modalOpen: false });
            this.props.setSaveStatus(apiStatus.UNDEFINED)
            this.refreshTagData(true);        
        }
    }
    
    refreshTagData = (flag) => {
        if (this.props.refreshTagData!= undefined){
            this.props.refreshTagData(flag);
        }
    }
    
    handleClose = () => {
        this.setState({
            modalOpen: false
        })
    }

    handleOpen = () => {
        const { auth, showLoginModal } = this.props;
        if (auth) {
            this.setState({
                modalOpen: true
            })
        } else {
            showLoginModal(true)
        }
    }

    setTaggedProducts = (primaryProducts) => {
        this.setState({ primaryProducts })
    }

    setTaggedCompanies = (exhibitors) => {
        this.setState({ exhibitors })
    }

    tagProductsOrExhibitors = () => {
        const { primaryProducts, exhibitors } = this.state;
        const { searchType, tagProductsAndCompanies, eventId, addExhibitors } = this.props;
        if (searchType === "product") {
            tagProductsAndCompanies(eventId, { primaryProducts });
        }
        else {
            addExhibitors(eventId, { exhibitors });
        }
    }

    render() {
        const { searchType } = this.props;
        const modalHeader = searchType === "product" ? "Tag Products" : "Tag exhibitors";
        const searchLabel = searchType === "product" ? "Add Products" : "Add Exhibitors";
        const constMessage = searchType === "product" ? "Select Product(s) which are a part of this event" : "Select Exhibitor(s) which are a part of this event";
        return (
            <Modal scrolling={false} className='tagscp-modal' open={this.state.modalOpen} onClose={this.handleClose} size={'small'}
                trigger={<Dropdown.Item onClick={this.handleOpen} className="dropDownThreeDots" text={modalHeader} />}>
                <Modal.Header>{modalHeader} <div className='closeicon-header' onClick={this.handleClose}><SvgIcon name='close-alt' viewbox="0 0 15 15.001" /></div> </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Form>
                            <Row>
                                <Col sm='12' className='mb-2'>
                                    <Form.Field>
                                        <label className='mb-1'>{searchLabel}</label>
                                        <p className='sub-title mt-0'>{constMessage}</p>
                                        <TagProductAndCompany
                                            searchType={searchType}
                                            setTaggedProducts={this.setTaggedProducts}
                                            setTaggedCompanies={this.setTaggedCompanies}
                                        />
                                    </Form.Field>
                                </Col>
                            </Row>
                        </Form>

                        <div className="text-center pt-4 mb-2 mt-auto">
                            <Button className='mr-2' loading={this.props.saveStatus === apiStatus.IN_PROGRESS} primary onClick={this.tagProductsOrExhibitors} >
                                Submit
                            </Button>
                            <Button onClick={this.handleClose} >
                                Cancel
                            </Button>
                        </div>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators({...commonActions }, dispatch) }
}
function mapStateToProps({ auth, common  }) {
    return { auth, common };
}


export default connect(mapStateToProps, mapDispatchToProps)(TagModal);;