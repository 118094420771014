import React, { Component } from 'react';
import Avatar from '../common/Avatar';
import { List, Segment } from 'semantic-ui-react';
import * as actions from '../../actions';
import './messagenew.scss';
import { connect } from 'react-redux';
import { formatIsoMiliseconds } from '../../common/helperMethods';
import qs from 'querystring';

class ChatRooms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rooms: Object.entries((this.props.auth && this.props.auth.chats) || {}),
            selectedConversation: 0
        }
    }

    componentDidMount() {
        const rooms = [...this.state.rooms];
        const query = qs.parse(this.props.location.search.slice(1));
        const { userId, userName } = query;
        let newRoom;
        if (userId && userName) {
            newRoom = [userId, { name: userName }];
            rooms.splice(0, 0, newRoom);
            this.setState({ rooms })
            this.showConversation(newRoom);
        }
    }
    renderChatImage(room) {
        return <Avatar id={room[0]} />;
    }

    renderChatName(room) {
        let date;
        if (room[1].lastRead) {
            date = formatIsoMiliseconds(room[1].lastRead)
        }
        else if (room[1].timestamp) {
            date = formatIsoMiliseconds(room[1].timestamp)
        }
        else {
            date = formatIsoMiliseconds(Date.now())
        }
        return <List.Header as='a'>{room[1].name}<small>{date}</small></List.Header>
    }

    showConversation = (room) => {
        this.setState({ currentChat: room[0] })
        this.props.showConversation(room);
    }

    render() {
        const {rooms}=this.state;
        return (
            <List className="mt-1">
                {
                    rooms && rooms.length > 0 ?
                        rooms
                            .sort((a, b) => b[1].timestamp - a[1].timestamp)
                            .map((r, i) => {
                                return (
                                    <List.Item key={i} className={`cursor-pointer ${this.state.currentChat === r[0] ? 'active' : ''}`} onClick={() => this.showConversation(r)}>
                                        {this.renderChatImage(r)}
                                        <List.Content className="dropdown-content">
                                            {this.renderChatName(r)}
                                        </List.Content>
                                    </List.Item>
                                );
                            })
                        :
                        <Segment basic textAlign="center">
                            <h4>There are no messages at this time</h4>
                        </Segment>
                }
            </List >
        )
    }
}

function mapStateToProps({ auth, user, chatUser }) {
    return { auth, user, chatUser };
}

export default connect(mapStateToProps, actions)(ChatRooms);