import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../config/config'

import { Label, Search } from 'semantic-ui-react';
import * as actions from '../../actions';
import { connect } from 'react-redux';

class CompanySearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            company: this.props.initialValue,
            errors: []
        };
    }

    handleSearchChange = (e, { value }) => {
        if (!value) {
            this.setState({ company: '', companyId: '', country: '', disableCountrySelect: false });
            this.props.onSelect({ company: '', companyId: '', country: '',});
            return;
        }
        this.setState({ company: value });
        if (value.length > 4) {
            this.setState({ isLoading: true })
            axios.get(`${BASE_URL}/api/v1/company/search/${value}`, { withCredentials: true })
                .then(response => {
                    this.setState({
                        isLoading: false,
                        results: response.data
                    })
                })
        }
    }

    handleResultSelect = (e, data) => {
        const { name, id, country } = data.result._source;
        this.setState({ company: name, companyId: id, country: country, disableCountrySelect: true })
        this.props.onSelect({ company: name, companyId: id, country: country});

        // let errors = [...this.state.errors];

        // let index = errors.indexOf("company");
        // errors.splice(index, 1);
        // index = errors.indexOf("country");
        // errors.splice(index, 1);
        // this.setState({ errors });
    }

    resultRenderer = result => {
        if (result && result._source && result._source.id)
            return (
                <div key={result._source.id}>
                    <Label content={result._source.name} />
                    <Label style={{ float: 'right' }} content={result._source.country} />
                </div>
            );
    };

    render() {
        const { isLoading, results, //errors, 
            company } = this.state;
        return (
            <Search
                fluid
                loading={isLoading}
                placeholder="Company Name*"
                showNoResults={false}
                onResultSelect={this.handleResultSelect}
                onSearchChange={this.handleSearchChange}
                results={results}
                value={company}
                resultRenderer={this.resultRenderer}
                className="w-100"
            />
        );
    }
}

function mapStateToProps({ user }) {
    return { user };
}

export default connect(
    mapStateToProps,
    actions,
)(CompanySearch);
