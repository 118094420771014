import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../../actions';
import {
    Container,
    Sidebar,
    Search,
    Label,
    Icon,
    Grid,
    Header,
    Button,
    Image,
    Menu,
    Segment,
    Responsive,
    Transition,
    Dropdown,
    List,
} from 'semantic-ui-react';
import mappesLogo from '../../mappesLogo-white.svg';
import linkedin from '../../linked-in.png';
import { BASE_URL, FRESH_CHAT_TOKEN } from '../../../../config/config';
import axios from 'axios';
import notificationTemplate from '../../../notifications/templates';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BottomPopup } from './BottomPopup';
import { replaceSpaceInName } from '../../../../common/helperMethods';
/* import { Freshchat } from 'reactjs-freshchat';
import 'reactjs-freshchat/dist/index.css' */
import { isDesktop, isMobile } from 'react-device-detect';
import SvgIcon from '../../svg-icon/svg-icon';
import '../../style.css';
import SweetAlerts from '../../sweetalerts/sweetalert';

import userProfileImg from '../../../../assets/images/user-avatar.png';
import AddNewProduct from '../../AddProduct/AddNewProduct';
import AddNewCompany from '../../../productpage_wip/suppliers/AddNewCompany';
import './footernav.scss';

import BottomMenuBar from './bottomMenuBar';
import MediaQuery from 'react-responsive';
import MobileSideBar from './MobileSideBar';
import GlobalSearch from './gloabalSearch';

/* const resultRenderer = result => {
    if (result && result._source && result._source.id)
        return (
            <div key={result._source.id}>
                {result._index === 'products' && <Label content={result._source.name} />}
                {result._index !== 'products' && result._source.country == undefined && <Label content={result._source.name} />}
                {result._index !== 'products' && result._source.country != undefined && <Label content={result._source.name + ' (' + result._source.country + ')'} />}
                {result._index === 'products' && <Label style={{ float: 'right' }} content={'Product'} />}
                {result._index !== 'products' && <Label style={{ float: 'right' }} content={'Company'} />}
            </div>
        );
}; */

/* const mixResultRenderer = result => {
    if (result.id) {
        return (
            <div key={result.id}>
                {result._index === 'products' && <Label content={result.name} />}
                {result._index === 'company' && result.country == undefined && <Label content={result.name} />}
                {result._index === 'company' && result.country != undefined && <Label content={result.name + ' (' + result.country + ')'} />}
                {result._index === 'products' && <Label style={{ float: 'right' }} content={'Product'} />}
                {result._index === 'company' && <Label style={{ float: 'right' }} content={'Company'} />}
            </div>
        );
    }
}; */



const options = [
    { key: 1, text: 'By Company', value: 'company' },
    { key: 2, text: 'By Product', value: 'product' },
];

class NavbarLoggedIn extends Component {
    constructor(props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.setUserWrapperRef = this.setUserWrapperRef.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onClickUser = this.onClickUser.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.handleOutsideClickUser = this.handleOutsideClickUser.bind(this);
        this.state = {
            visible: false,
            openAddProduct: false,
            openAddCompany: false,
            product_submit: false,
            company_submit: false,
            activeIndex: '',
            profileOption: false,
            hidden: false,
            hiddenUser: false,
            notification: [],
            notificationsLoading: true,
            searchCategory: 'product',
            notificationPageNumber: 1,
            unreadMessages: false,
            company_id: '',
            company_name: '',
            followed_prod_count: 0,
            isActiveSerach: false,
            search_string: '',
            results: [],
            current_filter: '',
            // page_name: null,
        };
    }

    checkUnreadMessages() {
        if (!this.state.unreadMessages) {
            // chats = {"<user-id>": {name: "username", lastRead: <timestamp>, timestamp: <timestamp>}, "<user-id>": {...}, ...}
            const rooms = Object.entries((this.props.auth && this.props.auth.chats) || {});
            rooms.forEach(room => {
                if (!room[1].lastRead || room[1].lastRead < room[1].timestamp) {
                    this.setState({ unreadMessages: true });
                    return;
                }
            });
        } else {
            this.setState({ unreadMessages: false });
        }
    }

    componentWillMount() {
        this.resetComponent();
        this.checkUnreadMessages();
        if (this.props.auth) {
            this.getNotifications();
        }
    }
    componentWillReceiveProps(nextProps) {
        if (!this.props.auth && nextProps && nextProps.auth) {
            this.props.getUserInfo(nextProps.auth.id);
            this.getNotifications();
        }
        if (nextProps) {
            this.checkUnreadMessages();
        }
    }
    getNotifications() {
        axios
            .get(`${BASE_URL}/api/v1/user/notification/all`, {
                withCredentials: true,
            })
            .then(response => {
                // console.log(response);
                if (response && response.data && response.data.Items && response.data.Items.length > 0) {
                    this.setState({
                        notification: response.data.Items,
                        unreadNotifications: response.data.Items[0].added > (this.props.auth.last_read_notification || 0),
                    });
                }
                this.setState({ notificationsLoading: false });
                if (this.props.notificationClicked) {
                    this.setState({ unreadNotifications: false });
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({ notificationsLoading: false });
            });
    }
    renderNotifications() {
        if (this.state.notificationsLoading) {
            return (
                <List.Item key={0}>
                    <List.Content>
                        <List.Description>Loading Notifications ...</List.Description>
                    </List.Content>
                </List.Item>
            );
        }
        if (!this.state.notification || this.state.notification.length === 0) {
            return (
                <List.Item key={0}>
                    <List.Content>
                        <List.Description>There are no notifications at this time.</List.Description>
                    </List.Content>
                </List.Item>
            );
        }
        const { notificationPageNumber } = this.state;
        return this.state.notification.slice(0, notificationPageNumber * 10).map((n, i) => notificationTemplate(n, i));
    }

    getNextNotifications = () => {
        this.setState({ notificationPageNumber: this.state.notificationPageNumber + 1 });
    };

    resetComponent = () => this.setState({ isLoading: false, results: [], value: '' });

    /* handleResultSelect = (e, { result }) => {
        if (result._index === 'products') {
            this.props.history.push(`/product/${replaceSpaceInName(result._source.name)}/${result._source.id}`);
        } else {
            this.props.history.push(`/company/${replaceSpaceInName(result._source.name)}/${result._source.id}`);
        }
    };

    handleSearchChange = (e, { value }) => {
        this.setState({ value });
        if (value.length > 2) {
            const { searchCategory } = this.state;
            this.setState({ isLoading: true });

            axios.get(`${BASE_URL}/api/v1/${searchCategory}/search/${value}`, { withCredentials: true }).then(response => {
                this.setState({
                    isLoading: false,
                    results: response.data,
                });
            });
        }
    }; */





    /* handleMixResultSelect = (result) => {
        if (result._index === 'products') {
            this.props.history.push(`/product/${replaceSpaceInName(result.name)}/${result.id}`);
        } else {
            this.props.history.push(`/company/${replaceSpaceInName(result.name)}/${result.id}`);
        }
    }; */

    /* handleSearchCategoryChange = (e, { value }) => {
        this.setState({ searchCategory: value }, () => {
            this.handleSearchChange(null, { value: this.state.value });
        });
    }; */

    toggleVisibility() {
        this.setState({
            visible: !this.state.visible,
        });
    }

    renderProfileImage() {
        if (this.props.auth) {
            if (this.props.userProfileInfo) {
                return (
                    <Image className="avatar-36" avatar src={this.props.userProfileInfo.image} />
                )
            } else if (this.props.auth.image) {
                return (
                    <Image className="avatar-36" avatar src={this.props.auth.image} />
                )
            } else {
                return (
                    <Label circular color="blue" className="notification-initials">
                        {this.props.auth.displayName.substring(0, 1)}
                    </Label>
                )
            }
        }
    }

    updateLastReadNotification() {
        if (this.state.notification && this.state.notification.length > 0) {
            axios
                .post(
                    `${BASE_URL}/api/v1/user/notification/lastread`,
                    { timestamp: this.state.notification[0].added },
                    {
                        withCredentials: true,
                    },
                )
                .then(() => {
                    this.setState({ unreadNotifications: false });
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }
    onClick() {
        if (!this.state.hidden) {
            document.addEventListener('click', this.handleOutsideClick, false);
            this.updateLastReadNotification();
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
        this.setState(prevState => ({
            hidden: !prevState.hidden,
        }));
    }
    handleOutsideClick(e) {
        if (this.wrapperRef && this.wrapperRef.contains(e.target)) {
            return;
        }
        this.onClick();
    }
    onClickUser() {
        if (!this.state.hiddenUser) {
            document.addEventListener('click', this.handleOutsideClickUser, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClickUser, false);
        }
        this.setState(prevState => ({
            hiddenUser: !prevState.hiddenUser,
        }));
    }
    handleOutsideClickUser(e) {
        if (this.wrapperRefUser && this.wrapperRefUser.contains(e.target)) {
            return;
        }
        this.onClickUser();
    }
    setWrapperRef(node) {
        this.wrapperRef = node;
    }
    setUserWrapperRef(node) {
        this.setUserWrapperRef = React.createRef();
    }
    onClickNotification = () => {
        this.props.setNotificationClicked();
        this.onClick();
    };

    handleProductClose = () => {

        this.setState({ openAddProduct: false });
    }

    handleProductOpen = () => {
        this.setState({ openAddProduct: true });
    }

    handleProductSucess = () => {
        this.setState({ product_submit: true });
    }

    handleProductCloseAlert = () => {
        this.setState({ product_submit: false })
    }

    handleCompanyClose = () => {
        this.setState({ openAddCompany: false });
    }

    handleCompanyOpen = () => {
        this.setState({ openAddCompany: true });
    }

    handleCompanySucess = () => {

        this.setState({ company_submit: true });
    }

    handleCompanyCloseAlert = () => {
        this.setState({ company_submit: false })
        setTimeout(() => {
            this.props.history.push(`/company/${replaceSpaceInName(this.state.company_name)}/${this.state.company_id}`);
            this.setState({ company_id: '', company_name: '' });
        }, 500);
    }

    get_company_id = (company_id, company_name) => {
        this.setState({ company_id: company_id, company_name: company_name })
    }

    /* toggle_page = (page_name) => {
        this.setState({ page_name: page_name })
        
        setTimeout(() => {
            console.log("state page_name ", this.state.page_name)
        }, 200);
    } */



    render() {
        const { isLoading, value, results, hidden, hiddenUser, openAddProduct, openAddCompany, product_submit, company_submit, /* page_name */ current_filter } = this.state;
        const { auth } = this.props;
        const showUpgrade = auth && (!auth.isActive || auth.isActive && auth.stripeId && (auth.subscribedUsers ? auth.subscribedUsers.length < 4 : true))
        const { location } = this.props;
        return (
            <React.Fragment>
                {/* {isDesktop ? (
                    <><Freshchat token={FRESH_CHAT_TOKEN} open={false} /> </>
                ) : ''}

                {isMobile ? (
                    <><Freshchat token={FRESH_CHAT_TOKEN} open={false} /> </>
                ) : ''} */}
                <Sidebar.Pushable as={Segment} id="sidebar-pushable">
                    <Sidebar
                        as={Menu}
                        className="blue-color sidebar-menu"
                        animation="overlay"
                        width="wide"
                        direction="right"
                        visible={this.state.visible}
                        icon="labeled"
                        vertical>
                        {!this.props.auth && (
                            <Container>
                                <Grid padded>
                                    <Grid.Row>
                                        <Grid.Column width={12}>
                                            <Image src={mappesLogo} className="inline-image" width="120" />
                                        </Grid.Column>
                                        <Grid.Column textAlign="right" width={4}>
                                            <Icon className="sidebar-menu__toggle" name="cancel" onClick={this.toggleVisibility.bind(this)} />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid padded>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <h2 className="white-color intro-text-sidebar">
                                                Signup to the fastest growing product centric platform of the world
                                            </h2>
                                            <Header style={{ fontSize: 20, fontWeight: 500 }} className="white-color" size="medium">
                                                Sign in with{' '}
                                            </Header>
                                            <a href={`${BASE_URL}/auth/linkedin`}>
                                                <Image src={linkedin} size="small" />
                                            </a>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Container>
                        )}
                        {this.props.auth && (
                            <Container>
                                <Grid padded>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Image src={mappesLogo} className="inline-image" width="120" />
                                            <Icon className="sidebar-menu__toggle" name="cancel" onClick={this.toggleVisibility.bind(this)} />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <h2 className="white-color intro-text-sidebar">
                                                Welcome <div>{this.props.auth && this.props.auth.displayName}</div>
                                            </h2>
                                            <Button
                                                as={'a'}
                                                href={`${BASE_URL}/auth/logout`}
                                                onClick={() => sessionStorage.setItem('login', 0)}
                                                basic
                                                className="white-outline-btn">
                                                Logout
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Container>
                        )}
                    </Sidebar>
                    <Sidebar.Pusher>
                        <Menu className="blue-color topNav" fixed="top" size="massive" borderless={true}>
                            <Container>
                                {/* <Responsive as={Menu.Menu} minWidth={768} className="item navbar-logo">
                                    <Link id="navbar-logo" {...this.props.auth ? { to: "/networkFeed/global" } : { to: "/" }} >
                                        <Image src={mappesLogo} className="inline-image" width="120" />
                                    </Link>
                                </Responsive> */}
                                <div className="item navbar-logo">
                                    <a href="/">
                                        <i className="icon-mappers-logo-sm navbar-logo__i" />
                                    </a>
                                </div>


                                <GlobalSearch {...this.props} />

                                <Menu.Menu position="right" className="right-menu">
                                    {/*   {showUpgrade &&
                                        <Responsive minWidth={768} as={Menu.Menu} className="item">
                                            <Link className="white-color btn" to="/upgrade">
                                                <Button primary className="btn mr-3">UPGRADE</Button>
                                            </Link>
                                        </Responsive>
                                    } */}
                                    <Responsive as={Menu.Menu} minWidth={768} className='item'>
                                        <Link className={window.location.pathname === '/networkFeed/global' ? 'navbar-links active' : 'navbar-links'} to='/'>
                                            <div className='icon-row'>
                                                <SvgIcon name='home-icon' viewbox="0 0 20 22" />
                                            </div>
                                            <label>Home</label>
                                        </Link>
                                    </Responsive>
                                    {this.props.auth && (
                                        <Responsive as={Menu.Menu} minWidth={768} className="item">
                                            <div className={window.location.pathname === '/notifications' ? 'navbar-notification navbar-links active' : 'navbar-notification navbar-links'} size="small" onClick={this.onClick} >
                                                {this.state.unreadNotifications && (
                                                    <div className="notify">
                                                        <span className="heartbit"></span>
                                                        <span className="point"></span>
                                                    </div>
                                                )}
                                                <div className="notify">
                                                        <span className="heartbit"></span>
                                                        <span className="point"></span>
                                                    </div>
                                                <div className='icon-row'>
                                                    <SvgIcon name='bell-icon' viewbox="0 0 20 20" />
                                                </div>
                                                <label>Notifications</label>
                                            </div>

                                            <div ref={this.setWrapperRef}>
                                                <Transition visible={hidden} animation="fade down" unmountOnHide duration={300}>
                                                    <div className="dropdown-warapper">
                                                        <div className="dropdown-box">
                                                            <List relaxed verticalAlign="middle" id="test">
                                                                <InfiniteScroll
                                                                    dataLength={10 * this.state.notificationPageNumber} //This is important field to render the next data
                                                                    next={this.getNextNotifications}
                                                                    hasMore={true}
                                                                    scrollableTarget="test"
                                                                    // height={1000}
                                                                    loader={<h4>Loading...</h4>}
                                                                    endMessage={
                                                                        <p style={{ textAlign: 'center' }}>
                                                                            <b>Yay! You have seen it all</b>
                                                                        </p>
                                                                    }
                                                                >
                                                                    {
                                                                        this.renderNotifications()
                                                                    }
                                                                </InfiniteScroll>
                                                            </List>
                                                            <a href="/notifications" className="notification-loadmore">
                                                                <button className="ui primary button btn mr-3">Load More</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </Transition>
                                            </div>
                                        </Responsive>
                                    )}
                                    {this.props.auth && (
                                        <Responsive minWidth={768} className="item" as={Link} to={'/message'}>
                                            <div id="navbar-messages" className={window.location.pathname === '/message' ? 'navbar-links active' : 'navbar-links'}>
                                                {this.state.unreadMessages && (
                                                    <div className="notify">
                                                        <span className="heartbit"></span>
                                                        <span className="point"></span>
                                                    </div>
                                                )}
                                                <div className='icon-row'>
                                                    <SvgIcon name='chat-icon' viewbox="0 0 27 27" />
                                                </div>
                                                <label>Messages</label>
                                            </div>
                                        </Responsive>
                                    )}
                                    {/* <Responsive as={Menu.Item} className="navbar-profile-pic" maxWidth={767}>
                                        {this.renderProfileImage()}
                                    </Responsive> */}
                                    <MediaQuery maxWidth={767}>
                                        <Menu.Item className="navbar-profile-pic">
                                            <MobileSideBar {...this.props} />
                                        </Menu.Item>
                                    </MediaQuery>

                                    <MediaQuery minWidth={767}>
                                        <Menu.Item>
                                            <Dropdown
                                                // icon={!this.props.auth ?
                                                //     <SvgIcon name='menu-alt' viewbox="0 0 16 12" id="navbar-menu" onClick={this.toggleVisibility.bind(this)} />
                                                //     :
                                                //     <SvgIcon name='menu-alt' viewbox="0 0 16 12" id="navbar-menu" onClick={this.onClickUser} />
                                                // }
                                                icon={this.props.auth.image ? (
                                                    <div className='profile-avatar-top'>
                                                        <Image
                                                            className="avatar-32"
                                                            avatar
                                                            src={this.props.auth.image}
                                                        />
                                                        <label>Profile</label>
                                                    </div>
                                                ) : (
                                                    <div className='profile-avatar-top'>
                                                        <Image
                                                            className="avatar-32"
                                                            avatar
                                                            src={userProfileImg}
                                                        />
                                                        <label>Profile</label>
                                                    </div>
                                                )}
                                                className='user-profile-drop'>
                                                <Dropdown.Menu>
                                                    <List relaxed verticalAlign="middle">
                                                        <List.Item as={'a'} href="/profile">
                                                            <div className='left-icon'>
                                                                {this.props.auth.image ? (
                                                                    <Image
                                                                        className="avatar-32"
                                                                        avatar
                                                                        src={this.props.auth.image}
                                                                    />
                                                                ) : (
                                                                    <Image
                                                                        className="avatar-32"
                                                                        avatar
                                                                        src={userProfileImg}
                                                                    />
                                                                )}

                                                            </div>
                                                            <div className='right-dtl'>
                                                                <b>{this.props.auth.displayName}</b>
                                                                <p>View your profile</p>
                                                            </div>
                                                        </List.Item>
                                                        {openAddProduct &&
                                                            <AddNewProduct
                                                                open={openAddProduct}
                                                                handleClose={this.handleProductClose}
                                                                type="Product"
                                                                handlePSucess={this.handleProductSucess}
                                                            />
                                                        }
                                                        <List.Item as={'a'} onClick={this.handleProductOpen}>
                                                            <div className='left-icon'>
                                                                <SvgIcon name='technology-icon' viewbox="0 0 24 24" />
                                                            </div>
                                                            <div className='right-dtl'>
                                                                <b>Create a technology page</b>
                                                                <p>Add new technology or products</p>
                                                            </div>
                                                        </List.Item>
                                                        {openAddCompany &&
                                                            <AddNewCompany
                                                                open={openAddCompany}
                                                                handleClose={this.handleCompanyClose}
                                                                productId={''}
                                                                productName={''}
                                                                addAsSupplier={false}
                                                                handleCSucess={this.handleCompanySucess}
                                                                history={this.props.history}
                                                                company_id={this.get_company_id}
                                                            />
                                                        }
                                                        <List.Item as={'a'} onClick={this.handleCompanyOpen}>
                                                            <div className='left-icon'>
                                                                <SvgIcon name='company-icon' viewbox="0 0 24 24" />
                                                            </div>
                                                            <div className='right-dtl'>
                                                                <b>Create a Company page</b>
                                                                <p>Add a new company to the Mappes</p>
                                                            </div>
                                                        </List.Item>
                                                        {/* <List.Item as={'a'}>
                                                            <div className='left-icon'>
                                                                <SvgIcon name='quotation-icon' viewbox="0 0 24 24" />
                                                            </div>
                                                            <div className='right-dtl'>
                                                                <b>Get Quotation</b>
                                                                <p>Suprised to get your best deals</p>
                                                            </div>
                                                        </List.Item> */}
                                                        <List.Item as={'a'} href="/event-list">
                                                            <div className='left-icon'>
                                                                <SvgIcon name='blog-icon' viewbox="0 0 24 24" />
                                                            </div>
                                                            <div className='right-dtl'>
                                                                <b>Event</b>
                                                                <p>Explore technology events</p>
                                                            </div>
                                                        </List.Item>
                                                        <List.Item as={'a'} href="/aboutus">
                                                            <div className='left-icon'>
                                                                <SvgIcon name='about-icon' viewbox="0 0 24 24" />
                                                            </div>
                                                            <div className='right-dtl'>
                                                                <b>About us</b>
                                                                <p> Know more about Mappes</p>
                                                            </div>
                                                        </List.Item>
                                                        <List.Item as={'a'} href="/contact">
                                                            <div className='left-icon'>
                                                                <SvgIcon name='contact-icon' viewbox="0 0 24 24" />
                                                            </div>
                                                            <div className='right-dtl'>
                                                                <b>Contact Us</b>
                                                                <p>Need help on something?</p>
                                                            </div>
                                                        </List.Item>
                                                        <List.Item
                                                            className="color-light-blue"
                                                            as={'a'}
                                                            href={`${BASE_URL}/auth/logout`}
                                                            onClick={() => sessionStorage.setItem('login', 0)}>
                                                            <div className='left-icon'>
                                                                <SvgIcon name='logout-icon' viewbox="0 0 24 24" />
                                                            </div>
                                                            <div className='right-dtl'>
                                                                <b>Logout</b>
                                                            </div>
                                                        </List.Item>
                                                    </List>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Menu.Item>
                                    </MediaQuery>
                                    {
                                        company_submit == true && <SweetAlerts type={'S'} title={'Company has been added successfully'} message={'The company you submitted is undergoing a verification process and will be accessible to everyone once it receives approval'} button_text={'Okay'} closeAlert={this.handleCompanyCloseAlert}> </SweetAlerts>
                                    }
                                    {
                                        product_submit && <SweetAlerts type={'S'} title={'Product has been added successfully'} message={'The product you submitted is undergoing a verification process and will be accessible to everyone once it receives approval'} closeAlert={this.handleProductCloseAlert}> </SweetAlerts>
                                        /*  product_submit == true && <SweetAlerts type={'S'} title={'Success'} message={'Product added successfully'} closeAlert={this.handleProductCloseAlert}> </SweetAlerts> */
                                    }

                                    {/* <div ref={this.setUserWrapperRef}>
                                            <Transition 
                                            visible={hiddenUser} 
                                            animation="fade down" unmountOnHide duration={300}>
                                                <div className="user-profile-drop">
                                                    <List relaxed verticalAlign="middle">
                                                        <List.Item as={'a'} href="/profile">
                                                            <div className='left-icon'>
                                                                <Image
                                                                    className="avatar-32"
                                                                    avatar
                                                                    src={userProfileImg}
                                                                />
                                                            </div>
                                                            <div className='right-dtl'>
                                                                <b>Gourav Gupta</b>
                                                                <p>View your profile</p>
                                                            </div>
                                                        </List.Item>
                                                        <CreateProduct />
                                                        <List.Item as={'a'}>
                                                            <div className='left-icon'>
                                                                <SvgIcon name='company-icon' viewbox="0 0 24 24" />
                                                            </div>
                                                            <div className='right-dtl'>
                                                                <b>Create a Company page</b>
                                                                <p>Add a new company to the Mappes</p>
                                                            </div>
                                                        </List.Item>
                                                        <List.Item as={'a'}>
                                                            <div className='left-icon'>
                                                                <SvgIcon name='quotation-icon' viewbox="0 0 24 24" />
                                                            </div>
                                                            <div className='right-dtl'>
                                                                <b>Get Quotation</b>
                                                                <p>Suprised to get your best deals</p>
                                                            </div>
                                                        </List.Item>
                                                        <List.Item as={'a'}>
                                                            <div className='left-icon'>
                                                                <SvgIcon name='blog-icon' viewbox="0 0 24 24" />
                                                            </div>
                                                            <div className='right-dtl'>
                                                                <b>Blog</b>
                                                                <p>Know more about mappes.io</p>
                                                            </div>
                                                        </List.Item>
                                                        <List.Item as={'a'} href="/aboutus">
                                                            <div className='left-icon'>
                                                                <SvgIcon name='about-icon' viewbox="0 0 24 24" />
                                                            </div>
                                                            <div className='right-dtl'>
                                                                <b>About us</b>
                                                                <p>Know more about mappes.io</p>
                                                            </div>
                                                        </List.Item>
                                                        <List.Item as={'a'} href="/contact">
                                                            <div className='left-icon'>
                                                                <SvgIcon name='contact-icon' viewbox="0 0 24 24" />
                                                            </div>
                                                            <div className='right-dtl'>
                                                                <b>Contact Us</b>
                                                                <p>Need any help on something</p>
                                                            </div>
                                                        </List.Item>
                                                        <List.Item
                                                            className="color-light-blue"
                                                            as={'a'}
                                                            href={`${BASE_URL}/auth/logout`}
                                                            onClick={() => sessionStorage.setItem('login', 0)}>
                                                            <div className='left-icon'>
                                                                <SvgIcon name='logout-icon' viewbox="0 0 24 24" />
                                                            </div>
                                                            <div className='right-dtl'>
                                                                <b>Logout</b>
                                                            </div>
                                                        </List.Item>
                                                    </List>
                                                </div>
                                            </Transition>
                                        </div> */}
                                    <Responsive as={Menu.Menu} minWidth={768} className='raq-btn item'>
                                        <Button primary>Request a quote</Button>
                                    </Responsive>
                                </Menu.Menu>
                            </Container>
                        </Menu>

                        {this.props.children}
                        <BottomMenuBar
                            auth={this.props.auth}
                            history={this.props.history}
                            onClickNotification={this.onClickNotification}
                            unreadNotifications={this.state.unreadNotifications}
                        /* page={page_name}
                        toggle_page={this.toggle_page} */
                        />
                        {/* <Responsive as={Menu.Menu} maxWidth={767}>
                            <Menu fluid widths={4} borderless icon="labeled" className="nav-bottom">
                                {!this.props.auth && (
                                    <div className="d-flex valign-middle">
                                        <Button size="mini" primary as="a" href="/?login=true&signup=true">
                                            Sign Up
                                        </Button>
                                        <span className="d-flex mL-10 mR-10">Or</span>
                                        <Button size="mini" primary as="a" href="/?login=true">
                                            Log In
                                        </Button>
                                    </div>
                                )}
                                {this.props.auth && (
                                    <Menu.Item name="Home" as={Link} to={'/networkFeed/global/noop'}>
                                        <Icon name="bars" className="icon-mappes icon-news-feed" /> Network Feed
                                    </Menu.Item>
                                )}
                                {this.props.auth && (
                                    <Menu.Item name="Notifications" onClick={this.onClickNotification} as={Link} to={'/notifications'}>
                                        <Icon name="bell">
                                            {this.state.unreadNotifications && (
                                                <div className="notify">
                                                    <span className="heartbit"></span>
                                                    <span className="point"></span>
                                                </div>
                                            )}
                                        </Icon>
                                        Notifications
                                    </Menu.Item>
                                )}
                                {this.props.auth && (
                                    <Menu.Item name="My Products" as={Link} to={'/event-list'}>
                                        <SvgIcon name='blog-icon' viewbox="0 0 24 24" />
                                        Events
                                    </Menu.Item>
                                )}
                                {this.props.auth && (
                                    <Menu.Item name="Message" as={Link} to={'/message'}>
                                        <Icon name="wechat" >
                                            {this.state.unreadMessages && (
                                                <div className="notify">
                                                    <span className="heartbit"></span>
                                                    <span className="point"></span>
                                                </div>
                                            )}
                                        </Icon>
                                        Message
                                    </Menu.Item>
                                )}
                            </Menu>
                        </Responsive> */}
                    </Sidebar.Pusher>
                </Sidebar.Pushable >
                {/* <BottomPopup /> */}
            </React.Fragment>
        );
    }
}

function mapStateToProps({ auth, chatUser, user }) {
    return {
        auth,
        userProfileInfo: user.userInfoCollection.find(item => item.id === auth.id),
        chatUser,
        notificationClicked: user.notificationClicked,
    };
}

export default connect(mapStateToProps, actions)(NavbarLoggedIn);
