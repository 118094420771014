import React from 'react';
import { Tab } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions';
import { networkFeedActions, commonActions, userActions } from '../../actions';
import { connect } from 'react-redux';
import NetworkFeedLayout from '../networkFeed/NetworkFeedLayout';
import CustomersView from './customers/CustomersView';
import SuppliersView from './suppliers/SuppliersView';
import ProductInfo from './ProductInfo';
import RawMaterialsView from './rawMaterials/RawMaterialsView';
import ApplicationsView from './applications/ApplicationsView';
import { DataVisualizations } from './DataVisualizations';
import {
  Route,
  NavLink,
  Switch
} from "react-router-dom";

class ProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      activeClass: "normal"
    }
  }

  componentDidMount() {
    this.getData();
    document.getElementById("tabFixed").addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    document.getElementById("tabFixed").removeEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.props.resetProductData();
      const sidebar = document.getElementById('sidebar-pushable');
      if (sidebar) {
        sidebar.scrollTop = 0;
      }
      this.getData();
      this.setState({ activeIndex: 0 })
    }
  }

  getData() {
    var productId = this.props.match.params.id;
    this.props.getProductFeedUnauthenticated(productId);
    this.props.getProductSupplier(productId);
    this.props.getProductApplication(productId);
    this.props.getProductRawMaterial(productId);
    this.props.getPotentialCustomers(productId);
    if (this.props.auth) {
      this.props.getFollowedCompanies();
    }
  }

  handleScroll = () => {
    if (document.getElementById("tabFixed").scrollTop > 13) {
      this.setState({ activeClass: "fixTop" });
    } else {
      this.setState({ activeClass: "normal" });
    }
  };

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex : activeIndex })

  panes = () => {
    const { product, match } = this.props;
    const { id, name } = match.params;

    const suppliers = product.suppliers && product.suppliers.results;
    const supplierCount = product.suppliers && product.suppliers.count || 0;

    const customers = product.customer && product.customer.potentialCustomer
    const customersCount = customers && customers.length || 0;

    const rawMaterials = product.raw && product.raw.Items;
    const rawMaterialCount = rawMaterials && rawMaterials.length || 0;

    const applications = product.application && product.application.Items;
    const applicationsCount = applications && applications.length || 0;

    const prod_info = product.info && product.info.Item;

    return [
      {
        menuItem: { as: NavLink, content: 'Feed', id: "product-tech-update-tab", to: `/product/${name}/${id}/`, exact: true, key: "tech-update" },
        pane: (
          <Route
            path={`/product/${name}/${id}/`}
            exact
            render={() => (
              <Tab.Pane attached={false}>
                <NetworkFeedLayout entityId={id} feedType="product" history={this.props.history} />
              </Tab.Pane>
            )}
          />
        )
      },
      {
        menuItem: {
          as: NavLink,
          id: "product-feed-supplier-tab",
          content: `Suppliers (${supplierCount})`,
          to: `/product/${name}/${id}/supplier`,
          exact: true,
          key: "supppliers"
        },
        pane: (
          <Route
            path={`/product/${name}/${id}/supplier`}
            exact
            render={() => (
              <Tab.Pane attached={false}>
                <SuppliersView productId={id} suppliers={suppliers} history={this.props.history} />
              </Tab.Pane>
            )}
          />
        )
      },
     
      {
        menuItem: { as: NavLink, content: `Prospects (${customersCount})`, id: "product-prospects-tab", to: `/product/${name}/${id}/prospects`, exact: true, key: "prospects" },
        pane: (
          <Route
            path={`/product/${name}/${id}/prospects`}
            exact
            render={() => (
              <Tab.Pane attached={false}>
                <CustomersView customers={customers} history={this.props.history} />
              </Tab.Pane>
            )}
          />
        )
      },
      {
        menuItem: { as: NavLink, content: `Materials & Equipment (${rawMaterialCount})`, id: "product-materials-equipment-tab", to: `/product/${name}/${id}/rawMaterials`, exact: true, key: "rawMaterials" },
        pane: (
          <Route
            path={`/product/${name}/${id}/rawMaterials`}
            exact
            render={() => (
              <Tab.Pane attached={false}>
                <RawMaterialsView rawMaterials={rawMaterials} productId={id} />
              </Tab.Pane>
            )}
          />
        )
      },
      {
        menuItem: { as: NavLink, content: `Applications (${applicationsCount})`, id: "product-end-user-tab", to: `/product/${name}/${id}/endUse`, exact: true, key: "endUse" },
        pane: (
          <Route
            path={`/product/${name}/${id}/endUse`}
            exact
            render={() => (
              <Tab.Pane attached={false}>
                <ApplicationsView applications={applications} productId={id} />
              </Tab.Pane>
            )}
          />
        )
      },
      // {
      //     menuItem: 'Events/News',
      //     render: () => <Tab.Pane attached={false}>Events/News</Tab.Pane>,
      // },
      {
        menuItem: { as: NavLink, content: 'Value chain', id: "product-value-chain-tab", to: `/product/${name}/${id}/valueChain`, exact: true },
        pane: (
          <Route
            path={`/product/${name}/${id}/valueChain`}
            exact
            render={() => (
              <Tab.Pane attached={false}>
                <DataVisualizations
                  applications={applications}
                  applicationsCount={applicationsCount}
                  product={prod_info}
                />
              </Tab.Pane>
            )}
          />
        )
      },
    ];
  }

  render() {
    const { id, name } = this.props.match.params;
    const { followProduct, unfollowProduct, user, product, auth, submitInquiry, common, uploadProductImage } = this.props;
    const { activeIndex } = this.state;
    let productName, description;
    if (this.props.product.info) {
      description = (this.props.product.info.Item != undefined && this.props.product.info.Item.description != undefined) ? this.props.product.info.Item.description : '';
      productName = (this.props.product.info.Item != undefined && this.props.product.info.Item.name) ? this.props.product.info.Item.name : '';
    }
    const panes = this.panes();
    return (
      <>
        
        <ProductInfo
          product={product}
          id={id}
          name={name}
          auth={auth}
          user={user}
          history={this.props.history}
          followProduct={followProduct}
          unfollowProduct={unfollowProduct}
          submitInquiry={submitInquiry}
          saveStatus={common.saveStatus}
          uploadProductImage={uploadProductImage}
        />
        <div className="product-bottom">
          <div className="product-bottom-inner" id="tabFixed">
            <Switch>
              <Tab
                className="product-tabes"
                menu={{ secondary: true, pointing: true }}
                defaultActiveIndex={activeIndex}
                activeIndex={-1}
                renderActiveOnly={false}
                panes={panes}
                onTabChange={this.handleTabChange}
              />
            </Switch>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps({ singleProduct, networkFeed, common }) {
  return { product: singleProduct, networkFeed, common };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ ...actions, ...networkFeedActions, ...commonActions, ...userActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);