import React from 'react';
import { Button, Icon, Table, Select } from 'semantic-ui-react';
import PremiumTag from '../../../assets/images/premium-tag.svg';
import { Link } from 'react-router-dom';
import FollowButton from '../FollowButton';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { commonActions } from '../../../actions';
import { replaceSpaceInName, sortList, sortListByStringKey } from '../../../common/helperMethods';
import LoginButton from '../../common/LoginButton';
// import NoSuppliers from '../../assets/images/no-suppliers.png';

const sortByOptions = [
    { key: 'name', value: 'company_name', text: 'name' },
    { key: 'country', value: 'country', text: 'country' },
    { key: 'No. of contacts', value: 'contacts', text: 'contacts' }
]

class CustomersList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            resultsRendered: 4,
            loading: false,
            sortBy: "premium"
        }
    }

    handleMoreClick = () => {
        if (!this.props.auth) {
            this.props.showLoginModal(true);
        }
        else {
            this.setState({ loading: true });
            setTimeout(() => {
                this.setState({
                    resultsRendered: this.props.customers.length,
                    loading: false
                })
            }, 1000);
        }
    }

    setSortBy = (e, { value }) => {
        this.setState({ sortBy: value });
    }

    getSortedCustomerList = () => {
        const { customers } = this.props;
        const { sortBy } = this.state;
        let sortedList = [];
        if (sortBy === "premium") {
            sortedList = customers.sort((a, b) => (b.premium || false - a.premium || false) ||
                (a.company_name && b.company_name ? a.company_name.localeCompare(b.company_name) : -1)
            );
        }

        else if (sortBy === "country" || sortBy === "company_name") {
            sortedList = sortListByStringKey(customers, this.state.sortBy);
        }
        else {
            sortedList = sortList(customers, sortBy, "desc");
        }
        return sortedList;
    }

    render() {
        const { auth, customers } = this.props;
        if (!customers) return null;

        const { resultsRendered, loading } = this.state;
        const sortedList = customers && this.getSortedCustomerList().slice(0, resultsRendered);

        return (
            <React.Fragment>
                <div className="supplier-list">
                    <Table basic="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan='4'>
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex align-items-center">
                                            {/* <Checkbox className="mr-3" /> */}
                                            Sort By
                                        </div>
                                        <div className="filter-select">
                                            <Icon name="filter" />
                                            <Select defaultValue="country" options={sortByOptions} onChange={this.setSortBy} />
                                        </div>
                                    </div>
                                </Table.HeaderCell>
                                {/* <Table.HeaderCell colSpan='2' className="text-right">
                                    <Button primary basic className="py-2 px-3">Request Quote</Button>
                                </Table.HeaderCell> */}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {sortedList && sortedList.map(({ company_name, company_id, contacts, country, premium, logo, images, contactsDetails }, index) => {
                                if (index <= resultsRendered)
                                    return (
                                        <Table.Row key={company_id}>
                                            {/* <Table.Cell className="pl-2">
                                        <Checkbox />
                                    </Table.Cell> */}
                                            <Table.Cell>

                                                <div className="d-flex align-items-center supplier-details">
                                                    {logo ? <img className="logo-img" src={logo} alt="LOGO" />
                                                        : <div className="supplierdetails-circle">{company_name && company_name.substring(0, 1)}</div>}
                                                    <div className="pl-3">
                                                        <Link to={`/company/${replaceSpaceInName(company_name)}/${company_id}`}>
                                                            <p className="suppliername">{company_name}</p>
                                                        </Link>
                                                        <div className="addresses">{country}
                                                            {premium &&
                                                                <img style={{ width: 56, marginBottom: -3, marginLeft: 2 }} src={PremiumTag} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <ul className="contactlist">
                                                    {
                                                        images && images.map(image =>
                                                            <li><img src={image} /></li>
                                                        )
                                                    }
                                                    {
                                                        contactsDetails && contactsDetails.slice(0, 3 - images.length).map(contact =>
                                                            <li className='init-circles'>{contact.firstName.substring(0, 1)}</li>
                                                        )
                                                    }
                                                </ul>
                                            </Table.Cell>
                                            {contacts > 0
                                                && <Table.Cell>
                                                    <div className="d-flex">
                                                        {contacts}&nbsp;<span className="text-muted">contacts</span>
                                                    </div>
                                                </Table.Cell>
                                            }
                                            {contacts == 0
                                                && <Table.Cell>
                                                    <div className="d-flex">
                                                       &nbsp;
                                                    </div>
                                                </Table.Cell>
                                            }
                                            <Table.Cell className="follow-btn-right" style={{ width: 90 }}>
                                                {/* <Button primary size="mini" className="follow-btn-new"><SvgIcon name="plus" viewbox="0 0 23 23" /> Follow</Button> */}
                                                <FollowButton id={company_id} name={company_name} type="company" history={this.props.history} />
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                            })}
                            {
                                customers.length > resultsRendered &&
                                <Table.Row>
                                    <Table.Cell colSpan="4" className="text-center">
                                        {auth ?
                                            <Button className="color-light-blue w-100 text-center mt-2 cursor-pointer load_more" onClick={this.handleMoreClick} loading={loading} >
                                                See All
                                            </Button>
                                            :
                                            <LoginButton />
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            }
                        </Table.Body>
                    </Table>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps({ auth }) {
    return { auth };
}


function mapDispatchToProps(dispatch) {
    return {
        dispatch, ...bindActionCreators(commonActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomersList);
