import React from 'react'
import { Grid, Container, Responsive } from 'semantic-ui-react';
import RightSection from '../RightSection';
import Navbar from './navbar/Navbar';
import LeftSidebar from '../LeftSidebar';

const Layout = (props) => {
    return (
        <Navbar {...props}>
            <div id="HEADER" className="primary-background">
                <Container className="body-background palm-nudge-sides">
                    <Grid padded stackable>
                        <Responsive as={Grid.Column} className="left-content" minWidth={768}>
                            <LeftSidebar />
                        </Responsive>

                        <Grid.Column className="main-content-area">
                            <div className="main-content pD-b-50">
                                <div className="main-content_inner">
                                    {props.children}
                                </div>
                            </div>
                        </Grid.Column>
                        <RightSection entityType={props.entityType} entityId={props.entityId} />
                    </Grid>
                </Container>
            </div>
        </Navbar>
    );
}

export default Layout;