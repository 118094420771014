import { SAVE_STATUS, SHOW_LOGIN_MODAL, NAVIGATE_TO_PRICING, GET_STATUS, REGENERATE_OTP } from '../../config/types'

export default function (state = {}, action) {
    switch (action.type) {
        case SAVE_STATUS:
            return { ...state, saveStatus: action.payload }
        case GET_STATUS:
            return { ...state, getStatus: action.payload }
        case SHOW_LOGIN_MODAL:
            return { ...state, showModal: action.payload }
        case NAVIGATE_TO_PRICING:
            return { ...state, navigateToPricing: action.payload }



        case REGENERATE_OTP:
            return { ...state, regenerateOtp: action.payload.value, userId: action.payload.userId }
        default:
            return state;
    }

}