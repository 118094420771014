import { FOLLOWED_PRODUCTS, UPDATE_USERINFO_COLLECTION, USERINFO, USER_IMAGE_UPLOAD, USER_PROFILE_DETAILS, USER_VENDORS_COUNT, USER_CONNECTIONS_COUNT, USER_CUSTOMERS_COUNT, USER_CONNECTIONS, USER_VENDORS, USER_CUSTOMERS, USER_FOLLOWED_COMPANIES, USER_UPDATE_RESPONSE, USER_SUGGESTED_COMPANY } from '../../config/types';

export default function (state = { userInfoCollection: [] }, action) {
    switch (action.type) {
        case FOLLOWED_PRODUCTS:
            return { ...state, followedProducts: action.payload }
        case 'PROFILE_FEED':
            return { ...state, profile_feed: action.payload }
        case 'NETWORK_FEED':
            return { ...state, network_feed: action.payload }
        case "USER_PROFILE_INFO":
            return { ...state, userProfileInfo: action.payload }
        case USERINFO:
            let userInfoCollection = state.userInfoCollection.slice();
            let userInfo = action.payload;
            if (userInfo) {
                if (userInfo.image && userInfo.image.indexOf('amazonaws') > -1) {
                    //prevent browser from rendering cached image if the image at the server has changed
                    userInfo.image = userInfo.image + '?' + new Date().getTime();
                }
                const existingUserInfoIndex = userInfoCollection.findIndex(item => item.id === userInfo.id);
                userInfoCollection.splice(existingUserInfoIndex, existingUserInfoIndex !== -1 ? 1 : 0, userInfo);
            }
            return { ...state, userInfoCollection }
        case UPDATE_USERINFO_COLLECTION:
            let existingUserInfoCollection = state.userInfoCollection.slice();
            existingUserInfoCollection.push(...action.payload);
            return { ...state, userInfoCollection: existingUserInfoCollection }
        case USER_IMAGE_UPLOAD:
            return { ...state, userImageUploadStatus: action.payload }

        case 'NOTIFICATION_CLICKED':
            return { ...state, notificationClicked: action.payload }
        case USER_PROFILE_DETAILS:
            return { ...state, userProfileDetails: action.payload }
        case USER_VENDORS_COUNT:
            return { ...state, vendorsCount: action.payload }
        case USER_CONNECTIONS_COUNT:
            return { ...state, connectionsCount: action.payload }
        case USER_CUSTOMERS_COUNT:
            return { ...state, customersCount: action.payload }
        case USER_CONNECTIONS:
            return { ...state, connections: action.payload }
        case USER_VENDORS:
            return { ...state, vendors: action.payload }
        case USER_CUSTOMERS:
            return { ...state, customers: action.payload }
        case USER_FOLLOWED_COMPANIES:
            return { ...state, followedCompanies: action.payload }
        case USER_SUGGESTED_COMPANY:
            return { ...state, suggestedCompany: action.payload }
        case USER_UPDATE_RESPONSE:
            return { ...state, profileUpdateResponse: action.payload }

        default:
            return state;
    }

}