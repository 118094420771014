import React from 'react';
import { Button } from 'semantic-ui-react';
import NoApplicationSvg from '../../../assets/images/no-application.svg';

function NoApplications({ setShowAddApplications }) {
    return (
        <React.Fragment>
            <div className="nodatalist-wrapper">
                <div className="no-nodatalist">
                    <img alt={NoApplicationSvg} src={NoApplicationSvg} />
                    <b>For this product, no applications have been added</b>
                    <p className="mb-4">Add the applications that require the use of this product</p>
                    <Button primary onClick={() => setShowAddApplications(true)}>Add Applications</Button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default NoApplications;