import React, { Component } from 'react';
import { Button, Loader } from 'semantic-ui-react';
import "./sweetalert.scss";


class SweetAlerts extends Component {
   constructor(props) {
      super(props);
      this.state = {
         errors: [],
         showAlert: true
      }
   }

   handleClose = () => {
      this.setState({ showAlert: false })
      this.props.closeAlert(true)
   }

   render() {
      const { showAlert } = this.state;
      const { type, title, message } = this.props
      return (
         <>

            <div className='alert-mask'>
               <div className={`alert-card ${showAlert ? "animate" : ""}`}>
                  <div className='alert-icon'>
                     {type == 'E' && (<div class="error-mark">
                        <div class="check-icon">
                           <span class="icon-line line-tip"></span>
                           <span class="icon-line line-long"></span>
                           <div class="icon-circle"></div>
                           <div class="icon-fix"></div>
                        </div>
                     </div>)}
                     {type == 'S' && (
                        <div class="success-checkmark">
                           <div class="check-icon">
                              <span class="icon-line line-tip"></span>
                              <span class="icon-line line-long"></span>
                              <div class="icon-circle"></div>
                              <div class="icon-fix"></div>
                           </div>
                        </div>
                     )}
                  </div>
                  <h1>{title}</h1>
                  <p>{message}</p>
                  <div className='action'>
                     {this.props.button_text != undefined && this.props.button_text != '' ? (
                        <Button type='button' primary onClick={this.handleClose}>{this.props.button_text}</Button>

                     ) : (
                        <Button type='button' primary onClick={this.handleClose}>Close</Button>

                     )}
                  </div>
               </div>
            </div>

         </>
      )
   }
}

export default SweetAlerts;