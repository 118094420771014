import React from 'react';
import { Link } from 'react-router-dom';

import { Grid, Container, Step, Form, Checkbox, Input, Button, Search, Label, Select, Message, Dimmer, Loader, TextArea } from 'semantic-ui-react'
import Navbar from '../common/layout/navbar/Navbar'
import { SvgIcon } from '../common';
import './index.scss'
import axios from 'axios';
import { replaceSpaceInName } from '../../common/helperMethods';
import { bindActionCreators } from 'redux';
import { commonActions, requestQuoteActions } from '../../actions/';
import { connect } from 'react-redux';
import SEO from '../seo/SEO';
import { apiStatus, emailRegex } from '../../common/constants';
import { BASE_URL } from '../../config/config';
import { countryList } from '../../common/countries';
import { setSaveStatus } from '../../actions/common/common';
const terms_condition = 'https://www.mappes.io/terms&condition.pdf';
const resultRenderer = result => <Label key={result._source.id} content={result._source.name} />;

const steps = [
    {
        key: '1',
        title: 'STEP 1',
        description: <>Fill your <br /> requirement</>,
    },
    {
        key: '2',
        title: 'STEP 2',
        description: <>Receive proposal from <br /> Verified suppliers</>,
    },
    {
        key: '3',
        title: 'STEP 3',
        description: <>Review & finalise the <br /> Supplier</>,
    },
    {
        key: '4',
        title: 'STEP 4',
        description: <>Procurement <br /> completed</>,
    },
]

class RequestQuote extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isFormValid: true,
            formData: {
                attachment: '',
                user_id: '',
                company_name: '',
                company_id: '',
                message: '',
                term_checked: false,
            },
            errors: {
                company_name_error: '',
                first_name_error: false,
                last_name_error: false,
                email_id_error: false,
                email_id_invalid: false,
                contact_error: '',
                //  contact_invalid: '',
                country_error: '',
                product_error: '',
                term_condition_error: '',
            }
        };
        setSaveStatus(apiStatus.UNDEFINED);
    }

    componentDidMount() {
        if (this.props.auth) {
            const { auth } = this.props
            console.log('auth ', auth )
            this.setUserInformation();
            
            if (this.props.match.params.type == 'company') {
                let company_id = this.props.match.params.id
                axios.get(`${BASE_URL}/api/v1/company/${company_id}`, { withCredentials: true })
                    .then(response => {
                        if (response.data.Item != undefined) {
                            this.setState({
                                formData: {
                                    ...this.state.formData, company_id: response.data.Item.id, company_name: response.data.Item.name
                                }
                            });
                            this.handleValidation('company_name', false);
                        }
                    })
            } else {
                setTimeout(() => {
                    this.setState({
                        formData: { ...this.state.formData, user_id: auth.id, company_id: auth.company_id, company_name: auth.company }
                    });
                    this.handleValidation('company_name', false);
                }, 500);
            }

            if (this.props.match.params.type == 'product') {
                let product_id = this.props.match.params.id
                axios.get(`${BASE_URL}/api/v1/product/${product_id}`, { withCredentials: true })
                    .then(response => {
                        if (response.data.Item != undefined) {
                            this.setState({
                                formData: { ...this.state.formData, product: response.data.Item.name, product_id: response.data.Item.id }
                            })
                            
                            this.handleValidation('product', false);
                        }
                    })
            }

        }
    }
    
    setUserInformation(){
        const { auth } = this.props
        this.setState({
            formData: {
                ...this.state.formData, first_name: auth.firstName, last_name: auth.lastName, email_id: auth.email, contact: auth.phone, country: auth.country }
        })
        
        if(auth.phone){
            this.handleValidation('contact', false);

        }
     
    }

    componentDidUpdate() {
        if (this.props.common.saveStatus === apiStatus.COMPLETED) {
            setSaveStatus(apiStatus.UNDEFINED);
            setTimeout(() => {
                if (this.props.match.params.type == 'company') {
                    let company_link = `/company/${replaceSpaceInName(this.state.formData.company_name)}/${this.props.match.params.id}`;
                    this.props.history.push(company_link)
                }
    
                if (this.props.match.params.type == 'product') {
                    this.props.history.push(`/product/${replaceSpaceInName(this.state.formData.product)}/${this.state.formData.product_id}`)
                }
            }, 1500);
        }
    }

    handleValidation = (field, value) => {
        let error_field = field + '_error';
        if (value !== '' && value !== undefined && value !== null && value !== false && value !== true) {
            value = value.replace(/^\s+|\s+$/gm, '')
        }

        if (value === '' || value === undefined || value === null) {
            this.setState({
                errors: { ...this.state.errors, [error_field]: true }
            })
        } else {
            let error_obj = {}
            error_obj[field + '_error'] = false

            if (field == 'email_id') {
                if (emailRegex.test(value.trim())) {
                    error_obj['email_id_invalid'] = false;
                } else {
                    error_obj['email_id_invalid'] = true;
                }
            }

            /*  if (field == 'contact') {
                 if (/^\d{10}$/.test(value.trim())) {
                      error_obj['contact_invalid'] =  false;
                 } else {
                      error_obj['contact_invalid'] =  true;
                 }
             } */

            this.setState({
                errors: { ...this.state.errors, ...error_obj }
            })

        }

    }

    handleInputChange = (event) => {
        let current_value = event.target.value;
        if (event.target.name === "estimate_procurement_date") {
            this.setState({
                formData: { ...this.state.formData, [event.target.name]: current_value, estimate_procurement_date: new Date(current_value).getTime() }
            });
        }
        else {
            if (current_value === '' || current_value === undefined || current_value === null) {
                current_value = '';
            }

            if (current_value !== '' && current_value !== undefined && current_value !== null && current_value !== false && current_value !== true) {
                if (event.target.name!= 'technical_specifications'){
                    current_value = current_value.replace(/^\s+|\s+$/gm, '')
                } else {
                    current_value = current_value;
                }
            }

            this.setState({
                formData: { ...this.state.formData, [event.target.name]: current_value }
            })

            if (event.target.name == 'company_name') {
                this.setState({
                    formData: { ...this.state.formData, company_id: '' }
                })
            }
        }

        // validations 
        this.handleValidation(event.target.name, current_value);
    }

    handleResultSelect = (e, { result }) => {

        this.setState({
            formData: { ...this.state.formData, product: result._source.name, product_id: result._source.id }
        })
        this.handleValidation('product', false);
    };

    countryInput = (e, { value }) => {
        this.setState({
            formData: { ...this.state.formData, country: value }
        })

        // validations 
        this.handleValidation('country', value);
    }

    onAttachmentChange = (e) => {
        e.preventDefault();
        let files;
        if (e.target.files) {
            
            files = e.target.files;
            let attachment = [...this.state.formData.attachment];
            for (let i = 0; i < files.length; i++) {
                attachment.push(files[i])
            }
            this.setState({
                formData: { ...this.state.formData, attachment: attachment  }
            });
        }
    }

    renderFileList() {
        const { attachment } = this.state.formData;
        var attachment_html = [];
        if (attachment.length > 0) {
            for (let i = 0; i < attachment.length; i++) {
                attachment_html.push(<li>Tech spec for {attachment[i]['name']}  <span onClick={() => this.removeFile(i)} > <SvgIcon name='close' viewbox='0 0 23.126 23.126' /></span></li>)
            }
        }
        return (<React.Fragment>
            <ul className='technicalspe-list'>
                {attachment_html}
            </ul>
        </React.Fragment>)
    }

    removeFile = (i) => {
        const { attachment } = this.state.formData;
        var fileListArr = Array.from(attachment)
        fileListArr.splice(i, 1);
        this.setState({
            formData: { ...this.state.formData, attachment: fileListArr }
        });
    }

    handleSearchChange = (e, { value }) => {
        this.setState({
            formData: { ...this.state.formData, product: value, product_id: '' }
        })

        if (value.trim() != '' && value.length > 2) {
            this.setState({ isLoading: true })
            axios.get(`${BASE_URL}/api/v1/product/search/${value}`, { withCredentials: true })
                .then(response => {
                    this.setState({
                        isLoading: false,
                        product_list: response.data
                    })
                })
        } else {
            this.setState({
                isLoading: false,
                product_list: []
            })
        }

        if (value != '') {
            this.handleValidation('product', false);

        } else {
            this.handleValidation('product', true);

        }
    }

    acceptTerms = (e) => {
        var checked_field = e.target.checked;
        this.setState({
            term_checked: e.target.checked,
        });

        this.handleValidation('term_condition', !checked_field);
    }

    createRequestQuote = () => {
        let errors = this.state.errors
        this.setState({ isFormValid: true })
        for (var error in errors) {
            if (errors[error] === '') {
                errors[error] = true;
                this.setState({
                    errors: { ...this.state.errors, ...errors }
                })
                this.setState({ isFormValid: false })
            } else {
                if (errors[error] === true) {
                    this.setState({ isFormValid: false })
                }
            }
        }

        setTimeout(() => {
            if (this.state.isFormValid) {
                this.props.createRequestQuote(this.state.formData);
            }
        }, 300);
    }

    render() {
        const { formData, errors } = this.state;
        return (
            <Navbar>
                <div className="primary-background">
                    <SEO robots="noindex, nofollow"
                        canonicalUrl="/connections"
                    />
                    <Container className="body-background palm-nudge-sides">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form>
                                        <div className='req-quote-wrapper'>
                                            <h2 className='main-heading'>Simple 4 steps procurement process</h2>
                                            <Step.Group items={steps} />
                                            <div className='card-section'>
                                                <div className='title'>Company Information</div>
                                                <div className='card'>
                                                    <Form.Field className="required">
                                                        <label>Company name</label>
                                                        <Input placeholder='Company Name' name="company_name" id="company_name" onChange={this.handleInputChange} value={formData.company_name} />
                                                        {errors.company_name_error === true ? (<span className='text-danger'> Company Name is required </span>) : ''}
                                                    </Form.Field>
                                                    <Form.Group widths='equal'>
                                                        <Form.Field className="required">
                                                            <label>Contact First Name </label>
                                                            <Input placeholder='Contact First Name' name="first_name" id="first_name" onChange={this.handleInputChange} value={formData.first_name} />
                                                            {errors.first_name_error === true ? (<span className='text-danger'> Contact First Name is required </span>) : ''}
                                                        </Form.Field>
                                                        <Form.Field className="required">
                                                            <label>Contact Last Name </label>
                                                            <Input placeholder='Contact Last Name' name="last_name" id="last_name" onChange={this.handleInputChange} value={formData.last_name} />
                                                            {errors.last_name_error === true ? (<span className='text-danger'> Contact Last Name is required </span>) : ''}
                                                        </Form.Field>
                                                    </Form.Group>
                                                    <Form.Group widths='equal'>
                                                        <Form.Field className="required">
                                                            <label>Email address </label>
                                                            <Input placeholder='Email Address' name="email_id" id="email_id" onChange={this.handleInputChange} value={formData.email_id} />
                                                            {errors.email_id_error === true ? (<span className='text-danger'> Email address is required </span>) : ''}
                                                            <br />
                                                            {errors.email_id_invalid === true && errors.email_id_error !== true ? (<span className='text-danger'> Email address is not valid Email </span>) : ''}

                                                        </Form.Field>
                                                        <Form.Field className="required">
                                                            <label>Contact Number</label>
                                                            <Input placeholder='Contact Number' name="contact" id="contact" onChange={this.handleInputChange} value={formData.contact} />
                                                            {errors.contact_error === true ? (<span className='text-danger'> Contact Number is required </span>) : ''}
                                                            <br />
                                                            {/* {errors.contact_invalid === true ? (<span className='text-danger'> Contact Number is not valid 10 Digit Mobile Number </span>) : ''} */}

                                                        </Form.Field>
                                                    </Form.Group>
                                                    <Form.Group widths='equal'>
                                                        <Form.Field className="required">
                                                            <label>Country</label>
                                                            <Select search placeholder='Country' name="country" id="country" options={countryList} onChange={this.countryInput} value={formData.country} className="profile-company-search" />

                                                            {errors.country_error === true ? (<span className='text-danger'> Country Name is required  </span>) : ''}
                                                        </Form.Field>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className='card-section'>
                                                <div className='title'>Product Information</div>
                                                <div className='card'>
                                                    <Form.Field className="required">
                                                        <label>Product/Raw Material </label>
                                                        <Search fluid placeholder='Product/Raw Material'
                                                            name="product" id="product"
                                                            onResultSelect={this.handleResultSelect}
                                                            onSearchChange={this.handleSearchChange}
                                                            value={formData.product}
                                                            loading={this.state.isLoading}
                                                            minCharacters={3}
                                                            results={this.state.product_list}
                                                            resultRenderer={resultRenderer} />

                                                        {errors.product_error === true ? (<span className='text-danger'> Product Name is required </span>) : ''}
                                                    </Form.Field>
                                                    <Form.Group widths='equal'>
                                                        <Form.Field>
                                                            <label>Quantity</label>
                                                            <Input placeholder='Quantity' name="quantity" id="quantity" onChange={this.handleInputChange} value={formData.quantity} />
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <label>Estimated procurement date</label>
                                                            <input type="date" readonly={true} placeholder='DD-MM-YYYY' name="procurement_date" id="procurement_date" onChange={this.handleInputChange} value={formData.procurement_date} />
                                                        </Form.Field>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className='card-section'>
                                                <div className='title'>Technical Specification</div>
                                                <div className='card'>
                                                    <Form.Field>
                                                        <label>Requirement Description (If any)</label>
                                                        <TextArea rows={4} placeholder='Requirement Description' name="technical_specifications" id="technical_specifications" onChange={this.handleInputChange} value={formData.technical_specifications} ></TextArea>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label>Attach specifications file (if any)</label>
                                                        <input type='file' placeholder='upload xls, doc, pdf or image file' onChange={this.onAttachmentChange} multiple='multiple' />
                                                    </Form.Field>

                                                    {formData.attachment.length > 0 ? this.renderFileList() : ''}
                                                </div>
                                            </div>
                                            <Form.Field className='terms-check'>
                                                <input type='checkbox' name="quantity" id="quantity" onChange={this.acceptTerms} checked={this.state.term_checked} /> &nbsp;
                                                <p classsName='terms'>   I accept the  <Link to={{ pathname: terms_condition }} target="_blank"> <span><u>Term & Conditions</u></span> </Link> of Mappes.io</p>
                                            </Form.Field>
                                            {this.props.common.saveStatus === apiStatus.COMPLETED ?
                                                <>
                                                    <div className="text-left mt-2 mb-2">
                                                        <Message className="reduce-msg-padding" compact visible success content={`Request Submitted Successfully `} />
                                                    </div>
                                                </> : <></>
                                            }
                                            <div className='submit-btn'>
                                                <Button type="submit"
                                                    disabled={!this.state.term_checked}
                                                    loading={this.props.common.saveStatus === apiStatus.IN_PROGRESS}
                                                    onClick={this.createRequestQuote} primary>Submit</Button>
                                            </div>
                                            {this.props.common.saveStatus === apiStatus.IN_PROGRESS ? (
                                                <Dimmer active>
                                                    <Loader indeterminate>Saving</Loader>
                                                </Dimmer>
                                                
                                            ) : ""}
                                            
                                        
                                            {!this.state.isFormValid ?
                                                <>
                                                    <div className="text-left mt-2 mb-2">
                                                        <Message className="reduce-msg-padding" compact visible error content={`You have form errors `} />
                                                    </div>
                                                </> : <></>
                                            }

                                        </div>
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </div>
            </Navbar>
        );
    }
}



function mapStateToProps({ auth, common }) {
    return {
        auth,
        common,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators({ ...commonActions, ...requestQuoteActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestQuote);


