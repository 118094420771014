import React, { Component } from 'react';
import * as actions from '../../actions'
import { connect } from 'react-redux'
import { Grid, Container, Responsive, Button, Segment, Header, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom';

// import './pricing.css'
import Navbar from '../common/layout/navbar/Navbar'
import LeftSidebar from '../common/LeftSidebar'
import RightSection from '../common/RightSection'


class PaymentFailure extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <Navbar {...this.props}>
                <div className="primary-background">
                    <Container className="body-background palm-nudge-sides">
                        <Grid padded stackable>
                            <Responsive as={Grid.Column} className="left-content" minWidth={768}>
                                <LeftSidebar />
                            </Responsive>
                            <Grid.Column className='main-content-area'>
                                <div className="main-content">
                                    <div className="main-content_inner">
                                        <Grid padded>
                                            <Grid.Row className="background-white extra-padding-sides pD-t-25">
                                                <Segment padded='very' className="payment_message">
                                                    <Icon name='close circle' size='massive' color="red" />
                                                    <br />
                                                    <Header >
                                                        Sorry,
                                                            <br />Looks like your payment has failed
                                                    </Header>
                                                    <br />
                                                   {/*  <Link to='/upgrade' primary><Button>Try Again</Button></Link>
                                                    Or &nbsp; */}
                                                    <Link to='/'><Button>Go To Home </Button></Link>
                                                </Segment>
                                            </Grid.Row>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid.Column>
                            <RightSection />
                        </Grid>
                    </Container>
                </div>
            </Navbar>
        );
    }
}

function mapStateToProps({ auth, user, chatUser }) {
    return { auth, user, chatUser };
}

export default connect(mapStateToProps, actions)(PaymentFailure);
