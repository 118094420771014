import React from 'react';
import { connect } from 'react-redux';

import { Grid, Tab } from 'semantic-ui-react'
import TopStories from './TopStories';
import Articles from './Articles';
import Images from './Images';
import moment from 'moment';
import Videos from './Videos';
import { networkFeedActions } from '../../actions';
import { bindActionCreators } from 'redux';
import { apiStatus } from '../../common/constants';
import CountrySelection from './Profile-Completion/CountrySelection';
import CompanySelection from './Profile-Completion/CompanySelection';
import SweetAlerts from '../common/sweetalerts/sweetalert';

class PostsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            activeClass: "normal",
            showCountrySelection: moment(localStorage.getItem('signUpTime')).from(moment(new Date().toLocaleDateString())) === 'a day ago',
            showCompanySelection: true,
            // showCompanySelection: moment(localStorage.getItem('companySelectionTime')).from(moment(new Date().toLocaleDateString())) === 'a day ago',
            showCompanySavedAlert: false
        }
    }

    handleScroll = () => {
        if (document.getElementById("sidebar-pushable").scrollTop > 400) {
            this.setState({ activeClass: "fixTop" });
        } else {
            this.setState({ activeClass: "normal" });
        }
    };

    componentDidMount() {
        if (this.props.auth) {
            this.getNetworkFeed();
        }
        const sidebar = document.getElementById("sidebar-pushable");

        if (sidebar) {
            sidebar.addEventListener("scroll", this.handleScroll);
            sidebar.scrollTop = 0;
        }
    }

    componentWillUnmount() {
        const sidebar = document.getElementById("sidebar-pushable");

        if (sidebar) {
            sidebar.removeEventListener("scroll", this.handleScroll);
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.feedType !== this.props.feedType || newProps.entityId !== this.props.entityId) {
            const feedPagination = { start: 0, size: 10 };
            this.props.setNetworkFeedPagination(feedPagination);

            this.setState({ activeTab: 0 }, () => this.getNetworkFeed())
        }
    }
    componentDidUpdate() {
        const { feedType, history, networkFeedLoadingStatus } = this.props;
        const feed = this.getFeed();
        if (feedType === "global" && feed && feed.length === 0 && networkFeedLoadingStatus === apiStatus.COMPLETED) {
            history.push('/networkfeed/new')
        }
    }
    getFeed = () => {
        const { auth } = this.props;
        const { feedType, userFeed, productFeed, globalFeed, companyFeed, companyFeedSummary, productFeedSummary } = this.props;
        let feed;
        if (auth) {
            feed = { "user": userFeed, "product": productFeed, "global": globalFeed, "company": companyFeed }[feedType];
        }
        else {
            feed = { "product": productFeedSummary, "company": companyFeedSummary }[feedType];
        }
        return feed;
    }

    getNetworkFeed = () => {
        const { auth, entityId, getUserNetworkFeed, getProductNetworkFeed, getUserGlobalFeed, getCompanyFeed, feedType, getLikedPosts, getProductFeedUnauthenticated, getCompanyFeedUnauthenticated } = this.props;
        console.log('this.props ', this.props)
        getLikedPosts(auth.id);

        if (feedType === "user") {
            getUserNetworkFeed(auth.id, entityId);
        }
        else if (feedType === "product") {
            if (auth.id)
                getProductNetworkFeed(auth.id, entityId);
            else
                getProductFeedUnauthenticated(entityId);
        }
        else if (feedType === "global") {
            getUserGlobalFeed();
        }
        else if (feedType === "company") {
            if (auth.id)
                getCompanyFeed(auth.id, entityId);
            else
                getCompanyFeedUnauthenticated(entityId);
        }
    }

    handleTabChange = (e, { activeIndex }) => {
        this.setState({ activeTab: activeIndex })
    }

    handleLoadMoreClick = () => {
        const { feedPagination, setNetworkFeedPagination } = this.props;
        feedPagination.start += feedPagination.size;
        setNetworkFeedPagination(feedPagination);

        this.getNetworkFeed();
    }

    render() {
        const { auth, networkFeedUsers, userInfoCollection,
            removePost, updateFeedPostDetail, entityId, likedPosts, feedType,
            feedPagination, tagProductsAndCompaniesInPost, postTagged, setPostTaggedStatus, FEED_UPDATE_STATUS } = this.props;
        const { activeTab } = this.state;
        const feed = this.getFeed();

        const commonProps = {
            feed,
            auth,
            networkFeedUsers,
            userInfoCollection,
            removePost,
            updateFeedPostDetail,
            feedType,
            entityId,
            likedPosts,
            tagProductsAndCompaniesInPost,
            postTagged,
            setPostTaggedStatus
        }

        /*  const panes = [
             { menuItem: 'Top Stories', render: () => <Tab.Pane attached={false}><TopStories {...commonProps} handleLoadMoreClick={this.handleLoadMoreClick} feedPagination={feedPagination} /></Tab.Pane> },
             { menuItem: 'Articles', render: () => <Tab.Pane attached={false}><Articles {...commonProps} /></Tab.Pane> },
             { menuItem: 'Videos', render: () => <Tab.Pane attached={false}><Videos {...commonProps} /></Tab.Pane> },
             { menuItem: 'Images', render: () => <Tab.Pane attached={false}><Images {...commonProps} /></Tab.Pane> }
         ] */

        return (
            <div className="feeds-container">
                {
                    this.state.showCountrySelection && this.props.userProfileDetails && !this.props.userProfileDetails.country &&
                    <CountrySelection userProfileDetails={this.props.userProfileDetails} onSubmitSuccessful={() => this.setState({ showCountrySelection: false })} />
                }

                {
                    feedType === "global" && this.state.showCompanySelection && this.props.userProfileDetails && !this.props.userProfileDetails.company &&
                    <CompanySelection userProfileDetails={this.props.userProfileDetails} onSubmitSuccessful={() => this.setState({ showCompanySelection: false, showCompanySavedAlert: true })} />
                }
                {
                    this.state.showCompanySavedAlert &&
                    <SweetAlerts type={'S'} title={'Company udpated successfully'} message={'Your company has been updated in your profile'} closeAlert={() => this.setState({ showCompanySavedAlert: false })}> </SweetAlerts>
                }
                <TopStories {...commonProps} handleLoadMoreClick={this.handleLoadMoreClick} feedPagination={feedPagination} history={this.props.history} />
            </div>

            /* <Grid.Column width={16} className=" feedmain-view">
                * <div className={"postcontainer-section " + this.state.activeClass}><Tab className="custom-tab mobile-menu-icon-tab" menu={{ secondary: true, pointing: true }} panes={panes} activeIndex={activeTab} onTabChange={this.handleTabChange} /></div> 
                
            </Grid.Column> */

        )
    }
}

const mapStateToProps = (state) => {
    return { FEED_UPDATE_STATUS: state.networkFeed.FEED_UPDATE_STATUS, ...state.networkFeed, ...state.user, ...state.likedPosts }
}
const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({ ...networkFeedActions }, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PostsContainer);