import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { SvgIcon } from '../../common';
import { Accordion, Icon } from 'semantic-ui-react';
import { replaceSpaceInName } from '../../../common/helperMethods';

export default class Applications extends Component {
    state = { activeIndex: 0 }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    render() {
        const { activeIndex } = this.state;
        const { applications } = this.props;

        return (
            <Accordion className="rawmaterial-list" styled fluid>
                {
                    applications && applications.map((item, index) =>
                        <>
                            <Accordion.Title
                                active={activeIndex === index}
                                index={index}
                                onClick={this.handleClick}
                            >
                                <Link to={`/product/${replaceSpaceInName(item.application_title)}/${item.application}`}>{item.application_title}</Link>
                                <div>
                                    {item.supplierCount > 0 && <span>{item.supplierCount} Suppliers</span>}
                                    <SvgIcon name="menu-arrow" viewbox="0 0 23.619 13.503" />
                                </div>
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === index}>
                                <p>
                                    {item.description}
                                </p>
                            </Accordion.Content>
                        </>
                    )
                }
            </Accordion>
        )
    }
}