import React from 'react';
import { Button } from 'semantic-ui-react';

import NoCustomersSvg from '../../../assets/images/no-customers.svg';

function NoCustomers({ handleActiveIndexChange }) {
    return (
        <React.Fragment>
            <div className="nodatalist-wrapper">
                <div className="no-nodatalist">
                    <img alt={NoCustomersSvg} src={NoCustomersSvg} />
                    <b>No Customer found for this technology.</b>
                    <p className="mb-4">Do you know any company that is using this technology?</p>
                    <Button primary onClick={() => handleActiveIndexChange(4)}>Add company as a customer</Button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default NoCustomers;