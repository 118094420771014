import React, { useState, useEffect } from 'react';
import FileDropBox from './FileDropBox';
import { Modal, Button } from 'semantic-ui-react';
import { SvgIcon } from '../../common';
import ImageCropper from './ImageCropper';
import './fileUpload.css';
import './cropp-modal.scss';

const CroppingModal = ({ open, setOpen, handleSubmit, dropBoxText, modalHeaderText, disableSave }) => {
    const [originalImage, setOriginalImage] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)

    useEffect(() => {
        if (!open) {
            setOriginalImage(null);
            setCroppedImage(null)
        } else if (!originalImage) {
            setCroppedImage(null)
        }
    }, [open, originalImage])

    const dropBoxProps = {
        multiple: false,
        onDrop: (files) => setOriginalImage(files),
        accept: {
            'image/*': ['.jpeg', '.png']
        }
    }

    const cropperProps = {
        className: "cropper",
        style: { height: 275 },
        guides: true,
        src: originalImage ? URL.createObjectURL(originalImage[0]) : null,
        background: false,
        responsive: true,
        viewMode: 2,
        zoomTo: 0.5,
        initialAspectRatio: 1,
        autoCropArea: 0.5,
        checkOrientation: false,
        guides: true,
    }

    const handleFormData = () => {
        const formData = new FormData();
        formData.append("original_image", originalImage[0]);
        formData.append("crop_image", croppedImage);
        handleSubmit(formData)
    }

    return (
        <Modal
            id="cropping-modal"
            closeIcon={<SvgIcon className="modal-close" name="close" viewbox="0 0 23.126 23.126" />}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            className="croppingimg-modal"
            centered
        >
            <Modal.Header>{modalHeaderText}</Modal.Header>
            <Modal.Content>
                <div className='mx-auto mb-3 text-center'>
                    <FileDropBox dropBoxProps={dropBoxProps} dropBoxText={dropBoxText}>
                        {originalImage
                            && (() => <Button
                                className='btn-primary background-light-blue color-white'
                                disabled={disableSave}
                                onClick={() => setOriginalImage(null)}>
                                Upload other image
                            </Button>
                            )
                        }
                    </FileDropBox>
                </div>
                {originalImage
                    && <ImageCropper
                        cropperProps={cropperProps}
                        setCroppedImage={setCroppedImage}
                        croppedImage={croppedImage}
                    />
                }
                <div className='text-right'>
                    {croppedImage
                        && <Button
                            onClick={handleFormData}
                            className="btn-primary mB-20 background-light-blue color-white"
                            name="submit-image"
                            disabled={disableSave}
                        >
                            Save
                        </Button>
                    }
                </div>
            </Modal.Content>
        </Modal>
    )
}

export default CroppingModal;
