import React, { Component } from 'react';
import { SvgIcon } from '../../common';
import classNames from "classnames";
import './testimonials.scss';

 class TestimonialCard extends Component {
     render() {
         const classes = classNames(
             this.props.className,
             "testimonial-card"
         );
        return (
            <React.Fragment>
                <div className={classes}>
                    <div className="card">
                        <div className="card-inner">
                            <SvgIcon name="quote" viewbox="0 0 73.136 66.095" />
                            <p>
                                {this.props.content}
                            </p>
                            <div className="person">
                                <b>{this.props.name},</b> {this.props.details}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default TestimonialCard
