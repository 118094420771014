import React, { Component } from 'react';
import './invite.scss';
import Email from './Email';

class InviteView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputsCount: 1
        };
    }
    handleAddMoreClick = () => {
        this.setState({ inputsCount: this.state.inputsCount + 1 })
    }

    render() {
        return (
            <div className="middle-section background-white">
                <div className="row py-4 px-3">
                    <div className="col-sm-12 invite-header mb-4">
                        <h1> Invite your colleagues and peers who would be interested in this {this.props.type}</h1>
                        {/* <p>
                            For every contact who joins and creates an account, we will give you one free week on top of
                            your subscription. The more people you invite, the bigger your network and the cheaper it is
                            :)
                        </p> */}
                    </div>
                    <div className="col-sm-12">
                        {
                            [...Array(this.state.inputsCount).keys()].map(key =>
                                <Email index={key} entityId={this.props.id} type={this.props.type} />
                            )
                        }
                        <div className="admoreemail-link">
                            <span onClick={this.handleAddMoreClick}> + Add more email addresses</span>
                        </div>
                    </div>
                    {/* <div className="col-sm-12">
                        <div className="inviteor-divider">
                            <div className="or-div">OR</div>
                        </div>
                    </div> */}
                    {/* <div className="col-sm-12">
                        <div className="invite-buttons">
                            <h2>Try other ways to invite</h2>
                            <div className="bottom">
                                <Button>
                                    <SvgIcon name="links" viewbox="0 0 511.997 511.997" /> Copy Link
                                </Button>
                                <Button>
                                    <SvgIcon name="gmaillogo" viewbox="0 0 512 512" /> Invite Gmail Contacts
                                </Button>
                                <Button>
                                    <SvgIcon name="share" viewbox="-21 0 512 512" /> Share on Social Networks
                                </Button>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default InviteView;
