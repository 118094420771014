import React, { Component } from 'react';
import { Button, Card, Image } from 'semantic-ui-react';
import { TestimonialCard, GettingStarted, SvgIcon } from '../common';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './home.scss';

import Navbar from '../common/layout/navbar/Navbar';
import Footer from '../common/layout/footer/footer';

// Import Images 
import BannerImage from '../../assets/images/home.png';
import OpticalFiberLogo from '../../assets/images/home/OpticalFiberLogo.jpg';
import PhotoVoltaicLogo from '../../assets/images/home/PhotoVoltaicLogo.jpg';
import UVLogo from '../../assets/images/home/UVLogo.jpg';
import LithiumIonLogo from '../../assets/images/home/LithiumIonLogo.jpg';
import AdditiveLogo from '../../assets/images/home/AdditiveLogo.jpg';
import CarbonFiberLogo from '../../assets/images/home/CarbonFiberLogo.jpg';
import SemiconductorLogo from '../../assets/images/home/SemiconductorLogo.jpg';
import GreenHydrogenLogo from '../../assets/images/home/Green-Hydrogen-1.jpeg';
import Snapshot from '../../assets/images/home/snapshot.jpg';
import ProductKeyImage1 from '../../assets/images/home/hp_1.svg';
import ProductKeyImage2 from '../../assets/images/home/hp_2.svg';
import ProductKeyImage3 from '../../assets/images/home/hp_3.svg';
import Snapshot1 from '../../assets/images/home/snapshot-1.jpg';
import Snapshot2 from '../../assets/images/home/snapshot-2.jpg';
import Snapshot3 from '../../assets/images/home/snapshot-3.jpg';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Testimonials } from './types';
import InviteUser from '../common/InviteUser';
import SEO from '../seo/SEO';

class NewHomeView extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        const { auth, history } = this.props;
        if (auth) {
            history.push('/networkFeed/global')
        }
    }


    productNetwork = [{
        key: 1,
        image: ProductKeyImage1,
        link: true,
        meta: "Lead Generation from Manufacturing Sector"
    },
    {
        key: 2,
        image: ProductKeyImage2,
        link: true,
        meta: "Customer Engagement through Product Updates",
    },
    {
        key: 3,
        image: ProductKeyImage3,
        link: true,
        meta: "Global Sourcing for Latest Technologies"
    }
    ]

    render() {
        const productData = [{
            key: 1,
            image: OpticalFiberLogo,
            name: "Optical Fiber and Cable",
            link: "/product/Single-Mode-Optical-Fiber/SkW9l-VnJRsG"
        },
        {
            key: 2,
            image: PhotoVoltaicLogo,
            name: "PhotoVoltaic",
            link: "/product/Solar-cells/ryh4YXLfWP6DX"
        },
        {
            key: 3,
            image: UVLogo,
            name: "UV/EB Curing",
            link: "/product/Uv-Curing-Systems/B1lAQ48Mbwavm"
        },
        {
            key: 4,
            image: LithiumIonLogo,
            name: "Lithium-Ion",
            link: "/product/Lithium-ion-battery-Cell/SyG_WFgFX"
        },
        {
            key: 5,
            image: AdditiveLogo,
            name: "Additive Manufacturing",
            link: "/product/3D-Printer/rkxIVP6OrI"
        },
        {
            key: 6,
            image: CarbonFiberLogo,
            name: "Carbon Fiber",
            link: "/product/Carbon-Fibers/S1aD-N21Ciz"
        },
        {
            key: 7,
            image: SemiconductorLogo,
            name: "Semiconductor",
            link: "/product/Integrated-Circuits-IC-Chips-or-Fabricated-wafers/HkqK_ZLfZvaDX"
        },
        {
            key: 8,
            image: GreenHydrogenLogo,
            name: "Green Hydrogen",
            link: "/product/Green-Hydrogen/S1mF5ohWK"
        }
        ]

        const settings = {
            dots: true,
            infinite: true,
            className: "center",
            centerPadding: "120px",
            centerMode: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        centerPadding: "30px",
                        centerMode: true
                    }
                }
            ]
        };

        const snapShotsettings = {
            dots: true,
            infinite: true,
            autoPlay: true,
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            slidesToScroll: 1,
        };
        
        return (
            <React.Fragment>
                <SEO robots="index, follow"
                    canonicalUrl="/"
                    alternateLink="/"
                    pageType={'homepage'}
                    
                />
                <Navbar {...this.props}>

                    <div className="home-main">
                        <div className="banner-section">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-8 col-md-12">
                                        <h1>Welcome to Your Manufacturing Community</h1>
                                        <p className="text-1 mt-4">Discover new technologies, customers, and suppliers for your industrial product. Network and share product updates with industry partners.</p>
                                        <div className="mt-5 button-section">
                                            {/* <Link to='/signup'>
                                                <Button primary className="btn-lg mr-4 custom-btn">CREATE A FREE ACCOUNT</Button>
                                            </Link>
                                            <Link to='/pricing'>
                                                <Button basic primary className="btn-lg custom-btn">SEE PRICING</Button>
                                            </Link> */}
                                            {/* <div className="ui action input invitation-action">
                                                <input type="text" placeholder="Email Address" onChange={this.handleInputChange} />
                                                <Button primary className="custom-btn text-inherit" onClick={this.sendInvite} loading={invitationStatus == "in progress"}>Get an invitation</Button>
                                            </div>
                                            {invitationStatus === "sent" && invitationMessage &&
                                                <Message positive size="mini">
                                                    <Message.Header>{invitationMessage}</Message.Header>
                                                </Message>
                                            } */}
                                            <InviteUser />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img alt={BannerImage} className="banner-img" src={BannerImage} />
                        </div>

                        <div className="homeproduct-section py-80">
                            <div className="container common-heading text-center">
                                <div className="row">
                                    <div className="col-lg-9 col-md-12 m-auto">
                                        <h2>Product Ecosystem at Your Fingertips</h2>
                                        <p className="text-1">Using Data Science and Machine Learning, we bring the complete value chain of Industrial products, helping you discover new applications, new customers and suppliers for your products. We help you share product updates, to connect and engage with your customers throughout the year.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="homeproduct-section-wrapper">
                                <Card.Group itemsPerRow={4} centered stackable>
                                    {productData.map((product, i) => (
                                        <Card key={i}>
                                            <Image alt={product.name} src={product.image} wrapped ui={false} />
                                            <Card.Content>
                                                <div className="content-inner">
                                                    <Card.Header>{product.name}</Card.Header>
                                                    <Link to={product.link}>
                                                        <Button primary className="custom-btn">Explore</Button>
                                                    </Link>
                                                </div>
                                            </Card.Content>
                                        </Card>
                                    )
                                    )}
                                </Card.Group>
                            </div>
                        </div>

                        <div className="productkey-section">
                            <div className="container common-heading text-center">
                                <div className="row">
                                    <div className="col-sm-12 m-auto">
                                        <h3>A Network for Your Manufacturing Products <br /> and Applications</h3>
                                        <p className="text-1">
                                            Our whole platform is about your products, raw materials, machinery, and product applications. Every person is connected to you because of your product network. Every discovery is made due to the power of mapping your products ecosystem.
                                        </p>
                                        <b className='blue-heading'>Product Network is the key</b>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Card.Group items={this.productNetwork} itemsPerRow={3} centered stackable />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="snapshot-section py-80">
                            <div className="container common-heading text-left">
                                <div className="row">
                                    <div className="col-lg-11 col-md-12 m-auto">
                                        <b className='h2-bold'>Product Snapshot</b>
                                        <p className="text-1">
                                            We have created a product centric social network where every person is connected to each other based on their product’s network. Our platform allows you to create a product page for every product in your ecosystem. You can add applications and raw materials. You can go to customer and supplier pages to find contacts and connect with them.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-10 col-md-12 m-auto">
                                        <div className='snapshot-slider'>
                                            <div className='snapshot-slider-head'>
                                                <SvgIcon name="ellipsis-vertical" viewbox="0 0 10.125 38.875" />
                                            </div>
                                            <Slider {...snapShotsettings}>
                                                <div className='snapshot-img'>
                                                    <img alt={Snapshot1} className="w-100" src={Snapshot1} />
                                                </div>
                                                <div className='snapshot-img'>
                                                    <img alt={Snapshot2} className="w-100" src={Snapshot2} />
                                                </div>
                                                <div className='snapshot-img'>
                                                    <img alt={Snapshot3} className="w-100" src={Snapshot3} />
                                                </div>
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="testimonial-section py-50">
                            <div className="testimonial-wrapper">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Slider {...settings}>
                                            {
                                                Testimonials.map((item, i) =>
                                                    <TestimonialCard
                                                        key={i}
                                                        content={item.content}
                                                        name={item.name}
                                                        details={item.details}
                                                    />)
                                            }
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <GettingStarted />
                    </div>
                    <Footer />
                </Navbar>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    return { auth };
}

export default connect(mapStateToProps)(NewHomeView);