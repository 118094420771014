import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { SvgIcon } from '../common';
import './aboutus.scss';

import Navbar from '../common/layout/navbar/Navbar';
import Footer from '../common/layout/footer/footer';

// Import Images 
import BannerImage from '../../assets/images/aboutus.png';
import AbputTri from '../../assets/images/about-tri.svg';
import SEO from '../seo/SEO';

class AboutUsView extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    contactUs = () => {
        //  window.location.href = "mailto:ankit.singhal@mappes.io"
        window.open('https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=ankit.singhal@mappes.io', '_blank');
    }

    render() {
        return (
            <Navbar {...this.props}>
                <SEO robots="index, follow"
                    canonicalUrl="/aboutus"
                />
                <div className="about-main">
                    <div className="banner-section">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-8">
                                    <p className="text-1 mt-4 aboutsmall-heading">About Mappes</p>
                                    <h1>Interact with your manufacturing community in a new way.</h1>
                                    <p className="text-1 mt-4">Mappes empowers manufacturers to create a digital network around the products and services they provide.</p>
                                </div>
                            </div>
                        </div>
                        <img alt={BannerImage} className="banner-img" src={BannerImage} />
                    </div>

                    <div className="about-mobile-tri">
                        <img alt={AbputTri} src={AbputTri} />
                    </div>

                    <div className="common-section aboutproduct-section">
                        <div className="container-common">
                            <div className="row align-items-center">
                                <div className="col-md-6 tri-shape">
                                    <SvgIcon name="tri-shapes" viewbox="0 0 420.76 415.011" />
                                </div>
                                <div className="col-md-6 products-text">
                                    <h2>Connecting Products. Connecting People.</h2>
                                    <p>
                                        Our mission to connect the value chain of every industrial product and help manufactures discover new technologies, suppliers, customers and latest product information at the fastest rate possible.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="whoweare-section py-80 mt-5">
                        <SvgIcon className="tri-shapes" name="tri-shapes" viewbox="0 0 420.76 415.011" />
                        <div className="container common-heading text-center">
                            <div className="row">
                                <div className="col-sm-8 m-auto">
                                    <h2>Who are we?</h2>
                                    <p className="text-1 mt-4">
                                        Our mission is simple: Connect products and people With the product at the core, Mappes was started with the human connection in mind. Mappes is the driving force of manufacturing ecosystems and allowing users to map out their value chains without using outdated traditions.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-8 m-auto center gotanyq-section">
                                    <h4>Got any questions or want to learn more? </h4>
                                    <p className="text-1">
                                        Reach out to us if you wish to sponsor and advertise on our platform to gain greater visibility
                                    </p>
                                    <Button primary className="btn-lg mt-4 basic custom-btn" onClick={this.contactUs}>CONTACT US</Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bottom-shape">
                        <SvgIcon name="bottom-shape" viewbox="0 0 1417 422" />
                    </div>
                </div>
                <Footer />
            </Navbar>
        );
    }
}



export default AboutUsView;
