import React, { Component } from 'react';
import classNames from "classnames";
import { Button } from 'semantic-ui-react'
import './index.scss';

 class GuestSliderCard extends Component {
     render() {
         const classes = classNames(
             this.props.className,
             "leftguest-slider"
         );
        return (
            <React.Fragment>
                <div className={classes}>
                    <div className="card">
                        <div className="card-inner">
                            <div className='card-bottom'>
                                <b>{this.props.title}</b>
                                <p>
                                    {this.props.content}
                                </p>
                                <Button primary size='mini' onClick={this.props.handleButtonClick}>{this.props.button_name}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default GuestSliderCard
