import React from 'react';
import { Button, Modal, Form, Select, Message, Responsive } from 'semantic-ui-react';
import { SvgIcon, Row, Col } from '../../common';
import { countryList } from '../../../common/countries';
import { commonActions, companyActions, productActions } from '../../../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { apiStatus } from '../../../common/constants';
import { replaceSpaceInName } from '../../../common/helperMethods';

class AddNewCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            company_name : '',
            errors: {
                company_name: '',
                company_country: '',
            }
        }
    }

    componentDidUpdate() {
       // console.log('this.props before ', this.props)
        if (this.props.saveStatus === apiStatus.COMPLETED) {
            
            setTimeout(() => {
                this.handleClose();
                this.props.handleCSucess();
                if (this.props.addAsSupplier == false){
                    if (this.props.new_company_data.new_company_id != undefined){
                        this.props.company_id(this.props.new_company_data.new_company_id, this.state.company_name)
                        this.setState({ company_name: '' })
                    }
                }
            }, 1200);
        }
    }

    handleCountrySelect = (e, { value }) => {
        this.setState({ country: value })
        let errors = this.state.errors;
        errors['company_country'] = ''
        this.setState({ errors: errors })

    }

    handleSave = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const { elements } = e.target;
        let errors = this.state.errors;
        let has_errors = false;
        const { productId, productName } = this.props;

        const data = {
            name: elements.name.value.trim(),
            address: elements.address.value,
            url: elements.url.value,
            desc: elements.desc.value,
            country: this.state.country,
            addAsSupplier: this.props.addAsSupplier,
            product_id: productId,
            product_name: productName,
        };

        if (data.name == "" || data.name == null || data.name == undefined) {
            errors['company_name'] = 'Name is required'
            has_errors = true;
        } else {
            errors['company_name'] = ''
        }

        if (data.country == "" || data.country == null || data.country == undefined) {
            errors['company_country'] = 'Country is required'
            has_errors = true;
        } else {
            errors['company_country'] = ''
        }

        this.setState({ errors: errors })

        if (has_errors == false) {
            this.props.createCompany(data);
            if (this.props.addAsSupplier == false) {
                this.setState({ company_name: data.name })
            }
        }
    }

    formatInput = (event) => {
        const attribute = event.target.getAttribute('name')
        this.setState({ [attribute]: event.target.value.trim() })
    }



    handleChange = (event) => {
        let errors = this.state.errors;
        const attribute = event.target.getAttribute('name')
        if (event.target.value != '') {
            errors[attribute] = ''
        } else {
            errors[attribute] = 'Name is required'
        }

        this.setState({ errors: errors })
    }


    handleClose = () => {
        this.props.setSaveStatus(apiStatus.UNDEFINED);
        this.props.handleClose();
        let errors = this.state.errors;
        errors['company_name'] = ''
        errors['company_country'] = ''
        this.setState({ errors: errors })
    }

    render() {
        const { saveStatus } = this.props;

        return (
            <Modal
                className="creatacompany-modal"
                open={this.props.open}
                size="small"
            >
                <Responsive as={Modal.Header} minWidth={577}><h3>Create a company/supplier</h3> <div className='closeicon-header' onClick={this.props.handleClose}><SvgIcon name='close-alt' viewbox="0 0 15 15.001" /></div></Responsive><Responsive as={Modal.Header} maxWidth={576}><div className='backicon-header' onClick={this.props.handleClose}><SvgIcon name='chevron-left' viewbox="0 0 10 16" /></div> <h3>Create a company/supplier</h3></Responsive> 
                <Modal.Content scrolling>
                    <Modal.Description>
                        <p>
                            Create a new company page
                        </p>
                        <Form onSubmit={this.handleSave}>
                            <Form.Field>
                                <label>Company Name</label>
                                <input id="name" onBlur={this.formatInput} name="company_name" placeholder='Formal business name' onChange={this.handleChange} />
                                <span className='text-danger'> {this.state.errors.company_name ? this.state.errors.company_name : ""}</span>
                            </Form.Field>
                            <Form.Field>
                                <label>Website</label>
                                <input id="url" placeholder='www.company.com' />
                            </Form.Field>
                            <Form.Field>
                                <label>Company Address</label>
                                <input id="address" placeholder="12345 Street B" />
                            </Form.Field>
                            <Form.Field>
                                <Row>
                                    <Col sm="12">
                                        <label>Country</label>
                                        <Select
                                            search
                                            name="country"
                                            placeholder='Select your country'
                                            options={countryList}
                                            onChange={this.handleCountrySelect}
                                        />
                                    </Col>
                                </Row>
                                <span className='text-danger'>   {this.state.errors.company_country ? this.state.errors.company_country : ""} </span>
                            </Form.Field>
                            <Form.Field>
                                <label>Company Description <small>(optional)</small></label>
                                <textarea id="desc" rows="3" placeholder='Company Description' />
                            </Form.Field>
                            {
                                saveStatus === apiStatus.ALREADY_EXISTS ?
                                    <>
                                        <div className="text-left mt-2 mb-2">
                                            <Message className="reduce-msg-padding" compact visible error content={`Company Already Exists `} />
                                        </div>
                                    </>
                                    :
                                    <></>
                            }
                            <div className="text-center pt-4 mb-2 mt-auto">
                                <Button className="ml-1 addproduct-btn" type='submit' primary loading={saveStatus === apiStatus.IN_PROGRESS}>Add company</Button>
                                <Button className='addproduct-btn' type='submit' onClick={this.props.handleClose}>Cancel</Button>
                            </div>
                            {
                                saveStatus === apiStatus.COMPLETED &&
                                <span>Company created successfully.</span>
                            }
                        </Form>
                    </Modal.Description>
                </Modal.Content>
            </Modal >
        )

    }
}

const mapStateToProps = ({ common, singleCompany }) => {
    return {
        saveStatus: common.saveStatus,
        new_company_data: singleCompany
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        ...bindActionCreators({ ...companyActions, ...productActions, ...commonActions }, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddNewCompany);