import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "./connections.scss";
import Layout from '../common/layout/Layout';
import { userActions } from '../../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Message from '../common/Message';
import { Label } from 'semantic-ui-react';
import { replaceSpaceInName } from '../../common/helperMethods';
import SEO from '../seo/SEO';
class Connections extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.props.getConnections();
    }

    handleClose = () => this.setState({ modalOpen: false });
    handleOpen = () => this.setState({ modalOpen: true });

    render() {
        const { connections, auth, userProfileDetails } = this.props;
        return (
            <>
                <SEO robots="noindex, nofollow"
                    canonicalUrl="/connections"
                />
                <Layout {...this.props} entityId={auth ? auth.id : null} entityType={"user"}>
                    <div className="row">
                        <div className="col-sm-12 p-0 background-white">
                            <h4 className="connections-list-heading px-4 pt-3">Connections</h4>

                            <div className="ui middle aligned divided list connections-list mt-2 py-1">
                                {
                                    connections && !connections.length &&
                                    <div className="col-sm-12 headers-section mb-4">
                                        <h2>
                                            Please add products on your company page:{" "}
                                            <Link to={`/company/${replaceSpaceInName(userProfileDetails ? userProfileDetails.company : "")}/${userProfileDetails && userProfileDetails.companyId}`}>
                                                {userProfileDetails && userProfileDetails.company}
                                            </Link> so that we can build recommendations for you
                                        </h2>
                                    </div>
                                }
                                {connections && connections.map(items => (
                                    <div className="item" key={items.key}>
                                        <div className="connection-left">
                                            <div className="images">
                                                {items.user_image ?
                                                    <img alt={items.user_image} className="ui avatar image" src={items.user_image} />
                                                    :
                                                    <Label circular color="blue">{items.user_name.split(" ").map((n) => n[0]).join("").substring(0, 2)}</Label>
                                                }
                                            </div>
                                            <div className="content">
                                                <Link to={`networkFeed/user/${items.user_id}`}><h4>{items.user_name}</h4></Link>
                                                {items.user_company_name && <Link to={`/company/${replaceSpaceInName(items.user_company_name)}/${items.user_company_id}`} >  <p>{items.user_company_name}</p>
                                                </Link>}
                                            </div>
                                        </div>
                                        <div className="right floated content">
                                            <Message auth={auth} userId={items.user_id} userName={items.user_name} />
                                            {/* <Link to="/">Message</Link> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Layout >
            </>
        );
    }
}
const mapStateToProps = ({ user, auth }) => {
    return {
        connections: user.connections,
        auth,
        userProfileDetails: user.userProfileDetails
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        ...bindActionCreators(userActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Connections);