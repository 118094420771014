import { CREATE_REQUEST_QUOTE, } from '../../config/types'


export default function (state = {}, action) {
   switch (action.type) {
      case CREATE_REQUEST_QUOTE:
         return { ...state, eventCreated: action.payload }
      default:
         return state;
   }

}