import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { Grid, Dropdown, Icon, Loader } from 'semantic-ui-react'
import ProfileImage from './ProfileImage';
import { Link } from 'react-router-dom';

import { getUserInfo, getUserProfileInfo } from '../../actions';
import { uploadUserImage } from '../../actions/user/user';
import Message from '../common/Message';
import { replaceSpaceInName } from '../../common/helperMethods';

const Name = ({ name }) => {
    console.log('name ', name)
    return (
        name ?
            <div className="profile-name">{name}</div>
            :
            <span className="hide-menu">Loading...</span>
    )
}

const Designation = ({ userProfileInfo }) => {
    console.log('userProfileInfo ', userProfileInfo)
    return (
        <span>
            {userProfileInfo.designation ? userProfileInfo.designation : ''} {userProfileInfo.company ? (' at ') : ' '}
            {userProfileInfo.company &&
                <Link to={`/company/${replaceSpaceInName(userProfileInfo.company)}/${userProfileInfo.company_id}`}>{userProfileInfo.company}</Link>}
        </span>
    )
}
const UserProfileImage = (props) => {
    const { auth, userProfileInfo, image, linkedinImage, uploadUserImage, userImageUploadStatus } = props;

    if (image || linkedinImage) {
        return (
            <React.Fragment>
                {userImageUploadStatus === "uploading" && <Loader active />}
                <ProfileImage image={image} linkedinImage={linkedinImage} />
            </React.Fragment>
        )
    }
    else {
        if (auth.id === userProfileInfo.user_id) {
            return (
                <div className="upload-user-image-control">
                    {userImageUploadStatus === "uploading" && <Loader active />}
                    <label htmlFor="upload-user-image">click to upload profile image</label>
                    <input id="upload-user-image" type="file" onChange={uploadUserImage} />
                </div>
            )
        }
        else {
            let userInitials = "";
            if (userProfileInfo.firstName) {
                userInitials = userProfileInfo.firstName.substring(0, 1)
            }
            else if (auth && auth.displayName) {
                userInitials = auth.displayName.substring(0, 1);
            }

            return userInitials;
        }
    }
}

class ProfileInfo extends React.Component {

    componentDidMount() {
        this.props.getUserInfo(this.props.userId);
        this.props.getUserProfileInfo(this.props.userId);
    }

    componentWillReceiveProps(newProps) {
        const { userId } = newProps;
        if (this.props.userId !== userId) {
            this.props.getUserInfo(userId);
            this.props.getUserProfileInfo(userId);
        }
    }

    uploadUserImage = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            this.props.uploadUserImage(file);
        }
    }

    render() {
        if (!this.props.user.userProfileInfo) { return null; }
        const { auth, user } = this.props;
        const { firstName, lastName, desc, user_id, id } = this.props.user.userProfileInfo;
        let userid = '';
        if (user_id != null && user_id != undefined) {
            userid = user_id;
        }

        if (id != null && id != undefined) {
            userid = id;
        }

        let image;
        let linkedinImage
        if (this.props.user.userInfoCollection) {
            let userInfo = this.props.user.userInfoCollection.find(userInfo => userInfo.id == this.props.userId);
            if (userInfo) {
                image = userInfo.image;
                linkedinImage = userInfo.linkedinImage;
            }
        }
        return (
            <Grid.Row className="extra-padding-sides">
                <Grid padded className="full-width">
                    <Grid.Column width={16}>
                        <div className="profile-info-bar">
                            <div className="profile-image">
                                <UserProfileImage
                                    image={image}
                                    auth={auth}
                                    userProfileInfo={this.props.user.userProfileInfo}
                                    uploadUserImage={this.uploadUserImage}
                                    userImageUploadStatus={this.props.user.userImageUploadStatus}
                                    linkedinImage={linkedinImage}
                                />
                            </div>
                            <div className="profile-info-text">
                                <div className="profile-name">
                                    <div className="profile-name-text">
                                        {firstName && lastName ? (<Name name={`${firstName} ${lastName}`} />) : ''}

                                    </div>
                                    {auth.id !== userid && <Message auth={auth} userId={userid} userName={`${firstName} ${lastName}`} />}
                                    {firstName && lastName ? (
                                        <div className="profile-designation">
                                            <Designation userProfileInfo={this.props.user.userProfileInfo} />
                                        </div>
                                    ) : ""}


                                    <div className="profile-company mB-10 ">
                                        {desc}
                                    </div>
                                    <div className="profile-network">
                                        {/* <a className="t600 text-blue">(24 People in the network)</a> */}
                                    </div>

                                    {/* <Button className="mappes-small-button" size='mini' color='green'>Send Message</Button> */}

                                    {
                                        auth.id === user_id &&
                                        <div className="profile-drop-down">
                                            <Dropdown trigger={<Icon size='large' name={'ellipsis horizontal'} />}>
                                                <Dropdown.Menu direction='right' style={{ borderRadius: "10px" }}>
                                                    <Dropdown.Item className="dropDownThreeDots update-image">
                                                        <label htmlFor="update-company--logo">Update User Image</label>
                                                        <input id="update-company--logo" type="file" onChange={this.uploadUserImage} />
                                                    </Dropdown.Item>

                                                    {/* <Dropdown.Item className="dropDownThreeDots" text='Copy Profile Link' />
                                                <Divider />
                                                <Dropdown.Item style={{ marginTop: "5px", marginBottom: "5px" }} className="dropDownThreeDots" text='Report Abuse' /> */}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid>
            </Grid.Row>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators({ getUserProfileInfo, getUserInfo, uploadUserImage }, dispatch) }

}
function mapStateToProps({ user }) {
    return { user };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo);