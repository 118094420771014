import React from 'react'
import { Grid, Container } from 'semantic-ui-react';
import Navbar from './navbar/Navbar';

const LayoutArticleEditor = (props) => {
    return (
        <Navbar {...props}>
            <div id="HEADER" className="primary-white">
                <Container className="body-background palm-nudge-sides article-view">
                    <Grid padded stackable>
                        {props.children}
                    </Grid>
                </Container>
            </div>
        </Navbar>
    );
}

export default LayoutArticleEditor;