import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
// import { Message } from 'semantic-ui-react';
import { Table, Grid } from 'semantic-ui-react';
import { userActions } from '../../actions';
import { replaceSpaceInName } from '../../common/helperMethods';
import Layout from '../common/layout/Layout';

import SEO from '../seo/SEO';

class Customers extends React.Component {
    componentDidMount() {
        this.props.getCustomers();
    }

    render() {

        const { customers, userProfileDetails, auth } = this.props;

        return (
            <>
                <SEO robots="noindex, nofollow"
                    canonicalUrl="/customers"
                />
                <Layout {...this.props} entityId={auth ? auth.id : null} entityType={"user"}>
                    <Grid.Column width={16}>
                        {
                            customers && !customers.length ?
                                <div className="col-sm-12 headers-section mb-4">
                                    <h2>
                                        Please add products on your company page:{" "}
                                        <Link to={`/company/${userProfileDetails && userProfileDetails.companyId}/${replaceSpaceInName(userProfileDetails ? userProfileDetails.company : "")}`}>
                                            {userProfileDetails && userProfileDetails.company}
                                        </Link> so that we can build recommendations for you
                                    </h2>
                                </div>
                                :
                                <Table padded basic="very" striped unstackable>
                                    <Table.Header>
                                        <Table.Row>
                                            {/* <Table.HeaderCell className="toggle-cell-icon"></Table.HeaderCell> */}
                                            <Table.HeaderCell className="color-light-blue">Company Name</Table.HeaderCell>
                                            <Table.HeaderCell className="color-light-blue">Country</Table.HeaderCell>
                                            <Table.HeaderCell className="color-light-blue" textAlign="center">
                                                Listed Contacts
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            customers && customers.map((customer, index) =>
                                                <Table.Row key={index}>

                                                    <Table.Cell>
                                                        <Link to={`/company/${customer.customer_id}`}>{customer.customer_name}
                                                            {/* {supplier.premium &&
                                                <img style={{ width: 56, marginBottom: -3, marginLeft: 2 }} src={PremiumTag} />
                                            } */}
                                                        </Link>
                                                    </Table.Cell>
                                                    <Table.Cell>{customer.customer_country}</Table.Cell>
                                                    <Table.Cell textAlign="center" style={{ color: '#797979' }}>
                                                        {customer.customer_contacts}
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        }
                                    </Table.Body>
                                </Table>
                        }
                        {/* {this.state.suppliers && this.props.supplier && this.state.suppliers.length < this.props.supplier.count && (
                    <Button
                        className="color-light-blue cursor-pointer load_more"
                        onClick={this.handleMoreClick.bind(this)}
                        loading={this.state.showSupplierLoader}
                    >
                        {this.props.auth ?
                            this.props.auth.isActive ? "Load More"
                                :
                                "Upgrade to see the complete list"
                            : "Load More"}
                    </Button>
                )} */}
                    </Grid.Column>
                </Layout>
            </>
        )
    }
}

const mapStateToProps = ({ user, auth }) => {
    return {
        customers: user.customers,
        userProfileDetails: user.userProfileDetails,
        auth
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        ...bindActionCreators(userActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Customers);