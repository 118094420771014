import {
    SINGLE_COMPANY_INFO,
    COMPANY_FOLLOWERS,
    GET_COMPANY_PRODUCTS,
    SET_COMPANY_EMPLOYEES,
    NEW_COMPANY_ID
} from '../../config/types'

export default function (state = {}, action) {
    switch (action.type) {
        case SINGLE_COMPANY_INFO:
            return { ...state, info: action.payload };
        case "SHOW_LOGIN_MODAL":
            return { ...state, showLoginModal: action.payload };
        case COMPANY_FOLLOWERS:
            return { ...state, followers: action.payload }
        case GET_COMPANY_PRODUCTS:
            return { ...state, products: action.payload }
        case SET_COMPANY_EMPLOYEES:
            return { ...state, employees: action.payload }
        case NEW_COMPANY_ID:
            return { ...state, new_company_id: action.payload }
        default:
            return state;
    }

}