import React from 'react';
import { Button } from 'semantic-ui-react';
import NoRawMaterialSvg from '../../../assets/images/raw-materials.svg';

function NoRawMaterials({ setShowAddRawMaterials }) {
    return (
        <React.Fragment>
            <div className="nodatalist-wrapper">
                <div className="no-nodatalist">
                    <img alt={NoRawMaterialSvg} src={NoRawMaterialSvg} />
                    <b>It looks like there are no raw material added for this product.</b>
                    <p className="mb-4">Add the raw materials required to make this product</p>
                    <Button primary onClick={() => setShowAddRawMaterials(true)}>Add Material</Button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default NoRawMaterials;