import React from 'react';
import { Button, Label, Dropdown } from 'semantic-ui-react';
import axios from 'axios';
import { SvgIcon } from '../../common';
import { BASE_URL } from '../../../config/config';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { commonActions, productActions } from '../../../actions';
import { apiStatus } from '../../../common/constants';
import AddNewProduct from '../../common/AddProduct/AddNewProduct';
import SweetAlerts from '../../common/sweetalerts/sweetalert';
import { toast, ToastContainer } from 'react-toastify';


class AddApplications extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            addRawButtonLoading: false,
            resultsRendered: 4,
            showLoadMore: true,
            condition: false,
            LastEvaluatedKey: props.supplier && props.supplier.LastEvaluatedKey,
            applications: [],
            showSupplierLoader: true,
            product_submit: false,

        };
    }

    addApplications = () => {
        const { auth, addApplications, productId, productName, showLoginModal } = this.props;
        if (!auth) {
            showLoginModal(true);
        }
        else {
            if (this.state.applications.length > 0){
                addApplications(this.state.applications, productId, productName);
                this.clearApplicationList();
                setTimeout(() => {
                    this.handleUpdateSucess();
                }, 1000);
            } else {
                console.log('in here')
                toast.error('Please add a application to continue !', {
                    autoClose: 3000,
                });
            }
        }
    }

    clearApplicationList = () => {
        this.setState({ applications: [] })
    }

    removeSupplier = (supplier) => {
        const index = this.state.applications.findIndex(supplier => supplier.company_id === supplier.company_id);
        const applications = [...this.state.applications];
        applications.splice(index, 1);
        this.setState({ applications });
    }

    handleResultSelect = (e, data) => {
        if (!data.options) {
            return;
        }
        this.setState({ value: data.value });
        const selectedValue = data.options.find(item => item.value === data.value);
        let newApplications = this.state.applications;
        if (selectedValue) {
            newApplications.push({
                id: selectedValue.key,
                name: selectedValue.name,
                country: selectedValue.country
            });

        }
        this.setState({
            applications: newApplications,
            value: '',
            results: []
        });
    };

    handleSearchChange = (e, { searchQuery }) => {
        this.setState({ searchQuery, isLoading: true });
        axios.get(`${BASE_URL}/api/v1/product/search/${searchQuery}`, { withCredentials: true }).then(response => {
            const result = response.data && response.data.map(k => {
                return {
                    key: k._source.id,
                    value: k._source.id,
                    text: k._source.name,
                    name: k._source.name
                }
            })
            this.setState({
                isLoading: false,
                results: result,
            });
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    }

    handleSucess = () => {
        this.setState({ product_submit: true });
    }

    handleCloseAlert = () => {
        this.setState({ product_submit: false })
    }

    handleUpdateSucess = () => {
        this.setState({ product_update_submit: true });
    }

    handleUpdateCloseAlert = () => {
        this.setState({ product_update_submit: false })
    }

    render() {
        const { isLoading, value, results, applications, product_submit, product_update_submit } = this.state;
        const { saveStatus, productName, productId } = this.props;
        const { open } = this.state;

        return (
            <>
                <div>
                    <ToastContainer />
                </div>
                <div className="supplier-head-add mb-3">
                    <div className='supplier-head-add-inner'>
                        <div>
                            <h3>Help us map the value chain.</h3>
                            <p>Are there more applications of {productName}? Add more to build the network.</p>
                        </div>
                        <div className="w-100 mt-3 d-flex justify-content-between">
                            {open &&
                                <AddNewProduct
                                    productId={productId}
                                    open={open}
                                    handleClose={this.handleClose}
                                    type="Applications"
                                    handleSucess={this.handleSucess}
                                />
                            }

                            <Dropdown
                                icon="search"
                                fluid
                                loading={isLoading}
                                search
                                selection
                                className="selectsupplier-drop"
                                placeholder='Search'
                                onChange={this.handleResultSelect}
                                onSearchChange={this.handleSearchChange}
                                options={results}
                                value={value}
                                allowAdditions
                                additionLabel={
                                    <span className="text-primary addnewcompany-link" onClick={() => this.setState({ open: true })}> + Create a New Product </span>
                                }
                                noResultsMessage={null}
                            />
                            <Button type='submit' primary onClick={this.addApplications} loading={saveStatus === apiStatus.IN_PROGRESS}>Add To List</Button>
                        </div>
                        <div className="w-100 selectedsupplier">
                            {
                                applications.map(supplier =>
                                    <span className="badge"><span className="badge-inner">{supplier.name}<SvgIcon name="close" viewbox="0 0 23.126 23.126" onClick={() => this.removeSupplier(supplier)} /></span></span>
                                )
                            }
                        </div>

                        {
                            applications.length > 0 &&
                            <div className="text-right mt-3">
                                <Button type='submit' onClick={this.clearApplicationList}>Cancel</Button>
                            </div>
                        }
                    </div>
                </div>
                {
                    product_submit && <SweetAlerts type={'S'} title={'Product has been added successfully'} message={'The product you submitted is undergoing a verification process and will be accessible to everyone once it receives approval'} closeAlert={this.handleCloseAlert}> </SweetAlerts>
                }

                {

                    product_update_submit && <SweetAlerts type={'S'} title={'Success'} message={'Application added successfully'} closeAlert={this.handleUpdateCloseAlert}> </SweetAlerts>
                }
            </>

        )
    }
}

const mapStateToProps = ({ singleProduct, common, auth }) => {
    return {
        productName: singleProduct.info && singleProduct.info.Item.name,
        saveStatus: common.saveStatus,
        auth
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        ...bindActionCreators({ ...productActions, ...commonActions }, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddApplications);