import axios from 'axios';
import { apiStatus } from '../../common/constants';
import { BASE_URL } from '../../config/config';
import { SAVE_STATUS, SHOW_LOGIN_MODAL, NAVIGATE_TO_PRICING, SUBMIT_INQUIRY_SUCCESS } from '../../config/types';

export const setSaveStatus = (status) => {
    return { type: SAVE_STATUS, payload: status };
}

export const showLoginModal = (value) => {
    return { type: SHOW_LOGIN_MODAL, payload: value };
};

export const navigateToPricing = (value) => {
    return { type: NAVIGATE_TO_PRICING, payload: value }
}

export const submitInquiry = (formData) => async (dispatch) => {
    try {
        dispatch(setSaveStatus(apiStatus.IN_PROGRESS));
        const bodyFormData = new FormData();
        Object.keys(formData).forEach(field => {
            let value;
            if (Array.isArray(formData[field])) {
                value = JSON.stringify(formData[field]);
            }
            else {
                value = formData[field];
            }
            bodyFormData.append(field, value)
        })

        const response = await axios.post(
            `${BASE_URL}/api/v2/inquiry`,
            bodyFormData,
            { headers: { "content-type": "multipart/form-data" }, withCredentials: true }
        )
        if (response) {
            dispatch(setSaveStatus(apiStatus.COMPLETED));
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const submitInquirySuccess = () => {
    return { type: SUBMIT_INQUIRY_SUCCESS }
}
