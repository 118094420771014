import { BASE_URL } from '../../config/config'
import {
    SINGLE_COMPANY_INFO,
    COMPANY_FOLLOWERS,
    DUMMY,
    GET_COMPANY_PRODUCTS,
    SET_COMPANY_EMPLOYEES,
    NEW_COMPANY_ID
} from '../../config/types'
import axios from 'axios'
import { apiStatus } from '../../common/constants';
import { setSaveStatus } from '../common/common';
import { getProductSupplier } from '../product/product';

export const getCompanyInfo = (id) => async (dispatch) => {
    let res = await axios.get(`${BASE_URL}/api/v1/company/${id}`, { withCredentials: true })
    dispatch({ type: SINGLE_COMPANY_INFO, payload: res.data });
};

export const uploadCompanyLogo = (id, logo) => async (dispatch) => {
    const bodyFormData = new FormData();
    bodyFormData.append("logo", logo);

    let res = await axios.put(`${BASE_URL}/api/v1/company/${id}/logo`, bodyFormData,
        {
            headers: {
                'content-type': 'multipart/form-data'
            },
            withCredentials: true
        }
    )
    // dispatch({ type: COMPANY_LOGO_UPDATED, payload: res.data });
    dispatch(getCompanyInfo(id));
};

export const getCompanyFollowers = id => async dispatch => {
    let res = await axios.get(`${BASE_URL}/api/v2/companies/${id}/followers`, { withCredentials: true });
    dispatch({ type: COMPANY_FOLLOWERS, payload: res.data });
};

export const createCompany = (data) => async dispatch => {
    dispatch(setSaveStatus(apiStatus.IN_PROGRESS));
    let response = await axios.post(`${BASE_URL}/api/v1/company`, data, { withCredentials: true })
    var res_data = response.data

    if (res_data.messages[0]['message'] == 'Company already exist') {
        dispatch(setSaveStatus(apiStatus.ALREADY_EXISTS));
    } else {
        dispatch(setSaveStatus(apiStatus.COMPLETED));
        dispatch({ type: NEW_COMPANY_ID, payload: res_data.data.company_id });
        if (data.product_id != undefined && data.product_id != '' && data.product_id != null) {
            dispatch(getProductSupplier(data.product_id));
        }
    }
};

export const followCompany = (id, name, cb) => async (dispatch) => {
    let res = await axios.post(`${BASE_URL}/api/v1/me/followedCompanies`, { company_id: id }, { withCredentials: true })
    cb();
    dispatch({ type: DUMMY, payload: res.data });
};

export const unfollowCompany = (id, name, cb) => async (dispatch) => {
    let res = await axios.delete(`${BASE_URL}/api/v1/me/followedCompanies/${id}`, { withCredentials: true })
    cb();
    dispatch({ type: DUMMY, payload: res.data });
};

export const getCompanyProducts = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/v1/company/${id}/products`, { withCredentials: true });
        dispatch({ type: GET_COMPANY_PRODUCTS, payload: response.data })
    } catch (error) {
        console.log("FAILED")
    }
}

export const updateCompanyDetails = (id, data) => async (dispatch) => {
    try {
        dispatch(setSaveStatus(apiStatus.IN_PROGRESS));
        const response = await axios.put(`${BASE_URL}/api/v1/company/${id}`, data, { withCredentials: true })
        dispatch(getCompanyInfo(id));
        if (response.data == 'Company already exist.') {
            dispatch(setSaveStatus(apiStatus.ALREADY_EXISTS));
        } else {
            dispatch(setSaveStatus(apiStatus.COMPLETED));
        }
    } catch (error) {
        console.log(error)
    } /* finally {

        dispatch(setSaveStatus(apiStatus.COMPLETED));

    } */
}


export const getEmployees = (companyId) => async (dispatch) => {
    var response = await axios.get(`${BASE_URL}/api/v1/company/${companyId}/employees`, { withCredentials: true });

    dispatch({ type: SET_COMPANY_EMPLOYEES, payload: response.data })
}

export const addProducts = (companyId, companyName, products) => async (dispatch) => {
    dispatch(setSaveStatus(apiStatus.IN_PROGRESS));

    const promises = products.map(product => {
        let data = {
            company_id: companyId,
            company_name: companyName,
            product_id: product.id,
            product_name: product.name,
        };
        return axios.post(`${BASE_URL}/api/v1/product/supplier`, data, { withCredentials: true });
    });

    Promise.all(promises)
        .then(() => {
            dispatch(setSaveStatus(apiStatus.COMPLETED));
            dispatch(getCompanyProducts(companyId));
        })
}