import React, { Component } from 'react';
import { Button, Loader } from 'semantic-ui-react';
import './invite.scss';
import { sendInvite } from './api';
import { emailRegex } from '../../../common/constants';

class Email extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputsCount: 1
        };
    }

    sendInvite = () => {
        if (!emailRegex.test(this.state.emailId.trim())) {
            this.setState({ invalidEmail: true });
        }
        else {
            this.setState({ invalidEmail: false, inviteStatus: "IN_PROGRESS" });
            sendInvite(this.state.emailId, this.props.type, this.props.entityId)
                .then(() => {
                    this.setState({ invalidEmail: false, inviteStatus: "SENT" });
                })
                .catch(() => {
                    this.setState({ invalidEmail: false, inviteStatus: "ERROR" });
                });
        }
    }

    setEmail = (e) => {
        this.setState({ emailId: e.target.value });
    }

    render() {
        const { invalidEmail, inviteStatus } = this.state;
        return (
            <>
                <div className="ui input invitation-email">
                    <input type="text" onChange={this.setEmail} placeholder="Email Address" disabled={inviteStatus === "SENT"} />
                    <Button primary className="custom-btn text-inherit" onClick={this.sendInvite} disabled={inviteStatus === "SENT"}>
                        Send invite
                    </Button>
                </div>

                <div className="message">
                    {
                        invalidEmail && <h3>Invalid email id</h3>
                    }
                    {
                        inviteStatus === "IN_PROGRESS" && <Loader inline active />
                    }
                    {
                        inviteStatus === "SENT" && <i className="icon-checked"></i>
                    }
                </div>
            </>

        );
    }
}

export default Email;
