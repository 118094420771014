import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom'
import SvgSprite from "../utility/SvgSpriteLoader"
import { connect } from 'react-redux'
import * as actions from '../actions/auth/auth'
import AllProducts from './product/AllProducts'
import AddCompany from './AddCompany'
import AddProduct from './product/AddProduct'
// import ProductHome from './product/ProductHome'
import ProductHomeNew from './product/ProductHomeNew'
// import ProductActivity from './product/ProductActivity'
import MyProducts from './myproducts/MyProducts'
import Company from './company_deprecated/Company'
import NewLanding from './landing/NewLanding'
import Feed from './networkFeed/NetworkFeedLayout'
// import Profile from './profile/Profile'
// import Message from './newMessage/Message'
import ProfileFeed from './profileFeed/profileFeed'
import profileFeedStatic from './profileFeed/profileFeedStatic'
// import About from './about/About'
import Faq from './faq/Faq'
import NetworkFeedLayout from './networkFeed/NetworkFeedLayout'
import Contact from './contact/Contact'
import Hidden from './hidden/Hidden'
import onBoarding from './onBoarding/onBoarding'
import Notifications from './notifications/Notifications'
import EmailVerificationMessage from './common/EmailVerificationMessage'
// import UserInfo from './onBoarding/UserInfo'
import PostDetails from './networkFeed/PostDetails.js';
import Event from './events/event'
import AddEvent from './events/addevent'
import Pricing from './pricing/pricing'
import ScrollToTop from './ScrollToTop';
// import PaymentSuccess from './pricing/PaymentSuccess';
import PaymentFailure from './pricing/PaymentFailure';

// Import css
import '../assets/scss/style.scss';

// Import new pages
import NewHomeView from './newhome/home';
import AboutUsView from './aboutus/aboutus';
import SignUpView from './authentication/signup/SignUpView';
/* import UpgradeView from './pricing/upgrade/upgrade'; */
import ListingNamesView from './pricing/listing-names/listing-names';
import ThankyouView from './pricing/thank-you/thank-you';
import SetNewPassword from './authentication/setnew-password/SetNewPassword';
import EditProfileView from './profile/Profile';
import InviteView from './common/invite/invite';
import selectMail from "./select-mail/select-mail";
import SelectMailMessage from "./select-mail/select-mail-message";

//Svg Sprite
import svgFile from "../assets/images/svg/svg-sprite.svg";

import Industry from './industry/Industry';
import Vendors from './vendors/Vendors';
import Customers from './customers/Customers';
import connections from './connections/connections';
import ProductpageWip from './productpage_wip/productpage_wip';
import CompanyPage from './company_page/company_page';
import FeedViewDev from './feed-new/feed-new';
import FeedView from './feed-new-bk/feed-new';

import MessageLayout from './message/MessageLayout';
import eventsList from './events/eventsList';
import VerifyOtp from './authentication/verify-otp/VerifyOtp';

import ArticlesEditor from './networkFeed/articles/ArticlesEditor';
import ArticlesView from './networkFeed/articlesview';
import RequestQuote from './request-quote';
import LoginWithOtp from './authentication/LoginWithOtp';
import LoginWithOtpNewUser from './authentication/LoginWithOtpNewUser';
import ProfileCompletion from './networkFeed/Profile-Completion';
import RedirectFailure from './authentication/LoginWithOtp/RedirectFailure.js';
import MediaQuery from 'react-responsive';
import { LinkAuthenticate } from './authentication/LoginWithOtp/LinkAuthenticate.js';

class App extends Component {

  componentDidMount() {
    this.props.fetchUser();
  }

  componentDidUpdate() {
    if (this.props.auth.firstName && this.props.auth.lastName) {
      // User details needed only till user logs-in. Not needed after that
      // Used first name and last name here is there is no difference between new user with pending details (fn/ln) and old logged in user in terms of auth props
      localStorage.removeItem('userId')
      localStorage.removeItem('newUser')
      localStorage.removeItem('email')
      sessionStorage.removeItem('otp')
    }
  }

  render() {
    if (this.props.auth === null) { return null };
    return (
      <BrowserRouter onUpdate={() => window.scrollTo(100, 100)}>
        <div className="main-wrapper">
          <SvgSprite url={svgFile} />
          <ScrollToTop>
            <Route exact path="/" component={NewHomeView} />
            <Route exact path="/NewLanding" component={NewLanding} />
            {/* TODO */}
            {/* <Route exact path="/feed" component={Feed} /> */}
            <Route exact path="/contact" component={Contact} />
            {/* <Route exact path="/hidden" component={Hidden} /> */}
            {/* <Route exact path="/company" component={Company} /> */}
            <Route exact path="/profile" component={EditProfileView} />
            {/* <Route exact path="/message" component={Message} /> */}
            {/* <Route exact path="/aboutusold" component={About} /> */}
            <Route exact path="/faq" component={Faq} />
            {/* <Route exact path="/onBoarding" component={onBoarding} />
            <Route exact path="/profileFeed/:id" component={ProfileFeed} />
            <Route exact path="/profileFeedStatic/" component={profileFeedStatic} /> */}
            {/* //TODO: is post id needed? */}
            <Route path="/networkFeed/:feedType/:entityId?/:postId?" component={NetworkFeedLayout} />
            {/* <Route exact path="/product_old/:id/:followers?" component={ProductHomeNew} /> */}
            {/* <Route exact path="/product/:id/activity" component={ProductActivity} /> */}
            {/* <Route exact path="/product/:id/followers" component={ProductHomeNew} /> */}
            {/* <Route exact path="/myproduct" component={MyProducts} />
            <Route exact path="/products" component={AllProducts} />
            <Route exact path="/addCompany" component={AddCompany} /> */}
            {/* <Route exact path="/update/product/:id " component={AddCompany} /> */}
            {/* <Route exact path="/add/product" component={AddProduct} /> */}
            <Route exact path="/notifications" component={Notifications} />
            <Route exact path="/signup/verificationMessage" component={EmailVerificationMessage} />
            {/* TODO: add SEO */}
            <Route exact path="/feed/post/:postId" component={PostDetails} />
            <Route exact path="/events/:name?/:eventId" component={Event} />
            <Route exact path="/addevent" component={AddEvent} />
            <Route exact path="/edit-event/:id" component={AddEvent} />
            <Route exact path="/pricing" component={Pricing} />
            <Route exact path="/payment/success" component={ListingNamesView} />
            <Route exact path="/payment/failure" component={PaymentFailure} />
            <Route path="/industry/:id" component={Industry} />
            <Route exact path="/aboutus" component={AboutUsView} />
            <Route exact path="/signup" component={SignUpView} />
            {/*   <Route exact path="/upgrade" render={(props) => <UpgradeView {...props} auth={this.props.auth} />} /> */}
            <Route exact path="/thankyou" component={ThankyouView} />
            <Route exact path="/resetpassword" component={SetNewPassword} />
            <Route exact path="/connections" component={connections} />
            <Route exact path="/invite" component={InviteView} />
            <Route exact path="/vendors" component={Vendors} />
            <Route exact path="/customers" component={Customers} />
            <Route exact path="/select-mail" component={selectMail} />
            <Route exact path="/select-mail-message" component={SelectMailMessage} />
            <Route exact path="/product/:name?/:id/:view?" component={ProductpageWip} />
            <Route exact path="/company/:name?/:id/:view?" component={CompanyPage} />
            <Route exact path="/feeds-dev" component={FeedViewDev} />
            <Route exact path="/feeds" component={FeedView} />
            <Route exact path="/message" component={MessageLayout} />
            <Route exact path="/event-list" component={eventsList} />
            <Route exact path="/verify-otp" component={VerifyOtp} />
            <Route exact path="/add-article/:feedType?/:entityId?" component={ArticlesEditor} />
            <Route exact path="/edit-article/:id/:feedType?/:entityId?" component={ArticlesEditor} />
            <Route exact path="/article/:name?/:postId" component={ArticlesView} />
            <Route exact path="/request-quote/:type?/:id?" component={RequestQuote} />
            <Route exact path="/otp-login" component={LoginWithOtp} />
            <Route exact path="/otp-login/:failed" component={RedirectFailure} />
            <Route exact path="/otp-new-login" component={LoginWithOtpNewUser} />
            <Route exact path="/link-authenticate/:userId" component={LinkAuthenticate} />
            <Route exact path="/profile-completion" component={ProfileCompletion} />
          </ScrollToTop>
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return { auth };
}

export default connect(mapStateToProps, actions)(App);
