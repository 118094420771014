import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NavbarLoggedIn from './NavbarLoggedIn';
import NavbarPreLogin from './NavbarPreLogin';
import { commonActions } from '../../../../actions';
import { BottomPopup } from './BottomPopup';

class Navbar extends React.Component {
    render() {
        const { auth } = this.props;
        return (
            <>
                {
                    auth ?
                        <NavbarLoggedIn {...this.props} />
                        :
                         <NavbarPreLogin {...this.props} />
                }
                {/* <BottomPopup /> */}
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return { ...bindActionCreators({ ...commonActions }, dispatch) }
}

const mapStateToProps = ({ auth, common }) => {
    return { auth, common }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);