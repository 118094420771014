import React from 'react';
import MetaTags from 'react-meta-tags';
import { helperMethods, constants } from '../../common'
import BannerImage from '../../assets/images/homepage_metatag_image.jpeg';
import { Schema } from './Schema';
import { formatIsoMiliseconds, replaceSpaceInName } from '../../common/helperMethods';
import post_metatag_image from '../../assets/images/post_metatag_image.jpg'

const baseUrl = window && window.location && window.location.origin;
const SEO = ({ title, robots, canonicalUrl, pageType, pageContent, alternateLink }) => {
    if (!baseUrl) return null;

    var videoPlayerLink = '';
    var twitter_og_media = '';
    var imageUrl = '';
    var videoUrl = '';
    var youtubeVideoId = '';
    var videmoVideoId = '';
    var post_or_page_url = '';
    var articleUrl = '';
    var vimeoUrlLink = '';
    var articleText = '';
    var articleDesc = '';
    var isYoutubeVideo = false;
    var isVimeoVideo = false;
    var alternate_link = '';
    var schema = '';

    if (pageType == 'post') {
        let articleMediaType = pageContent.media && Object.keys(pageContent.media)[0];

        switch (articleMediaType) {
            case "image":
                imageUrl = pageContent.media.image.data;
                break;
            case "video":
                videoUrl = pageContent.media.video.data;
                break;

            default:
                break;
        }

        articleText = pageContent.text;

        if (articleText) {
            youtubeVideoId = helperMethods.getYouTubeVideoId(articleText);
            if (youtubeVideoId) {
                articleText = articleText.replace(constants.youtubeUrlRegex, '');
                isYoutubeVideo = true
            }

            if (!youtubeVideoId) {
                articleUrl = helperMethods.getUrlFromHtml(articleText);
            }

            vimeoUrlLink = helperMethods.checkVimeoUrl(articleText);
            if (vimeoUrlLink != '') {
                if (!/^https?:\/\//i.test(vimeoUrlLink)) {
                    vimeoUrlLink = 'http://' + vimeoUrlLink;

                }
            }
        }

        if (videoUrl != '') {
            videoPlayerLink = videoUrl;
        }
        if (vimeoUrlLink != '') {
            videoPlayerLink = vimeoUrlLink;
            isVimeoVideo = true;
            videmoVideoId = helperMethods.getVimeoIdFromUrl(vimeoUrlLink)
        }

        if (videoPlayerLink) {
            articleText = articleText.replaceAll(videoPlayerLink, '');
        }

        articleText = (articleText != undefined && articleText != '') ? articleText.replace(/<\/?[^>]+(>|$)/g, "").trim() : '';
        articleText = (articleText != undefined && articleText != '') ? articleText.replaceAll('&nbsp;', '') : '';

        articleDesc = articleText;
        if (pageContent.title != undefined && pageContent.text != '') {
            articleText = pageContent.title;
        }

        if (articleText.length > 50) {
            articleText = articleText.substr(0, 50)
        }

        if (articleDesc.length > 165) {
            articleDesc = articleDesc.substr(0, 165)
        }

        if (articleDesc == '') {
            articleDesc = `Explore the widest range of products from the best manufacturer companies. Connect with manufacturers and suppliers at Mappes.io.`;
        }

        if (isVimeoVideo) {
            twitter_og_media = "https://vumbnail.com/" + videmoVideoId + ".jpg";
        } else if (isYoutubeVideo) {
            twitter_og_media = "https://img.youtube.com/vi/" + youtubeVideoId + "/maxresdefault.jpg";
        } else if (imageUrl != '') {
            twitter_og_media = imageUrl
        } else {
            twitter_og_media = post_metatag_image;
        }

        post_or_page_url = baseUrl + '/feed/post/' + pageContent.id
    }

    if (pageType == 'article') {
        console.log('pageContent ', pageContent)
        articleText = pageContent.text;
        imageUrl = (pageContent.media.image!= undefined ) ? pageContent.media.image.data : '';
        articleText = (articleText != undefined && articleText != '') ? articleText.replace(/<\/?[^>]+(>|$)/g, "").trim() : '';
        articleText = (articleText != undefined && articleText != '') ? articleText.replaceAll('&nbsp;', '') : '';

        articleDesc = articleText;
        if (pageContent.title != undefined && pageContent.text != '') {
            articleText = pageContent.title;
        }

        if (articleText.length > 50) {
            articleText = articleText.substr(0, 50)
        }

        if (articleDesc.length > 165) {
            articleDesc = articleDesc.substr(0, 165)
        }

        if (articleDesc == '') {
            articleDesc = `Explore the widest range of products from the best manufacturer companies. Connect with manufacturers and suppliers at Mappes.io.`;
        }

        if (imageUrl != '') {
            twitter_og_media = imageUrl
        } else {
            twitter_og_media = post_metatag_image;
        }
        post_or_page_url = `${baseUrl}/article/${replaceSpaceInName(pageContent.title)}/${pageContent.id}`;
        schema = Schema({
            pageType,
            name: articleText,
            headline:pageContent.title,
            description: pageContent.text,
            image: twitter_og_media,
            datePublished: formatIsoMiliseconds(pageContent.added),
            dateModified: formatIsoMiliseconds(pageContent.updated),
            author: pageContent.user,
            url: post_or_page_url
        })
    }

    if (pageType == 'product') {
        if (pageContent != undefined && pageContent.Item != undefined) {
            articleText = (pageContent.Item.seo_title != undefined && pageContent.Item.seo_title != '') ? pageContent.Item.seo_title : pageContent.Item.name;
            articleDesc = (pageContent.Item.seo_description != undefined && pageContent.Item.seo_description != '') ? pageContent.Item.seo_description : pageContent.Item.description;
            if (articleDesc != undefined && articleDesc != '') {
                if (articleDesc.length > 165) {
                    articleDesc = articleDesc.substr(0, 165)
                    // articleDesc = articleDesc + '' + `. Connect with ${articleText} manufacturers and suppliers at Mappes.io.`
                } else {
                    if (articleDesc.length > 100) {

                        articleDesc = articleDesc;  // + '' + ``
                    } else {
                        articleDesc = `Explore the widest range of ${articleText} product from the best manufacturer companies. Connect with ${articleText} manufacturers and suppliers at Mappes.io.`;
                    }
                }
            } else {
                articleDesc = `Explore the widest range of ${articleText} product from the best manufacturer companies. Connect with ${articleText} manufacturers and suppliers at Mappes.io.`;
            }
            post_or_page_url = baseUrl + canonicalUrl;
            alternate_link = post_or_page_url;
            if (pageContent.Item != undefined && pageContent.Item.original_images != undefined && pageContent.Item.original_images.length > 0) {
                twitter_og_media = (pageContent.Item.original_images[0]['value'] != undefined) ? pageContent.Item.original_images[0]['value'] : post_metatag_image
            }
            schema = Schema({
                pageType,
                name: articleText,
                image: twitter_og_media,
                url: post_or_page_url,
                description: pageContent.Item.description
            })
        }
    }

    if (pageType == 'company') {
        if (pageContent != undefined) {

            articleText = (pageContent.seo_title != undefined && pageContent.seo_title != '') ? pageContent.seo_title : pageContent.name;
            articleDesc = (pageContent.seo_description != undefined && pageContent.seo_description != '') ? pageContent.seo_description : pageContent.description;
            if (articleDesc != undefined && articleDesc.trim() != '') {
                if (articleDesc.length > 165) {
                    articleDesc = articleDesc.substr(0, 165)
                } else {
                    if (articleDesc.length > 100) {
                        articleDesc = articleDesc;  // + '' + ``
                    } else {
                        articleDesc = `Explore ${pageContent.name} on Mappes.io, To know about what they offer and what raw materials and equipment they need to manufacture their technologies.`;
                    }
                }
            } else {
                articleDesc = `Explore ${pageContent.name} on Mappes.io, To know about what they offer and what raw materials and equipment they need to manufacture their technologies.`;
            }
            post_or_page_url = baseUrl + canonicalUrl;
            alternate_link = post_or_page_url;
            twitter_og_media = (pageContent['logo'] != undefined && pageContent['logo'] != '') ? pageContent['logo'] : post_metatag_image

            schema = Schema({
                pageType,
                name: articleText,
                image: twitter_og_media,
                url: post_or_page_url,
                description: pageContent.description
            })
        }
    }

    if (pageType == 'event') {
        if (pageContent != undefined) {
            articleText = pageContent.eventName;

            articleDesc = pageContent.details;
            articleDesc = (articleDesc != '' && articleDesc != undefined) ? articleDesc.replace(/<\/?[^>]+(>|$)/g, "").trim() : ''
            articleDesc = (articleDesc != '' && articleDesc != undefined) ? articleDesc.replaceAll('&nbsp;', '') : '';
            if (articleDesc != undefined && articleDesc != '') {
                if (articleDesc.length > 165) {
                    articleDesc = articleDesc.substr(0, 165)
                }
            } else {
                articleDesc = ``;
            }
            post_or_page_url = baseUrl + canonicalUrl;
            alternate_link = post_or_page_url;
            twitter_og_media = (pageContent['image'] != undefined && pageContent['image'] != '') ? pageContent['image'] : post_metatag_image
            schema = Schema({
                pageType,
                name: articleText,
                description: pageContent.details,
                image: twitter_og_media,
                startDate: formatIsoMiliseconds(+pageContent.startDate),
                endDate: formatIsoMiliseconds(+pageContent.endDate)
            })
        }
    }

    if (pageType == 'homepage') {
        articleText = 'Ecosystem Driven B2B Platform for the High-Tech Industry';
        articleDesc = 'Mappes.io is an AI powered B2B platform that leverages product ecosystem for manufacturers of advanced machines and materials to make sales and procurement process efficient and faster.';
        post_or_page_url = baseUrl;
        twitter_og_media = baseUrl + BannerImage;
        alternate_link = post_or_page_url + '/';
        schema = Schema({ pageType })
    }

    if (twitter_og_media == '' && (pageType == 'company' || pageType == 'product' || pageType == 'post')) {
        twitter_og_media = baseUrl + BannerImage;
    }

    return (
        <>
            <MetaTags>
                {pageType == 'homepage' ? (<title> Mappes.io | {articleText} </title>) : ''}
                {pageType != 'homepage' && articleText ? (<title> {articleText} | Mappes.io  </title>) : ''}
                {pageType != 'homepage' && articleText == '' ? (<title> Mappes.io </title>) : ""}
                <meta name="robots" content={robots} />
                <link rel="canonical" href={`${baseUrl}${canonicalUrl}`} />
                {alternate_link ? (<link id="alternate-link" rel="alternate" href={`${alternate_link}`} hreflang="en-IN" />) : ''}

                {/* post / homepage/ product / company / event - start  */}
                <meta name="description" content={articleDesc} />
                {pageType == 'post' || pageType == 'homepage' || pageType == 'product' || pageType == 'company' || pageType == 'event' || pageType === 'article' ? (
                    <>
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:site" content="@mappesInc" />
                        <meta name="twitter:creator" content="@mappesInc" />
                        <meta name="twitter:player" content="https://www.youtube.com/@mappesinc4987" />
                        <meta name="twitter:image" content={twitter_og_media} />
                        <meta name="twitter:title" content={articleText} />
                        <meta name="twitter:description" content={articleDesc} />
                        <meta name="twitter:url" content={post_or_page_url} />

                        <meta property="og:locale" content="en" />
                        <meta property="og:site_name" content="Mappes" />
                        <meta property="og:type" content="Website" />
                        <meta property="og:image" content={twitter_og_media} /> {/* Image - "Image" , video - (Thumbnail), text - "" */}
                        <meta property="og:title" content={articleText} />
                        <meta property="og:description" content={articleDesc} />
                        <meta property="og:url" content={post_or_page_url} />
                        {
                            schema &&
                            <script id="schema" type="application/ld+json">{JSON.stringify(schema)}</script>
                        }
                    </>) : ''}
                {/* post / homepage/ product / company / event - end  */}

            </MetaTags>
        </>
    );
}

export default SEO;