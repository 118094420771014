import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table, Modal, Form, Select, Accordion, Icon } from 'semantic-ui-react';
import { replaceSpaceInName } from '../../common/helperMethods';
import { SvgIcon, Row, Col } from '../common';
import FollowButton from '../productpage_wip/FollowButton';

const supplerrowdatalist = [
    {
        key: 1,
        name: "Product Name 1",
        porstcount: "29",
    },
    {
        key: 2,
        name: "Product Name 2",
        porstcount: "29",
    },
    {
        key: 3,
        name: "Product Name 3",
        porstcount: "29",
    },
    {
        key: 4,
        name: "Product Name 4",
        porstcount: "29",
    }
]

const options1 = [
    { Key: 'add', text: <AddaNewCopmany /> },
    { key: 'Name_1239898790', text: 'Name_123989879', value: 'Name_1239898790' },
    { key: 'Name_1239898792', text: 'Name_123989879', value: 'Name_1239898792' },
    { key: 'Name_12379', text: 'Name_12379', value: 'Name_12379' },
    { key: 'Name_1239898791', text: 'Name_123989879', value: 'Name_1239898791' },
    { key: 'Fiber12398345', text: 'Fiber12398345', value: 'Fiber12398345' },
    { key: 'Fiber12398346', text: 'Fiber12398346', value: 'Fiber12398346' },
]

const countryOptions = [
    { key: 'af', value: 'af', text: 'Afghanistan' },
    { key: 'ax', value: 'ax', text: 'Aland Islands' },
    { key: 'al', value: 'al', text: 'Albania' },
    { key: 'dz', value: 'dz', text: 'Algeria' },
    { key: 'as', value: 'as', text: 'American Samoa' },
    { key: 'ad', value: 'ad', text: 'Andorra' },
    { key: 'ao', value: 'ao', text: 'Angola' },
    { key: 'ai', value: 'ai', text: 'Anguilla' },
    { key: 'ag', value: 'ag', text: 'Antigua' },
    { key: 'ar', value: 'ar', text: 'Argentina' },
    { key: 'am', value: 'am', text: 'Armenia' },
    { key: 'aw', value: 'aw', text: 'Aruba' },
    { key: 'au', value: 'au', text: 'Australia' },
    { key: 'at', value: 'at', text: 'Austria' },
    { key: 'az', value: 'az', text: 'Azerbaijan' },
    { key: 'bs', value: 'bs', text: 'Bahamas' },
    { key: 'bh', value: 'bh', text: 'Bahrain' },
    { key: 'bd', value: 'bd', text: 'Bangladesh' },
    { key: 'bb', value: 'bb', text: 'Barbados' },
    { key: 'by', value: 'by', text: 'Belarus' },
    { key: 'be', value: 'be', text: 'Belgium' },
    { key: 'bz', value: 'bz', text: 'Belize' },
    { key: 'bj', value: 'bj', text: 'Benin' },
]


const filterOption = [
    { key: 'filter1', value: 'filter1', text: 'Fitler by country' },
    { key: 'filter2', value: 'filter2', text: 'Fitler by date' }
]

function AddaNewCopmany() {
    const [open, setOpen] = React.useState(false)

    return (
        <Modal
            className="createa-supplier"
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size="mini"
            trigger={<span className="text-primary addnewcompany-link">+ Add a New Company</span>}
        >
            <Modal.Content>
                <Modal.Description>
                    <div className="mb-3"><b>Create A Supplier</b></div>
                    <Form>
                        <Form.Field>
                            <label>Company Name</label>
                            <input placeholder='Formal business name' />
                        </Form.Field>
                        <Form.Field>
                            <label>Website</label>
                            <input placeholder='www.company.com' />
                        </Form.Field>
                        <Form.Field>
                            <label>Company Address</label>
                            <input placeholder='' value="12345 Street B" />
                        </Form.Field>
                        <Form.Field>
                            <Row>
                                <Col sm="6">
                                    <label>Country</label>
                                    <Select ui={false} placeholder='Select your country' options={countryOptions} />
                                </Col>
                            </Row>
                        </Form.Field>
                        <Form.Field>
                            <label>Company Description (optional)</label>
                            <textarea rows="3" placeholder='Company Zen' />
                        </Form.Field>
                        <div className="text-right mt-5">
                            <Button type='submit' onClick={() => setOpen(false)}>Cancel</Button>
                            <Button className="ml-1" type='submit' primary onClick={() => setOpen(false)}>Save</Button>
                        </div>
                    </Form>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    )
}

const ProductsList = (props) => {
    const { products, isCompanyUser } = props;

    const [activeProduct, setActiveProduct] = useState(0)
    const expandProduct = (e, titleProps) => {
        setActiveProduct(titleProps.index)
    }

    return (
        products.Items.map(product => (
            <Table.Row key={product.product_id}>
                <Table.Cell>
                    <Accordion>
                        <Accordion.Title
                            active={activeProduct === product.product_id}
                            index={product.product_id}
                            onClick={expandProduct}
                        >
                            <Link className='w-100' to={`/product/${replaceSpaceInName(product.product_name)}/${product.product_id}`}>
                                <div className="d-flex align-items-center supplier-details">
                                    <div className="companyproduct-listname">
                                        <p className="suppliername">{product.product_name}</p>
                                        <div className="post-links">
                                            {/* {product.productFeedCount} posts */}
                                            <SvgIcon name="link-send" viewbox="0 0 21.206 17.719" />
                                        </div>
                                    </div>
                                </div>
                            </Link>

                            <div className='d-flex align-items-center right-action-product-list'>
                                {/* <Table.Cell textAlign="right" className="follow-btn-right"> */}
                                    <div className='mr-2'>{!isCompanyUser && <FollowButton id={product.product_id} name={product.product_name} type="product" />}</div>
                                    {/* <Button primary size="mini" className="follow-btn-new"><SvgIcon name="plus" viewbox="0 0 23 23" /> Follow</Button> */}
                                {/* </Table.Cell> */}
                                <Icon className="mt-n1" name='dropdown' />
                            </div>
                        </Accordion.Title>

                        <Accordion.Content active={activeProduct === product.product_id}>
                            <p>{product.description}</p>
                        </Accordion.Content>
                    </Accordion>
                </Table.Cell>
            </Table.Row>
        ))
    )
}

export default ProductsList;