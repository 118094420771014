import React, { Component } from 'react';
import { Button, Table, Accordion } from 'semantic-ui-react';
import { SvgIcon, GettingStarted } from '../common';
import './pricing.scss';

import Navbar from '../common/layout/navbar/Navbar';
import Footer from '../common/layout/footer/footer';

// Images
import planImage1 from '../../assets/images/plan/plan-1.svg';
import planImage2 from '../../assets/images/plan/plan-2.svg';
import planImage3 from '../../assets/images/plan/plan-3.svg';
import { connect } from 'react-redux';
import { TableBody } from 'semantic-ui-react';
 
class PricingView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 10
        };
    } 

    componentDidMount() {
        const { auth, history } = this.props;
        if (auth) {
            history.push('/networkFeed/global')
        }
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }
    render() {
        const pricingCardData = [
            {
                key: 1,
                image: planImage1,
                planname: "Free",
                price: "$0 / month",
                plandetailslists: [
                    {
                        name: <React.Fragment> Follow products and company <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Email alerts (new customer, supplier, product) <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Get a Company Page <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Post and Share, product & company updates <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Access to supplier and customer data <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Premium Listing on product page </React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Messaging other users </React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Analytics </React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Request for Quote </React.Fragment>,
                    }
                ],
                timeprroid : "1-year",
                buttonname: "CREATE AN FREE ACCOUNT",
                onButtonClick: ()=>this.props.history.push('/otp-login')
            },
            {     
                key: 2,
                image: planImage2,
                planname: "Premium",
                price: "$1250 / year (5 users incl.)",
                plandetailslists: [
                    {
                        name: <React.Fragment> Follow products and company <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Email alerts (new customer, supplier, product) <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Get a Company Page <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Post and Share, product & company updates <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Access to supplier and customer data <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Premium Listing on product page <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Messaging other users <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Analytics </React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Request for Quote </React.Fragment>,
                    }
                ],
                timeprroid : "1-year term",
                buttonname: "CREATE ACCOUNT",
                onButtonClick: ()=>this.props.history.push('/otp-login')
            },
            {
                key: 3,
                image: planImage3,
                planname: "Platinum",
                price: "$3000 / year (5 users incl.)",
                plandetailslists: [
                    {
                        name: <React.Fragment> Follow products and company <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Email alerts (new customer, supplier, product) <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Get a Company Page <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Post and Share, product & company updates <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Access to supplier and customer data <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Premium Listing on product page <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Messaging other users <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Analytics  <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    },
                    {
                        name: <React.Fragment> Request for Quote  <SvgIcon name="check" viewbox="0 0 11.385 8.685" /></React.Fragment>,
                    }
                ],
                timeprroid : "1-year term",
                buttonname: "CONTACT US TO UPGRADE",
                onButtonClick: () =>  window.location.href = "mailto:ankit.singhal@mappes.io"
            }
        ]

        const benefitPlanData = [
            {
                key: 1,
                name: "Follow products and company",
                freedetails: <SvgIcon name="check" viewbox="0 0 11.385 8.685" />,
                smallteamdetails: <SvgIcon name="check" viewbox="0 0 11.385 8.685" />,
                interprisedetails: <SvgIcon name="check" viewbox="0 0 11.385 8.685" />,
            },
            {
                key: 2,
                name: "Email alerts (new customer, supplier, product)",
                freedetails: <SvgIcon name="check" viewbox="0 0 11.385 8.685" />,
                smallteamdetails: <SvgIcon name="check" viewbox="0 0 11.385 8.685" />,
                interprisedetails: <SvgIcon name="check" viewbox="0 0 11.385 8.685" />,
            },
            {
                key: 3,
                name: "Get a Company Page",
                freedetails: <SvgIcon name="check" viewbox="0 0 11.385 8.685" />,
                smallteamdetails: <SvgIcon name="check" viewbox="0 0 11.385 8.685" />,
                interprisedetails: <SvgIcon name="check" viewbox="0 0 11.385 8.685" />,
            },
            {
                key: 4,
                name: "Post and Share, product & company updates",
                freedetails: <SvgIcon name="check" viewbox="0 0 11.385 8.685" />,
                smallteamdetails: <SvgIcon name="check" viewbox="0 0 11.385 8.685" />,
                interprisedetails: <SvgIcon name="check" viewbox="0 0 11.385 8.685" />,
            },
            {
                key: 5,
                name: "Access to supplier and customer data",
                freedetails: <small>Limited</small>,
                smallteamdetails: <SvgIcon name="check" viewbox="0 0 11.385 8.685" />,
                interprisedetails: <SvgIcon name="check" viewbox="0 0 11.385 8.685" />,
            },
            {
                key: 6,
                name: "Premium Listing on product page",
                freedetails: <SvgIcon name="close" className="noinplan" viewbox="0 0 23.126 23.126" />,
                smallteamdetails: <SvgIcon name="check" viewbox="0 0 11.385 8.685" />,
                interprisedetails: <SvgIcon name="check" viewbox="0 0 11.385 8.685" />,
            },
            {
                key: 7,
                name: "Messaging other users",
                freedetails: <SvgIcon name="close" className="noinplan" viewbox="0 0 23.126 23.126" />,
                smallteamdetails: <SvgIcon name="check" viewbox="0 0 11.385 8.685" />,
                interprisedetails: <SvgIcon name="check" viewbox="0 0 11.385 8.685" />,
            },
            {
                key: 8,
                name: "Analytics",
                freedetails: <SvgIcon name="close" className="noinplan" viewbox="0 0 23.126 23.126" />,
                smallteamdetails: <SvgIcon name="close" className="noinplan" viewbox="0 0 23.126 23.126" />,
                interprisedetails: <SvgIcon name="check" viewbox="0 0 11.385 8.685" />,
            },
            {
                key: 9,
                name: "Request for Quote",
                freedetails: <SvgIcon name="close" className="noinplan" viewbox="0 0 23.126 23.126" />,
                smallteamdetails: <SvgIcon name="close" className="noinplan" viewbox="0 0 23.126 23.126" />,
                interprisedetails: <SvgIcon name="check" viewbox="0 0 11.385 8.685" />,
            }
        ]

        const { activeIndex } = this.state
        return (
                <Navbar {...this.props}>
                    <div className="pricing-main">
                        <div className="common-section aboutproduct-section">
                            <div className="container common-heading text-left">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h2 className="fw-bold">Plans that scale as you grow</h2>
                                        <p className="pricing-text">Learn more about the different tiers</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="pricing-row">
                                            {pricingCardData.map(pringdata => (
                                                <div className="pricing-col">
                                                    <div className="pricing-image">
                                                        <img src={pringdata.image} alt={pringdata.image} />
                                                    </div>
                                                    <h1>{pringdata.planname}</h1>
                                                    <p>{pringdata.price}</p>
                                                    <ul>
                                                        {pringdata.plandetailslists.map(listsItems => (
                                                            <li>
                                                                {listsItems.name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <div className="time-period">
                                                        {pringdata.timeprroid}
                                                    </div>
                                                    <div className="action">
                                                        <Button className="custom-btn" onClick={pringdata.onButtonClick}>{pringdata.buttonname}</Button>
                                                    </div>
                                                </div>
                                            ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="common-section aboutproduct-section py-80">
                            <div className="container common-heading text-left pb-4">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h2 className="fw-bold">Benefits of a paid plan</h2>
                                        <p className="pricing-text">Learn more about the functions and capabilities</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12 web-benefits-plan">
                                        <Table singleLine className="benefits-plan">
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Features</Table.HeaderCell>
                                                    <Table.HeaderCell><small>Basic</small> Free</Table.HeaderCell>
                                                    <Table.HeaderCell><small>Premium</small> ($1250/year/5 users)</Table.HeaderCell>
                                                    <Table.HeaderCell><small>Platinum</small> ($3000/year/5 users)</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {benefitPlanData.map(benefitplan => (
                                                    <Table.Row>
                                                        <Table.HeaderCell>{benefitplan.name}</Table.HeaderCell>
                                                        <Table.Cell>{benefitplan.freedetails}</Table.Cell>
                                                        <Table.Cell>{benefitplan.smallteamdetails}</Table.Cell>
                                                        <Table.Cell>{benefitplan.interprisedetails}</Table.Cell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Table>
                                    </div>
                                    <div className="col-sm-12 mobile-benefits-plan">
                                        {benefitPlanData.map(benefitplan => (
                                            <div>
                                                <h2 className="planname-mobile">{benefitplan.name}</h2>
                                                <Table singleLine className="benefits-plan mobile-view">
                                                    <TableBody>
                                                        <Table.Row>
                                                            <Table.HeaderCell>Free</Table.HeaderCell>
                                                            <Table.Cell>{benefitplan.freedetails}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.HeaderCell>Small Teams</Table.HeaderCell>
                                                            <Table.Cell>{benefitplan.smallteamdetails}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.HeaderCell>Enterprise</Table.HeaderCell>
                                                            <Table.Cell>{benefitplan.interprisedetails}</Table.Cell>
                                                        </Table.Row>
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="common-section aboutproduct-section py-50 pb-0">
                            <div className="container common-heading text-left pb-4">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h2 className="fw-bold">FAQ</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Accordion className="faq-list">
                                            <Accordion.Title
                                                active={activeIndex === 0}
                                                index={0}
                                                onClick={this.handleClick}
                                            >
                                                <SvgIcon className="dropdown" name="menu-arrow" viewbox="0 0 23.619 13.503" />
                                            What Is Mappes?
                                        </Accordion.Title>
                                            <Accordion.Content active={activeIndex === 0}>
                                                <p>
                                                    Mappes.io is the social networking and online platform for industrial products and consumer goods. It’s a community-based platform that buyers and suppliers use to gain knowledge about their products - Discover Suppliers, Customers, Raw Materials and end-applications. User can uncover latest innovations and industry news related to products of their interest.
                                                </p>
                                            </Accordion.Content>

                                            <Accordion.Title
                                                active={activeIndex === 1}
                                                index={1}
                                                onClick={this.handleClick}
                                            >
                                                <SvgIcon name="menu-arrow" viewbox="0 0 23.619 13.503" />
                                            How is it different from LinkedIn and Facebook?
                                        </Accordion.Title>
                                            <Accordion.Content active={activeIndex === 1}>
                                                <p>
                                                    Facebook connects people by mapping a relationship between People
                                                    LinkedIn connects people by mapping a relationship between professionals
                                                    Mappes.io connects people by mapping a relationship between products

                                                    By connecting a raw material to its applications, we are effectively connecting a supplier to its buyer, so, while LinkedIn has been able to create hiring solutions, Mappes.io can create sales and marketing solutions.
                                            </p>
                                            </Accordion.Content>

                                            <Accordion.Title
                                                active={activeIndex === 2}
                                                index={2}
                                                onClick={this.handleClick}
                                            >
                                                <SvgIcon name="menu-arrow" viewbox="0 0 23.619 13.503" />
                                            How do I build my network on Mappes?
                                        </Accordion.Title>
                                            <Accordion.Content active={activeIndex === 2}>
                                                <p>
                                                    Unlike Linkedin or facebook, you don’t add other people into your network but map your products to its raw materials and applications. Once you do that Mappes.io automatically builds your network for you by bringing your suppliers and customers closer to you. With each added application, you will get access to its potential customer base and with each added raw material or equipment you will get access to its supplier base.
                                            </p>
                                            </Accordion.Content>

                                            <Accordion.Title
                                                active={activeIndex === 4}
                                                index={4}
                                                onClick={this.handleClick}
                                            >
                                                <SvgIcon name="menu-arrow" viewbox="0 0 23.619 13.503" />
                                            How do I make the most out of Mappes?
                                        </Accordion.Title>
                                            <Accordion.Content active={activeIndex === 4}>
                                                <p>
                                                    Mappes.io delivers Product related solutions.

                                                    - Lead Generation: Find new customers worldwide.
                                                    - Supplier Discovery: Helps find new suppliers of raw material and machinery.
                                                    - Product Discovery: Helps find new products in your field of work.
                                                    - Curates product related articles, videos from the member community.
                                                    - Curates top news stories, industry updates and blog posts
                                                    - Our web platform provides product centric insights that you won’t find anywhere else. That includes thousands of searchable products, latest news and much more.
                                                    
                                            </p>
                                            </Accordion.Content>

                                            <Accordion.Title
                                                active={activeIndex === 5}
                                                index={5}
                                                onClick={this.handleClick}
                                            >
                                                <SvgIcon name="menu-arrow" viewbox="0 0 23.619 13.503" />
                                            How many people use the platform?
                                        </Accordion.Title>
                                            <Accordion.Content active={activeIndex === 5}>
                                                <p>
                                                    Mappes.io is powered by an active community of business professionals from our targeted industries. We quickly plan to expand to other industries.
                                                </p>
                                            </Accordion.Content>

                                            <Accordion.Title
                                                active={activeIndex === 6}
                                                index={6}
                                                onClick={this.handleClick}
                                            >
                                                <SvgIcon name="menu-arrow" viewbox="0 0 23.619 13.503" />
                                            How does the Mappes Community contribute information?
                                        </Accordion.Title>
                                            <Accordion.Content active={activeIndex === 6}>
                                                <p>
                                                    Most business professionals hold valuable knowledge and important insights about a handful of products —their manufacturing process, suppliers, customers, raw materials, end-application and other product related insights. The members can add articles, presentations, case studies related to the products they manufacture. They can also map the products to its applications and help build the product network.

                                                    Members of the Mappes.io community contributes unique product insights, such as new process knowhow, new raw material or end-use. This feet-on-the-street knowledge provides other Mappes.io members with answers to questions such as “How to make optical fiber” or “Who are the suppliers and/or customers of optical fiber”. That’s the power of Mappes.io!
                                                </p>
                                            </Accordion.Content>

                                            <Accordion.Title
                                                active={activeIndex === 7}
                                                index={7}
                                                onClick={this.handleClick}
                                            >
                                                <SvgIcon name="menu-arrow" viewbox="0 0 23.619 13.503" />
                                            What types of companies use this community?
                                        </Accordion.Title>
                                            <Accordion.Content active={activeIndex === 7}>
                                                <p>
                                                    Mappes.io helps manufacturing companies from SMEs all the way to large, global enterprises.
                                                </p>
                                            </Accordion.Content>

                                            <Accordion.Title
                                                active={activeIndex === 8}
                                                index={8}
                                                onClick={this.handleClick}
                                            >
                                                <SvgIcon name="menu-arrow" viewbox="0 0 23.619 13.503" />
                                            How accurate is Mappes.io’s information?
                                        </Accordion.Title>
                                            <Accordion.Content active={activeIndex === 8}>
                                                <p>
                                                    Mappes.io has the most accurate product information on the planet. Mappes.io is updated in real-time from our community of business professionals, through industry experts and through our proprietary algorithms. Our accuracy improves with each new member and every additional contribution.

                                                    Mappes.io solves the core problem that exists in product intelligence: the lag between the creation of product and the timeliness in which it needs to the end user. 
                                            </p>
                                            </Accordion.Content>

                                            <Accordion.Title
                                                active={activeIndex === 9}
                                                index={9}
                                                onClick={this.handleClick}
                                            >
                                                <SvgIcon name="menu-arrow" viewbox="0 0 23.619 13.503" />
                                            How do I get started?
                                        </Accordion.Title>
                                            <Accordion.Content active={activeIndex === 9}>
                                                <p>
                                                    You can sign up for Mappes.io right away on www.mappes.io with your Business Email. Once you do so, you’ll immediately understand the power that Mappes.io has to offer and the value we deliver.
                                            </p>
                                            </Accordion.Content>

                                            <Accordion.Title
                                                active={activeIndex === 10}
                                                index={10}
                                                onClick={this.handleClick}
                                            >
                                                <SvgIcon name="menu-arrow" viewbox="0 0 23.619 13.503" />
                                            Who uses Mappes?
                                        </Accordion.Title>
                                            <Accordion.Content active={activeIndex === 10}>
                                                <p>
                                                    Like LinkedIn, Mappes.io is used by business professionals across all job functions and industries. Anyone who wants to gain knowledge related his products, find new customers and suppliers, discover new applications of his products, or find new processes to better its existing manufacturing process, can benefit from Mappes.io.
                                            </p>
                                                <p>
                                                    Here are just a few examples of Mappes.io in action:
                                            </p>
                                                <ul>
                                                    <li>
                                                        <b>CEOs</b> follow all products in their Product Relationship Graph including customers, prospects, competitors, partners, vendors, and suppliers to have a 360 degree view of their business environment.
                                                </li>
                                                    <li>
                                                        <b>CTOs</b> follow all products in their Product Relationship Graph to discover new technologies, new applications for their product.
                                                </li>
                                                    <li>
                                                        <b>Sales people</b> use Mappes.io to identify trigger events, discover prospective companies, and ultimately close more deals.
                                                </li>
                                                    <li>
                                                        <b>Marketers</b> help their brand gain visibility with Mappes.io.
                                                </li>
                                                    <li>
                                                        <b>Procurement people</b> use Mappes.io to find new products and new suppliers.
                                                </li>
                                                    <li>
                                                        <b>Recruiters</b> pounce on top talent by discovering professionals with knowledge in a particular product.
                                                </li>
                                                    <li>
                                                        <b>Investors</b> drive better returns by tapping into market insights by understanding the potential of products.
                                                </li>
                                                    <li>
                                                        <b>Business Development Professionals</b> identify new partnership opportunities by staying abreast of the latest news, and alerts in their industry.
                                                </li>
                                                    <li>
                                                        <b>Product Managers</b> share and gain knowledge about the products.
                                                </li>
                                                    <li>
                                                        <b>Job Seekers</b> identify companies related to their products, and research potential opportunities.
                                                </li>
                                                </ul>
                                            </Accordion.Content>

                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <GettingStarted />

                        <div className="bottom-shape">
                            <SvgIcon name="bottom-shape" viewbox="0 0 1417 422" />
                        </div>
                    </div>
                    <Footer />
                </Navbar> 
        );
    }
}

const mapStateToProps = ({ auth }) => {
    return { auth };
}

export default connect(mapStateToProps)(PricingView);