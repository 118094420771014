import axios from 'axios';
import { BASE_URL } from '../../../config/config';

export const sendInvite = async (email, entityType, entityId) => {
    var inviteType, data;
    if (entityType === "company") {
        inviteType = "followCompany";
        data = { email, company_id: entityId };
    }
    else {
        inviteType = "followProduct";
        data = { email, product_id: entityId };
    }

    const res = await axios.post(`${BASE_URL}/api/v1/emails/${inviteType}`, { ...data }, { withCredentials: true });
    return res.status;
}