import React from 'react';
const SignUpComplete = () => {
    return (
        <div className="signupright-inner messge-thanku">
            <div className="mb-4 title">
                <h3>Thank you for your submission</h3>

                <p className="mt-4">You successfully created account. Please check your email to contiune with completion of your profile.</p>

                <p className="mt-4">You may close this window at this time</p>

                <div className="row mt-5">
                    <div className="col-sm-9">
                        {/* <button className="ui w-100 primary button btn-lg">
                            CLOSE WINDOW
                        </button> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SignUpComplete;