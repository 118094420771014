
import React from 'react';
import Tribute from "tributejs";
import { BASE_URL } from '../../../config/config';
import { replaceSpaceInName } from '../../../common/helperMethods';

// Require Editor JS files.
// additioanl packages - start
//import 'froala-editor/js/plugins/colors.min.js';
//import 'froala-editor/js/plugins/table.min.js';
//import 'froala-editor/js/plugins/font_size.min.js';
//import 'froala-editor/js/plugins/font_family.min.js';
//import 'froala-editor/js/plugins/fullscreen.min.js';
// additional packages - end 

/* import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';

import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/url.min.js';

import 'tributejs/dist/tribute.css'
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css'; */
import './NewRichEditor.scss';
// Require Editor CSS files.

// import FroalaEditor from 'froala-editor';

var tagged_products = [];
var tagged_company = [];
/* var colorlist = [
   '#000000', '#FFFFFF', '#DCDCDC', '#D3D3D3', 	'#2F4F4F', '#FFF0F5', '#FFE4E1', '#800000',
   '#F4A460', '#0000FF', '#0000CD', '#00008B', '#000080', '#006400', '#008000', '#00FF00', '#FF4500',
    '#FF6347', '#FF0000', '#4B0082', '#6A5ACD', '#800080', '#FF00FF', '#FFC0CB', '#DB7093'
] */

var tributeMultipleTriggers = new Tribute({
   collection: [{
      trigger: "@",
      selectTemplate: function (item) {
         if (item !== undefined) {

            if (this.range.isContentEditable(this.current.element)) {
               tagged_company.push(item.original);
               return (
                  '<a href="' + item.original.url + '" title="' + item.original.value + '" rel="noopener noreferrer">@' + item.original.value + "</a>"

               );
            }
            return "@" + item.original.name;
         } else {
            return '';
         }

      },


      values: function (text, cb) {
         companySearch(text, users => cb(users));
      },
      lookup: 'value',
      fillAttr: 'value'
   },
   {

      trigger: "#",
      loadingItemTemplate: '<div style="padding: 16px">Loading</div>',

      selectTemplate: function (item) {
         if (item !== undefined) {
            if (this.range.isContentEditable(this.current.element)) {
               tagged_products.push(item.original);
               return (
                  '<a href="' + item.original.url + '" rel="noopener noreferrer">#' + item.original.value.replace() + "</a>"

               );
            }

            return "#" + item.original.value;
         } else {
            return '';
         }


      },
      values: function (text, cb) {
         productSearch(text, users => cb(users));
      },
      lookup: "value",

      fillAttr: "value"
   },
   ],
   noMatchTemplate: function (tribute) {

      return '<li><span style:"visibility: hidden;"></span></li>';
   },
   allowSpaces: true,

});

function companySearch(text, cb) {
   const hostname = window.location.protocol + '//' + window.location.host;
   if (text !== '' && text.length >= 3) {
      var URL = BASE_URL + "/api/v1/company/search";
      let xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
         if (xhr.readyState === 4) {
            if (xhr.status === 200) {
               var data = JSON.parse(xhr.responseText);
               var filtered_json = [];
               for (let item of data) {
                  filtered_json.push({
                     "key": item._source.id,
                     "value": item._source.name,
                     //  "url": hostname + "/company/" + item._source.id
                     "url": hostname + `/company/${replaceSpaceInName(item._source.name)}/${item._source.id}`
                  });
               }

               cb(filtered_json);
            } else if (xhr.status === 403) {

               cb([]);
            }
         }
      };
      xhr.open("GET", URL + "/" + text, true);
      xhr.send();
   } else {
      var data = [];
      cb(data);
   }
}

function productSearch(text, cb) {
   // console.log("text ", text, " length ", text.length);
   const hostname = window.location.protocol + '//' + window.location.host;
   if (text !== '' && text.length >= 3) {
      var URL = BASE_URL + "/api/v1/product/search";
      let xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
         if (xhr.readyState === 4) {
            if (xhr.status === 200) {
               var data = JSON.parse(xhr.responseText);
               var filtered_json = [];
               for (let item of data) {
                  filtered_json.push({
                     "key": item._source.id,
                     "value": item._source.name,
                     // "url": hostname + "/product/" + item._source.id
                     url: hostname + `/product/${replaceSpaceInName(item._source.name)}/${item._source.id}`
                  });
               }

               cb(filtered_json);
            } else if (xhr.status === 403) {
               cb([]);
            }
         }
      };
      xhr.open("GET", URL + "/" + text, true);
      xhr.send();
   } else {
      var data = [];
      cb(data);
   }
}

class CustomRickEditor extends React.Component {
   constructor(props) {
      super(props);
      this.state = { vr: null, tx: null, editor_obj: null };
      this.mention = React.createRef();
      this.textAreaInput = React.createRef();
      this.htmldiv = React.createRef();
   }


   instantHandler = () => {
      this.props.gettagged_products(tagged_products);
      this.props.gettagged_company(tagged_company);
      var res = this.mention.current.innerHTML;

      this.setState({ vr: res });
      this.props.onEditorChange(res);
   }

   handler = () => {
      var res = this.mention.current.innerHTML;
      this.mention.current.innerHTML = "";
      this.setState({ vr: res });
      this.props.onEditorChange(res);
   }

   edit = () => {
      if (this.props.value != '' && this.props.value != null) {
         this.setState({ vr: this.props.value });
      }
      this.mention.current.innerHTML = this.state.vr
   }

   textAreaSubmit = (e) => {
      this.setState({ tx: this.textAreaInput.current.value }, () => { this.htmldiv.current.innerHTML = this.state.tx });
   }


   frola_editor_init() {
      /*
      const that = this;
      const { auth } = this.props
      const firstname = (auth != undefined && auth.firstName) ? auth.firstName : '';
       const frola = new FroalaEditor('#froala-editor', {
         // placeholderText: `What’s on your mind ${firstname}`,
         placeholderText: `Write article here. Use @ to include “ People or Company”, # to include product or technology`,
         fontFamilySelection: true,
         listAdvancedTypes: true,
         pluginsEnabled: [
            'align',
           // 'colors',
          //  'fontSize',
         //   'fontFamily',
           // 'fullscreen',
           // 'table',
            'quote',
            'paragraphFormat',
            'paragraphStyle',
            'url',
            'lists',
            'link',
            'image',
            'video',
            
         ],
         toolbarButtonsXS: ['undo', 'redo', '-', 'bold', 'italic', 'underline'],
         toolbarButtons: [
            'paragraphFormat', 'paragraphStyle', '|',
            'bold', 'italic', 'underline', 
            // 'fontSize', 'fontFamily',  
            'undo', 'redo', '|',
            'formatOL', 'formatUL', '|',
            // 'formatOLSimple', 'formatULSimple', '|',
            'alignLeft', 'alignRight', 'alignCenter', 'alignJustify', '|',
            // 'textColor', 'backgroundColor', '|',
            'outdent', 'indent', '|',
            // 'insertTable',
            'insertLink', '|', 
            // 'insertImage', 'insertVideo', '|',
            'quote', 'clearFormatting',
            // 'fullscreen', '|'
         ],
         colorsStep: 6,
         //colorsBackground: colorlist,
         // colorsText: colorlist,
         pastePlain: true,
         attribution: false,
         events: {
            initialized: function () {
               var editor = this;
               that.setState({ editor_obj: editor });
               tributeMultipleTriggers.attach(editor.el)
               editor.events.on('keydown', function (e) {
                  if (e.which == FroalaEditor.KEYCODE.ENTER && tributeMultipleTriggers.isActive) {
                     return false;
                  }
               }, true);
            },  input: function () {
               that.articleTextUpdate(this, that)

            }, contentChanged: function () {
               that.articleTextUpdate(this, that)
            }
         }
      }) 
      
      FroalaEditor.DefineIcon('paragraphFormat', { title: 'Heading', template: 'font_awesome'  } )
      FroalaEditor.DefineIconTemplate('font_awesome', '<div class="paragraph-format">T</div>');
      */
   }

   articleTextUpdate = (obj, obj2) => {
      setTimeout(() => {
         var editor = obj;
         var value = editor.html.get();

         obj2.setState({ vr: value });
         obj2.props.onEditorChange(value);

         obj2.props.gettagged_products(tagged_products);
         obj2.props.gettagged_company(tagged_company);
      }, 1500);
   }

   componentDidMount() {
      //const { toolbar } = this.props
      /* if (toolbar != undefined && toolbar != null && toolbar == true) { // article editor 
         this.frola_editor_init()
      } else { */
      tributeMultipleTriggers.attach(this.mention.current);

      this.mention.current.addEventListener("tribute-replaced", function (e) {
         console.log( "Original event that triggered text replacement:", e.detail.event );
         if (e.detail.event.keyCode == 13) { // enter
            that.instantHandler();
         }
         
         if (e.detail.event.type == 'mousedown') { // mouse selection for mention
            that.instantHandler();
         }
      });

      let that = this;
      this.mention.current.addEventListener('DOMCharacterDataModified', function (e) {
         that.instantHandler();
      }, false);

      this.mention.current.addEventListener('input', function (e) {
         that.instantHandler();
      }, false);

      this.mention.current.addEventListener("paste", (e) => {
         e.preventDefault()
         var text = (e.originalEvent || e).clipboardData.getData('text/plain');
         this.mention.current.innerHTML += text;
      }, false);

      /* } */

      if (this.props.value != undefined && this.props.value != '' && this.props.value != null) {
         this.setState({ vr: this.props.value });
         this.mention.current.innerHTML = this.props.value;
      }
      this.props.onRef(this);
   }

   componentWillReceiveProps(newProps) {
      if (newProps.value != undefined && newProps.value != '' && newProps.value != null && this.state.vr == null) {
         if (newProps.toolbar != undefined && newProps.toolbar != null && newProps.toolbar == true) { // article editor 
            setTimeout(() => {
               this.setState({ vr: newProps.value });
               this.state.editor_obj.html.set(newProps.value)
            }, 200);
         } else {
            this.setState({ vr: newProps.value });
            this.mention.current.innerHTML = newProps.value;
         }
      }

   }

   componentWillUnmount() {
      this.props.onRef(undefined);
   }

   clearInput = () => {

      tagged_company = [];
      tagged_products = [];
      this.setState({ vr: '' });
      this.mention.current.innerHTML = "";
   }

   render() {
      const { auth, toolbar } = this.props;
      const firstname = (auth != undefined && auth.firstName) ? auth.firstName : '';
      /* if (toolbar != undefined && toolbar != null && toolbar == true) { // article editor 
         return (
            <div className="wrapper" >
               <div id="froala-editor"  ></div>
            </div>
         );
      } else { */
      return (
         <div className="wrapper">
            <div id="froala-editor" className="mentionable" data-placeholder={"What’s on your mind " + firstname} ref={this.mention} onKeyDown={this.keyDn}  ></div>
         </div>
      );
      /* } */

   }
}

export default CustomRickEditor;
