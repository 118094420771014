import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import "./password-reset-successfully.scss";

class PasswordResetSuccessfully extends Component {
    render() {
        return (
            <div className="forgot-password">
                <div className="forgot-password-inner">
                    <h2>Your've successfully updated your password</h2>
                    <div className="forgotpassowrd-form mt-5">
                        <div className="row">
                            <div className="col-sm-12 mt-2 text-center">
                                <Link to="/">
                                    <Button basic className="ui primary button btn-lg custom-btn text-inherit fw-bold">
                                        Sign in into your account
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PasswordResetSuccessfully