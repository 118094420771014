import { useEffect, useState } from "react"

export const useOTPTimer = () => {
    const [seconds, setSeconds] = useState(60)

    useEffect(() => {
        const time = setInterval(function () {
            setSeconds((second) => {
                if (second > 0) {
                    return second - 1
                } else {
                    clearInterval(time)
                }
            })
        }, 1000)
        return () => {
            clearInterval(time)
        }
    }, [])

    return { seconds, setSeconds }
}