import React from 'react';
import CustomersList from './CustomersList';
import NoCustomers from './NoCustomers';
// import SuppliersList from './SuppliersList';
// import AddSuppliers from './AddSuppliers'

class CustomersView extends React.Component {
    render() {
        return (
            <div className="supplierslist-wrapper border-1-radius">
                {this.props.customers!= undefined && this.props.customers.length
                    ? <CustomersList customers={this.props.customers} history={this.props.history} />
                    : <NoCustomers handleActiveIndexChange={this.props.handleActiveIndexChange}/>
                }
            </div>
        )
    }
}

export default CustomersView;