import React, { Component, useState } from 'react';
import { Form, Button, Loader, Modal } from 'semantic-ui-react';
import { apiStatus } from '../../common/constants';

const messages = {
    beforeDelete: "Do you want to delete your profile? All your data will be lost when your account is deactivated.",
    afterDelete: "Account deactivation request has been submitted, Your account will deactivated in 48 hour"
}
const DeleteProfileConfirmationModal = ({ deactivateAccount }) => {
    const [open, setOpen] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [message, setMessage] = useState(messages.beforeDelete);
    const [status, setApiStatus] = useState(null);

    const handleDelete = () => {
        const callback = () => {
            setMessage(messages.afterDelete)
            setShowLoader(false);
            setApiStatus(apiStatus.COMPLETED);
        }
        setShowLoader(true);
        deactivateAccount(callback);
    }

    const test = () => {
        setMessage(messages.beforeDelete);
        setShowLoader(false);
        setApiStatus(apiStatus.UNDEFINED);
        setOpen(false);
    }

    const DeleteButton = <>If you want to deactivate your Mappes account <Button onClick={() => setOpen(true)}>click here</Button></>

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size='mini'
            className='deletemyaccount-modal'
            trigger={DeleteButton}
        >

            <Modal.Header>Delete my account</Modal.Header>
            <Modal.Content>
                <div>
                    <p>{message}</p>
                </div>
            </Modal.Content>
            <Modal.Actions>
                {
                    status === apiStatus.COMPLETED ?
                        <Button primary onClick={() => test()}>
                            Ok
                        </Button>
                        :
                        <>
                            <Button onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                            <Button color='red' onClick={handleDelete} loading={showLoader}>
                                Delete
                            </Button>
                        </>
                }
            </Modal.Actions>
        </Modal>
    )
}
export default DeleteProfileConfirmationModal;