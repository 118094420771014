import React, { Component } from 'react';
import axios from 'axios';
import { Button } from 'semantic-ui-react';

import "./SetNewPassword.scss";

import Navbar from '../../common/layout/navbar/Navbar';
import { BASE_URL } from '../../../config/config';
import PasswordResetSuccessfully from '../password-reset-successfully/password-reset-successfully';

class SetNewPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: []
        }
    }

    inputChangeHandler = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        // this.updateErrors(name, value);
    }

    updateErrors = (name, value) => {
        const { password, confirmPassword } = this.state.formData;

        if (password !== confirmPassword) {
            this.setState({ validationErrorMessage: "password and confirm password are different" })
            return false;
        }
        if (password.length < 8) {
            this.setState({ validationErrorMessage: "password must be atleast 8 characters long" });
            return false;
        }
        let errors = [...this.state.errors];

        if (!value) {
            errors.push(name);
        }
        else {
            const index = errors.indexOf(name);
            if (index > -1) {
                errors.splice(index, 1);
            }
        }
        this.setState({ errors });
    }

    updatePassword = () => {
        const { password, confirmPassword } = this.state;
        if (!password || !confirmPassword || password.length < 8 || confirmPassword.length < 8) return;
        this.setState({ loading: true });

        axios.post(`${BASE_URL}/api/v2/me/updatePassword`, { password }, { withCredentials: true })
            .then(() => {
                this.setState({ loading: false, success: true });
            })
            .catch(() => {
                this.setState({ loading: false, success: false });
            })
    }
    render() {
        const { success, errors, password, confirmPassword } = this.state;

        return (
            <Navbar {...this.props}>

               

                        <div className="forgot-password">
                    {
                        success ?
                            <PasswordResetSuccessfully />

                            :
                            <div className="forgot-password-inner">
                                <h2>Set up your new password</h2>
                                <div className="forgotpassowrd-form mt-5">
                                    <div className="row">
                                        <div className="col-sm-12 mb-4">
                                            <label>Password*</label>
                                            <div className="ui input large w-100 form-input mb-1">
                                                <input type="password" placeholder="Password" name="password" onChange={this.inputChangeHandler} className={errors.includes("password") && 'error'} />
                                            </div>
                                            {password && password.length < 8 &&
                                                <div className="d-flex align-items-center justify-content-between password-hint">
                                                    <small>Must be at least 8 characters</small>
                                                    {/* <div className="d-flex align-items-center"><small>1 / 6</small> <i className="info circle icon ml-1"></i></div> */}
                                                </div>
                                            }
                                        </div>
                                        <div className="col-sm-12 mb-4">
                                            <label>Confirm Password*</label>
                                            <div className="ui input large w-100 form-input mb-1">
                                                <input type="password" placeholder="Password" name="confirmPassword" onChange={this.inputChangeHandler} className={errors.includes("confirmPassword") && 'error'} />
                                            </div>
                                            {confirmPassword && confirmPassword !== password &&
                                                <div>
                                                    <small>Both passwords must match</small>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-sm-12 mt-2">
                                            <Button className="ui primary button w-100 btn-lg custom-btn text-inherit fw-bold" onClick={this.updatePassword} loading={this.state.loading}>
                                                Update password
                                    </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
    }
                        </div>
                
            </Navbar>

        );
    }
}

export default SetNewPassword