import React, { Component, useEffect, useState } from 'react';
import { SvgIcon } from '../../common';
import OtpInput from 'react-otp-input';
import { Button, Message } from 'semantic-ui-react';
import './index.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { registrationActions } from '../../../actions';
import { bindActionCreators } from 'redux';
import { BASE_URL } from '../../../config/config';

const VerifyOtp = (props) => {

    const [otp, setOtp] = useState('');
    const [otpError, setShowOtpError] = useState(false);
    const userId = localStorage.getItem("userId");
    const emailId = localStorage.getItem("email");
    const { otpVerificationStatus } = props.registration;

    // useEffect(() => {
    // if (otpVerificationStatus) {
    //     if (otpVerificationStatus.data.success) {
    //         // props.history.push('/');
    //     }
    //     else {
    //         setShowOtpError(true);
    //     }
    // }
    // }, [otpVerificationStatus]);

    // const handleSubmit = () => {
    //     if (otp.length === 6) {
    //         props.verifyOtp(otp, emailId, userId);
    //     }
    // }

    return (
        <div className="signup-wrapper">
            <div className="signup-left">
                <div className="signupleft-inner">
                    <SvgIcon name="logo" viewbox="0 0 907 93" />
                    <h2>Map Your Product Community And Stay Connected. Anytime, Anywhere.</h2>
                </div>
            </div>
            <div className="signup-right">
                <form className='w-100' action={`${BASE_URL}/registration/verify`}>
                    <div className="signupright-inner otpverify-inner">
                        <Link to='/otp-login' className='back-arrow'><SvgIcon name='arrow-left' viewbox="0 0 23 17" /></Link>
                        <div className="mb-4 title">
                            <h3>Verify OTP</h3>
                            <p>We have sent you a verification code to {props.email} to validate your email address</p>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="ui input large w-100 otp-input mb-3">
                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={6}
                                        separator={<span>-</span>}
                                    />
                                </div>
                            </div>
                        </div>
                        <input type="hidden" name="token" value={otp} />
                        <input type="hidden" name="email" value={emailId} />
                        <input type="hidden" name="redirect" value={`${window.location.origin}/networkfeed/new`} />
                        {
                            otpError &&
                            <Message warning>
                                {/* <Message.Header>{otpVerificationStatus.messages.message}</Message.Header> */}
                                <Message.Header>Invalid Otp</Message.Header>
                            </Message>
                        }
                        <div className="row mt-auto">
                            <div className="col-sm-12">
                                <p className="fs-12 bycliking-text">By continuing, you agree to Mappes Inc Terms of service, Privacy policy</p>
                                <Button type="submit" className="ui primary button w-100 btn-lg auth-btn" disabled={otp.length < 6}>
                                    Verify & continue
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators(registrationActions, dispatch) }
}
function mapStateToProps({ registration }) {
    return { registration };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOtp);