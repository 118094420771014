import React from 'react';
import { Button, Icon, Table, Select } from 'semantic-ui-react';
import PremiumTag from '../../../assets/images/premium-tag.svg';
import { Link } from 'react-router-dom';
import FollowButton from '../FollowButton';
import { commonActions, productActions } from '../../../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { replaceSpaceInName, sortList, sortListByStringKey } from '../../../common/helperMethods';
import LoginButton from '../../common/LoginButton';

const sortOption = [
    { key: 'name', value: 'company_name', text: 'name' },
    { key: 'country', value: 'country', text: 'country' },
    { key: 'No. of contacts', value: 'contacts', text: 'contacts' }
]

class SuppliersList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSupplierLoader: false,
            sortBy: "premium",
            visibleSuppliers: 5
        }
    }

    getSortedSuppliersList = () => {
        let sortedList = [];
        const { sortBy } = this.state;
        const { suppliers } = this.props;
        const suppliersList = suppliers && suppliers.results;

        if (suppliersList) {
            if (sortBy === "premium") {
                sortedList = suppliersList.sort((a, b) => (b.premium - a.premium) ||
                    (a.company_name && b.company_name ? a.company_name.localeCompare(b.company_name) : -1));
            }
            else if (sortBy === "country" || sortBy === "company_name") {
                sortedList = sortListByStringKey(suppliersList, sortBy);
            }
            else {
                sortedList = sortList(suppliersList, sortBy, "desc");
            }
        }
        return sortedList;
    }

    handleMoreClick() {
        const { auth, suppliers, showLoginModal } = this.props;
        const supplierCount = suppliers && suppliers.count || 0;

        if (!auth) {
            showLoginModal(true);
        }
        else {
            this.setState({ showSupplierLoader: true });
            setTimeout(() => {
                this.setState({
                    visibleSuppliers: supplierCount,
                    showSupplierLoader: false
                })
            }, 1000);
        }
    }

    setSortBy = (e, { value }) => {
        this.setState({ sortBy: value })
    }

    render() {
        const { auth, suppliers } = this.props;
        if (!suppliers) return null;

        const { visibleSuppliers } = this.state;
        const supplierList = suppliers && suppliers.results;
        const supplierCount = suppliers && suppliers.count || 0;
        const sortedSuppliersList = supplierList && this.getSortedSuppliersList().slice(0, visibleSuppliers);

        return (
            <React.Fragment>
                <div className="supplier-list">
                    <Table basic="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan='4'>
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex align-items-center">
                                            {/* <Checkbox className="mr-3" /> */}
                                            Sort By
                                        </div>
                                        <div className="filter-select">
                                            <Icon name="filter" />
                                            <Select defaultValue="country" options={sortOption} onChange={this.setSortBy} />
                                        </div>
                                    </div>
                                </Table.HeaderCell>
                                <Table.HeaderCell colSpan='1'>
                                </Table.HeaderCell>
                                {/* <Table.HeaderCell colSpan='2' className="text-right">
                                    <Button primary basic className="py-2 px-3">Request Quote</Button>
                                </Table.HeaderCell> */}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {sortedSuppliersList.map(({ company_name, company_id, contacts, country, images, premium, logo, contactsDetails }) => (
                                <>
                                    <Table.Row key={company_id}>
                                        {/* <Table.Cell className="pl-0">
                                            <Checkbox />
                                        </Table.Cell> */}
                                        <Table.Cell>
                                            <div className="d-flex align-items-center supplier-details">
                                                {logo ? <img className="logo-img" src={logo} alt="LOGO" />
                                                    : <div className="supplierdetails-circle">{company_name && company_name.substring(0, 1)}</div>}
                                                <div className="pl-3">
                                                    <Link to={`/company/${replaceSpaceInName(company_name)}/${company_id}`}>
                                                        <p className="suppliername">{company_name}</p>
                                                    </Link>
                                                    <div className="addresses">
                                                        {country}
                                                        {premium && <img style={{ width: 56, marginBottom: -3, marginLeft: 2 }} src={PremiumTag} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <ul className="contactlist">
                                                {/* {
                                                    images && images.map(image =>
                                                        <li><img src={image} /></li>
                                                    )
                                                } */}
                                                {
                                                   /* contactsDetails && contactsDetails.slice(0, 3 - images.length).map(contact =>
                                                        <li className='init-circles'>{contact.firstName.substring(0, 1)}</li>
                                                        
                                                    )*/
                                                    contactsDetails && contactsDetails.map(contact =>
                                                        contact.image ?   <li><img src={contact.image} /></li> : 
                                                        <li className='init-circles'>{contact.firstName.substring(0, 1)}</li>
                                                    )
                                                } 
                                            </ul>

                                        </Table.Cell>
                                        {contacts > 0 && <Table.Cell><div className="d-flex">{contacts}&nbsp; <span className="text-muted">contacts</span></div></Table.Cell>}
                                        {contacts == 0
                                            && <Table.Cell>
                                                <div className="d-flex">
                                                    &nbsp;
                                                </div>
                                            </Table.Cell>
                                        }
                                        <Table.Cell className="follow-btn-right supplierfollow-btn-right" textAlign="right">
                                            <FollowButton id={company_id} name={company_name} type="company" history={this.props.history} />
                                            {/* <Button primary size="mini" className="follow-btn-new"><SvgIcon name="plus" viewbox="0 0 23 23" /> Follow</Button> */}
                                        </Table.Cell>
                                    </Table.Row>
                                </>
                            ))}
                            {
                                supplierCount > visibleSuppliers && (

                                    /*  <Button
                                         className="color-light-blue cursor-pointer load_more"
                                         onClick={this.handleMoreClick.bind(this)}
                                         loading={this.state.showSupplierLoader}
                                     > Load More
                                         {this.props.auth ?
                                             this.props.auth.isActive ? "See All"
                                                 :
                                                 "Upgrade to see the complete list"
                                             : "Load More"} 
                                     </Button> */
                                    <Table.Row>
                                        <Table.Cell colSpan="5" className="text-center">
                                            {
                                                auth ?
                                                    <Button className="color-light-blue w-100 text-center mt-2 cursor-pointer load_more" onClick={this.handleMoreClick.bind(this)} loading={this.state.showSupplierLoader} >
                                                        See All
                                                    </Button>
                                                    :
                                                    <LoginButton />
                                            }
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
                </div>
            </React.Fragment >
        )
    }
}

function mapStateToProps({ singleProduct, auth, common }) {
    return { suppliers: singleProduct.suppliers, auth, common };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({ ...productActions, ...commonActions }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SuppliersList);