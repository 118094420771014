import React from 'react';
import { List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import User from './user';
import AvatarSmall from '../../common/AvatarSmall';

export default (notification, index) => (
    <List.Item key={index}>
        <AvatarSmall id={notification.actor_id} name={notification.actor_name} />
        <List.Content>
            <List.Description>
                <User id={notification.actor_id} /> liked <Link to={`/feed/post/${notification.post_id}`}>post</Link> that you commented on
            </List.Description>
        </List.Content>
    </List.Item>
);
