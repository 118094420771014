import axios from 'axios';
import { FETCH_USER, REGENERATE_OTP } from '../../config/types';
import { BASE_URL } from '../../config/config';

export const fetchUser = () => async dispatch => {
    try {
        fetch(`${BASE_URL}/api/v1/current_user`, { credentials: "include" }).then(async response => {
            let res = await response.json();
            if (response.status === 401) {
                dispatch({ type: REGENERATE_OTP, payload: { value: true, userId:  res.data.user_id} });
                dispatch({ type: FETCH_USER, payload: false });
            }
            else {
                const hostname = window && window.location && window.location.hostname;
                if (res && (hostname === 'mappes.io' || hostname === 'www.mappes.io')) {
                    window.dataLayer.push({ "userId": res.Item.id })
                }
                dispatch({ type: FETCH_USER, payload: res.Item });
            }
        })
            .catch(err => {
                dispatch({ type: FETCH_USER, payload: false });
            });
    } catch (err) {
        dispatch({ type: FETCH_USER, payload: false });
    }
};