//TODO: Refrefactor needed: all list views are similar, check if it can be refactored
import React, { useState } from 'react';
import NoApplications from './NoApplications';
import Applications from './Applications';
import AddApplications from './AddApplications'

function ApplicationsView({ productId, applications }) {
    const [showAddApplications, setShowAddApplications] = useState(false)
    return (
        <div className="supplierslist-wrapper border-1-radius">
            {applications!= undefined && applications.length > 0
                ? 
                <>
                    <AddApplications productId={productId} />
                    <Applications applications={applications} />
                </>
                : showAddApplications
                    ? <AddApplications productId={productId} />
                    : <NoApplications setShowAddApplications={setShowAddApplications} />
            }
        </div>
    )
}

export default ApplicationsView;