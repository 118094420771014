import {
    SINGLE_PRODUCT_INFO,
    SINGLE_PRODUCT_SUPPLIER,
    SINGLE_PRODUCT_ACTIVITY,
    SINGLE_PRODUCT_APPLICATION,
    SINGLE_PRODUCT_RAW_MATERIALS,
    POTENTIAL_CUSTOMERS,
    PRODUCT_FOLLOWERS,
    RESET_PRODUCT_DATA
} from '../../config/types'

const initialState = {
    suppliers: {
        results: [],
        count: 0,
        LastEvaluatedKey: {}
    },
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SINGLE_PRODUCT_INFO:
            return { ...state, info: action.payload };
        case SINGLE_PRODUCT_ACTIVITY:
            return { ...state, activity: action.payload };
        case SINGLE_PRODUCT_SUPPLIER:
            return { ...state, suppliers: action.payload }
        case SINGLE_PRODUCT_APPLICATION:
            return { ...state, application: action.payload };
        case SINGLE_PRODUCT_RAW_MATERIALS:
            return { ...state, raw: action.payload };
        case POTENTIAL_CUSTOMERS:
            return { ...state, customer: action.payload }
        case PRODUCT_FOLLOWERS:
            return { ...state, followers: action.payload }
        case RESET_PRODUCT_DATA:
            return {
                ...state, suppliers: {
                    results: [],
                    count: 0,
                    LastEvaluatedKey: {}
                }
            }

        default:
            return state;
    }

}