import React from 'react';
import { Button, Input, Label } from 'semantic-ui-react';
import { emailRegex } from '../../common/constants';
import { SvgIcon } from '../common';
import { replaceSpaceInName } from '../../common/helperMethods';

class SharePost extends React.Component {
    constructor(props) {
        super(props);
        if (props.type == 'post') {
            this.state = {
                emailIds: '',
                emailBody: 'Hey Team,\nthought you might be interested in this post.',
                subject: `${props.userName} shared a post with you`,
                disableShareButton: true,
                invalidEmailId: false,
                post_url: window.location.protocol + '//' + window.location.host + '/feed/post/' + this.props.postId,
                copy: 'Copy'
            }
        }

        if (props.type == 'article') {
            this.state = {
                emailIds: '',
                emailBody: 'Hey Team,\nthought you might be interested in this article.',
                subject: `${props.userName} shared an article with you`,
                disableShareButton: true,
                invalidEmailId: false,
                post_url: window.location.protocol + '//' + window.location.host + '/article/' + replaceSpaceInName(this.props.postTitle) + '/' + this.props.postId,
                copy: 'Copy'
            }
        }

        if (props.type == 'news' || props.type == 'NEWS') {
            this.state = {
                emailIds: '',
                emailBody: 'Hey Team,\nthought you might be interested in this news.',
                subject: `${props.userName} shared a news with you`,
                disableShareButton: true,
                invalidEmailId: false,
                post_url: window.location.protocol + '//' + window.location.host + '/feed/post/' + this.props.postId,
                copy: 'Copy'
            }
        }

        if (props.type == 'product') {
            this.state = {
                emailIds: '',
                emailBody: 'Hey Team,\nthought you might be interested in this product.',
                subject: `${props.userName} shared a product with you`,
                disableShareButton: true,
                invalidEmailId: false,
                post_url: window.location.protocol + '//' + window.location.host + '/product/' + replaceSpaceInName(props.postTitle) + '/' + this.props.postId,
                copy: 'Copy'
            }
        }

        if (props.type == 'company') {
            this.state = {
                emailIds: '',
                emailBody: 'Hey Team,\nthought you might be interested in this company.',
                subject: `${props.userName} shared a company with you`,
                disableShareButton: true,
                invalidEmailId: false,
                post_url: window.location.protocol + '//' + window.location.host + '/company/' + replaceSpaceInName(props.postTitle) + '/' + this.props.postId,
                copy: 'Copy'
            }
        }

        if (props.type == 'event') {
            this.state = {
                emailIds: '',
                emailBody: 'Hey Team,\nthought you might be interested in this event.',
                subject: `${props.userName} shared an event with you`,
                disableShareButton: true,
                invalidEmailId: false,
                post_url: window.location.protocol + '//' + window.location.host + '/event/' + replaceSpaceInName(props.postTitle) + this.props.postId,
                copy: 'Copy'
            }
        }
    }


    handleInputChange = (event) => {
        if (event.target.name === "emailIds") {
            this.setState({
                disableShareButton: event.target.value === '',
                invalidEmailId: false
            });
        }
        this.setState({ [event.target.name]: event.target.value })
    }

    validateEmailIds = () => {
        const emailIds = this.state.emailIds.trim().split(',');
        let validEmailIds = true;

        //validate all email addresses
        if (emailIds.length > 0) {
            emailIds.forEach(emailId => {
                if (emailId && !emailRegex.test(emailId.trim())) {
                    validEmailIds = false;
                }
            });
        }
        return validEmailIds;
    }

    handleShareClick = () => {
        if (this.props.postShareStatus === "shared") {
            this.props.setSharePostStatus(undefined);
            this.props.hideSharePost(true);
        }
        else {
            const validationResult = this.validateEmailIds();
            const { subject, emailIds, emailBody } = this.state;
            if (validationResult) {
                const formData = {
                    to: emailIds.split(','),
                    subject,
                    post_id: this.props.postId,
                    post_title: this.props.postTitle || "_",
                    body: emailBody
                };
                this.props.sharePost(formData);
            }
            else {
                this.setState({ invalidEmailId: true });
            }
        }
    }

    copyPostUrl = () => {
        navigator.clipboard.writeText(this.state.post_url)
        this.setState({ 'copy': 'Copied' })
        setTimeout(() => {
            this.setState({ 'copy': 'Copy' })
        }, 4000);
    }

    socialSharePostLink = (platform) => {
        let share_link = '';
        if (platform == 'fb') {

            window.open("https://www.facebook.com/sharer/sharer.php?u=" + this.state.post_url, "pop", "width=600, height=400, scrollbars=no");
        }

        if (platform == 'tw') {
            share_link = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(this.state.post_url)
            window.open(share_link, '_blank');
        }

        if (platform == 'ld') {
            share_link = 'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURI(this.state.post_url) + '&source=mappes.io'
            window.open(share_link, '', '_blank, width=500, height=500, resizable=yes, scrollbars=yes');
        }


    }

    render() {
        // const showInvalidEmailIdError = this.state.invalidEmailId;
        const { copy } = this.state;
        return (
            <>
                <div className='sharepost-popup'>
                    <div className='share-header'>
                        <h2>Share this post</h2>
                        <a onClick={this.props.closePopup}><i className="icon-close"></i></a>
                    </div>
                    <div className='share-body'>
                        <div className='post-link-row'>
                            <Input labelPosition='right' type='text' placeholder='Amount'>
                                <Label basic>
                                    <SvgIcon name='link-icon' viewbox='0 0 18.573 18.557' />
                                </Label>
                                <input value={this.state.post_url} />
                                <Label className="copy-btn" onClick={this.copyPostUrl}> {copy} <SvgIcon name='copy-icon' viewbox='0 0 15.333 17' /></Label>
                            </Input>
                        </div>
                        <div className='social-btn-row'>
                            <Button onClick={() => { this.socialSharePostLink('fb') }} className="fb-btn"><SvgIcon name='facebook2' viewbox='0 0 14.481 14.394' /> Facebook</Button>
                            <Button onClick={() => { this.socialSharePostLink('tw') }} className="twitter-btn"><SvgIcon name='twitter2' viewbox='0 0 18 14.619' /> Twitter</Button>
                            <Button onClick={() => { this.socialSharePostLink('ld') }} className="linkedin-btn"><SvgIcon name='linkedin2' viewbox='0 0 14.5 14.5' /> LinkedIn</Button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default SharePost;