import { constants } from "./index";

export const getYouTubeVideoId = (articleText) => {
    if (!articleText) { return '' };
    const youtubeUrl = articleText.match(constants.youtubeUrlRegex);
    const youtubeVideoId = youtubeUrl && youtubeUrl[0].match(constants.youTubeVideoIdRegex)[7];
    return youtubeVideoId || '';
}

export const getVimeoIdFromUrl = (url) => {
    const match = /vimeo.*\/(\d+)/i.exec(url);
    if (match) {
        return match[1];
    }
};

export const checkVimeoUrl = (articleText) => {
    if (!articleText) { return '' };
    const vimeourl = articleText.match(constants.vimeoUrlRegex);
    if (vimeourl != null && vimeourl != undefined) {
        return vimeourl[0];
    } else {
        return '';
    }
}

export const getUrlFromText = (text) => {
    if (!text) { return '' }
    const articleUrl = text.match(constants.urlRegex);
    return articleUrl ? articleUrl[0] : '';
}

export const getUrlFromHtml = (text) => {
    if (!text) { return '' }
    const articleUrl = text.match(constants.urlRegexWithoutAnchorTag);
    return articleUrl && isValidUrl(articleUrl) ? articleUrl[0] : '';
}

export const formatIsoMiliseconds = (ISOMiliSeconds) => {
    return  new Date(ISOMiliSeconds).toLocaleString(undefined, { day: "numeric", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit" })
};

export const formatIsoMiliseconds2 = (ISOMiliSeconds) => {
    console.log('new Dat ', new Date(ISOMiliSeconds))
    let fd = new Date(ISOMiliSeconds)
    var hours = fd.getHours();
    var minutes = fd.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var tm = hours + ':' + minutes + ' ' + ampm;

    return fd.getDate() + '-' + (fd.getMonth() + 1) + '-' + fd.getFullYear() + ' ' + tm
};
export const formatIsoMilisecondsWithoutTime = (ISOMiliSeconds) => new Date(ISOMiliSeconds).toLocaleString(undefined, { day: "numeric", month: "short", year: "numeric" });
export const formatIsoMilisecondOnlyTime = (ISOMiliSeconds) => new Date(ISOMiliSeconds).toLocaleString(undefined, { hour: "2-digit", minute: "2-digit" });
export const formatIsoMilisecondsToYYYYMMDD = (ISOMiliSeconds) => new Date(ISOMiliSeconds).toISOString().substr(0, 10);

export const sortListByStringKey = (list, key) => {
    list.sort((a, b) => {
        var nameA = a[key] ? a[key].toUpperCase() : ""; // ignore upper and lowercase
        var nameB = b[key] ? b[key].toUpperCase() : ""; // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;
    })
    return list;
}

export const sortList = (list, key, order) => {
    list.sort((a, b) => {
        if (order === "asc")
            return a[key] - b[key];
        else
            return b[key] - a[key];
    })
    return list;
}

const isValidUrl = (string) => {
    try {
        const url = new URL(string);
    }
    catch (_) {
        return false;
    }
    return true;
}

export const replaceSpaceInName = (name) => name ? name.replaceAll(' ', '-').replace(/[`~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]/gi, '') : '';

export const getFormattedDate = (date, prefomattedDate = false, hideYear = false, hideTime = false) => {

    var month_names = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    
    var month_names_short = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',
        'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
    ];
    const day = date.getDate();
    const month = month_names[date.getMonth()];
    const month_short = month_names_short[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    let minutes = date.getMinutes();
    let local_string_time = date.toLocaleString(undefined, { hour: "2-digit", minute: "2-digit" });

    if (minutes < 10) {
        // Adding leading zero to minutes
        minutes = `0${minutes}`;
    }

    if (prefomattedDate) {
        // Today at 10:20
        // Yesterday at 10:20
        return `${prefomattedDate} at ` + local_string_time;
    }

    if (hideYear) {
        // 10. January at 10:20
        return `${day} ${month} at ` + local_string_time;
    }
    
    if (hideTime) {
        // 10. January 2023
        
        return `${day} ${month_short} ${year}` ;
    }

    // 10. January 2017. at 10:20
    return `${day}. ${month} ${year}. at ` + local_string_time;
}

export const feedTimeAgo = (feed_date) => {
    if (!feed_date) {
        return null;
    }

    const date = new Date(feed_date);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();

    if (seconds < 5) {
        return 'now';
    } else if (seconds < 60) {
        return `${seconds} seconds ago`;
    } else if (seconds < 90) {
        return 'about a min ago';
    } else if (minutes < 60) {
        return `${minutes} mins ago`;
    } else if (isToday) {
        return getFormattedDate(date, 'Today'); // Today at 10:20
    } else if (isYesterday) {
        return formatIsoMiliseconds(date); //  return getFormattedDate(date, 'Yesterday'); Yesterday at 10:20
    } else if (isThisYear) {
        return formatIsoMiliseconds(date); // return getFormattedDate(date, false, true); 10. January at 10:20
    }

    return formatIsoMiliseconds(date); // getFormattedDate(date); 10. January 2017. at 10:20

}

export const articleTimeAgo = (feed_date) => {
    if (!feed_date) {
        return null;
    }

    const date = new Date(feed_date);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();

    if (seconds < 5) {
        return 'Published now';
    } else if (seconds < 60) {
        return `Published ${seconds} seconds ago`;
    } else if (seconds < 90) {
        return 'Published about a min ago';
    } else if (minutes < 60) {
        return `Published ${minutes} mins ago`;
    } else if (isToday) {
        return `Published ` + getFormattedDate(date, 'Today'); // Today at 10:20
    } else if (isYesterday) {
        return `Published ` + formatIsoMiliseconds(date); //  return getFormattedDate(date, 'Yesterday'); Yesterday at 10:20
    } else if (isThisYear) {
        return ` Published on ` + formatIsoMiliseconds(date); // return getFormattedDate(date, false, true); 10. January at 10:20
    }

    return `Published on ` + formatIsoMiliseconds(date); // getFormattedDate(date); 10. January 2017. at 10:20
}

export const getDay = (date_string) => {
    if (!date_string) {
        return null;
    }

    const date = new Date(parseInt(date_string));
    const day_list = { 0: 'Sunday', 1: 'Monday', 2: 'Tuesday', 3: 'Wednesday', 4: 'Thursday', 5: 'Friday', 6: 'Saturday' }
    const day = day_list[date.getDay()]
    return day
}

export const isHtmlCheck = (string_val) => {
    return /<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i.test(string_val);
}
