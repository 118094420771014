import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Table,
    Dropdown,
    Modal,
    Button,
    Label,
    List,
    Search,
    Icon,
    Grid,
    Header,
    Divider,
} from 'semantic-ui-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../../config/config';
import SupplierCountry from './SupplierCountry';
import _ from 'lodash';
// import { SvgIcon } from '../../common';
// import { Field, reduxForm } from 'redux-form';
// import { connect } from 'react-redux';
// import * as actions from '../../../actions/'

import PremiumTag from '../../../assets/images/premium-tag.svg';

const resultRenderer = result => (
    <Label key={result._source.id} content={result._source.name + ' (' + result._source.country + ')'} />
);

export default class Supplier extends Component {
    constructor(props) {
        super(props);
        this.state = {
            raws: [],
            modalOpen: false,
            addRawButtonLoading: false,
            resultsRendered: 4,
            showLoadMore: true,
            condition: false,
            LastEvaluatedKey: props.supplier && props.supplier.LastEvaluatedKey,
            suppliers: [],
            showSupplierLoader: true,
        };
    }
    notify = () => {
        toast.success('Successfully added suppliers !', {
            autoClose: 3000,
        });
    };
    componentWillMount() {
        this.resetComponent();
    }
    componentDidUpdate(prevProps) {
        if (prevProps && prevProps.info && this.props.info.Item.id !== prevProps.info.Item.id) {
            this.setState({
                raws: [],
                modalOpen: false,
                addRawButtonLoading: false,
                resultsRendered: 4,
                showLoadMore: true,
                condition: false,
                LastEvaluatedKey: null,
                suppliers: [],
                showSupplierLoader: true,
            });
        }
        if (
            this.props.supplier &&
            _.differenceBy(this.props.supplier.results, this.state.suppliers, 'company_id').length !== 0
        ) {
            let newSuppliers = _.uniqBy([...this.state.suppliers, ...this.props.supplier.results], 'company_id');
            if (this.state.suppliers.length > 0 && this.state.suppliers[0].product_id !== this.props.info.Item.id) {
                newSuppliers = this.props.supplier.results;
            }
            this.setState({
                LastEvaluatedKey: this.props.supplier.LastEvaluatedKey,
                showSupplierLoader: false,
                suppliers: newSuppliers,
            });
        } else if (
            this.props.supplier &&
            this.props.supplier.results.length == 0 &&
            this.state.suppliers.length > 0 &&
            this.state.suppliers[0].product_id !== this.props.info.Item.id
        ) {
            this.setState({
                LastEvaluatedKey: null,
                showSupplierLoader: false,
                suppliers: [],
            });
        }
    }
    handleOpen = () => this.setState({ modalOpen: true });

    handleClose = () => this.setState({ modalOpen: false });

    resetComponent = () => this.setState({ isLoading: false, results: [], value: '' });

    handleResultSelect = (e, { result }) => {
        this.setState({ value: result._source.name });
        let newRaw = this.state.raws;
        newRaw.push({
            id: result._source.id,
            name: result._source.name,
            country: result._source.country
        });
        this.setState({
            raws: newRaw,
            value: '',
        });
    };

    handleSearchChange = (e, { value }) => {
        this.setState({ value });
        this.setState({ isLoading: true });
        axios.get(`${BASE_URL}/api/v1/company/search/${value}`, { withCredentials: true }).then(response => {
            this.setState({
                isLoading: false,
                results: response.data,
            });
        });
    };

    addRawMaterials() {
        this.setState({
            addRawButtonLoading: true,
        });
        for (let i = 0; i < this.state.raws.length; i++) {
            let data = {
                company_id: this.state.raws[i].id,
                company_name: this.state.raws[i].name,
                product_id: this.props.info.Item.id,
                product_name: this.props.info.Item.name,
            };
            axios.post(`${BASE_URL}/api/v1/product/supplier`, data, { withCredentials: true });
        }
        let that = this;
        setTimeout(function () {
            that.setState({
                addRawButtonLoading: false,
                raws: [],
                modalOpen: false,
            });
            that.notify();
            window.location.reload();
        }, 3000);
    }
    removeItem(id) {
        this.setState({
            raws: this.state.raws.filter(e => e.id !== id),
        });
    }
    renderRawMaterials() {
        return this.state.raws.map((raw, index) => {
            return (
                <List.Item key={raw.id}>
                    <List.Content floated="right">
                        <Button style={{ width: '40px' }} icon onClick={this.removeItem.bind(this, raw.id)} color={'red'}>
                            <Icon name="delete" />
                        </Button>
                    </List.Content>
                    <List.Content style={{ fontSize: '19px', color: '#979499' }}>
                        {raw.name} ({raw.country})
                    </List.Content>
                </List.Item>
            );
        });
    }
    handleMoreClick() {
        const { auth, getProductSupplier, showLoginModal, info, history } = this.props;
        if (!auth) {
            history.push('/otp-login');

            showLoginModal(true);
        } /* else if (!auth.isActive) {
            history.push('/upgrade');
        }  */else {
            this.setState({ showSupplierLoader: true });
            getProductSupplier(info.Item.id, this.state.LastEvaluatedKey, "nonPremium");
        }
        // this.setState({
        //     resultsRendered: this.state.resultsRendered + 5,
        //     showLoadMore : (this.state.resultsRendered+5 >= this.props.supplier.length) ? false : true
        // })
    }
    handleClick() {
        this.setState({
            condition: !this.state.condition,
        });
    }
    renderSupplier() {
        return this.state.suppliers.map((supplier, index) => {
            return (
                <Table.Row key={index}>
                    {/* <Table.Cell className="toggle-cell-icon">
                            <Button basic color='blue' size='mini' icon onClick={ this.handleClick.bind(this) } className= { this.state.condition ? "active" : "" }>
                                <i className="mappes-icon icon-plus"></i>
                            </Button>
                        </Table.Cell> */}
                    <Table.Cell>
                        <Link to={`/company/${supplier.company_id}`}>{supplier.company_name}
                            {supplier.premium &&
                                <img alt={PremiumTag} style={{ width: 56, marginBottom: -3, marginLeft: 2 }} src={PremiumTag} />
                            }
                        </Link>
                    </Table.Cell>
                    <Table.Cell>
                        <SupplierCountry compId={supplier.company_id} />
                    </Table.Cell>
                    <Table.Cell textAlign="center" style={{ color: '#797979' }}>
                        {supplier.contacts}
                    </Table.Cell>
                </Table.Row>
            );
        });
    }

    render() {
        const { isLoading, value, results } = this.state;

        return (
            <Grid id="abc" padded>
                <Grid.Row className="background-white extra-padding-sides mx-0">
                    <Grid.Column width={16}>
                        <Grid>
                            <Grid.Column floated="left" width={13}>
                                <Header as={'h4'} className="table-heading pD-b-10">
                                    Suppliers
                                </Header>
                            </Grid.Column>
                            <Grid.Column textAlign="right" floated="right" width={3}>
                                {this.props.auth && (
                                    <Dropdown
                                        className="padding-top-15"
                                        trigger={
                                            <Icon
                                                className="color-light-blue"
                                                size="large"
                                                name={'ellipsis horizontal'}
                                            />
                                        }>
                                        <Dropdown.Menu direction="right" style={{ borderRadius: '10px' }}>
                                            <Modal
                                                open={this.state.modalOpen}
                                                onClose={this.handleClose}
                                                size={'large'}
                                                trigger={
                                                    <Dropdown.Item
                                                        onClick={this.handleOpen}
                                                        className="dropDownThreeDots"
                                                        text="Add Suppliers"
                                                    />
                                                }>
                                                <Modal.Header>Add Suppliers to the product</Modal.Header>
                                                <Modal.Content>
                                                    <Modal.Description>
                                                        <Grid>
                                                            <Grid.Column width={4}></Grid.Column>
                                                            <Grid.Column width={8}>
                                                                <Search
                                                                    fluid
                                                                    loading={isLoading}
                                                                    onResultSelect={this.handleResultSelect}
                                                                    onSearchChange={this.handleSearchChange}
                                                                    results={results}
                                                                    value={value}
                                                                    resultRenderer={resultRenderer}
                                                                />
                                                                <List
                                                                    style={{ paddingTop: '20px' }}
                                                                    animated
                                                                    divided
                                                                    verticalAlign="middle">
                                                                    {this.renderRawMaterials()}
                                                                </List>
                                                            </Grid.Column>
                                                            <Grid.Column width={4}></Grid.Column>
                                                        </Grid>

                                                        <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                                                            <Button
                                                                loading={this.state.addRawButtonLoading}
                                                                primary
                                                                animated
                                                                className="background-light-blue"
                                                                onClick={this.addRawMaterials.bind(this)}>
                                                                <Button.Content visible>Submit</Button.Content>
                                                                <Button.Content hidden>
                                                                    <Icon name="right arrow" />
                                                                </Button.Content>
                                                            </Button>
                                                            <Button animated color="red" onClick={this.handleClose}>
                                                                <Button.Content visible>Cancel</Button.Content>
                                                                <Button.Content hidden>
                                                                    <Icon name="delete" />
                                                                </Button.Content>
                                                            </Button>
                                                        </div>

                                                        {/* <button onClick={this.addRawMaterials.bind(this)}>Done</button> */}
                                                    </Modal.Description>
                                                </Modal.Content>
                                            </Modal>
                                            {/* <Dropdown.Item className="dropDownThreeDots" text='Remove Suppliers' /> */}
                                            {/* <Divider /> */}
                                            {/* <Dropdown.Item style={{ marginTop: "5px", marginBottom: "5px" }} className="dropDownThreeDots" text='Feedback' /> */}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )}
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Divider className="tableDivider" />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Table padded basic="very" striped unstackable>
                            <Table.Header>
                                <Table.Row>
                                    {/* <Table.HeaderCell className="toggle-cell-icon"></Table.HeaderCell> */}
                                    <Table.HeaderCell className="color-light-blue">Company Name</Table.HeaderCell>
                                    <Table.HeaderCell className="color-light-blue">Country</Table.HeaderCell>
                                    <Table.HeaderCell className="color-light-blue" textAlign="center">
                                        Listed Contacts
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>{this.state.suppliers && this.renderSupplier()}</Table.Body>
                        </Table>
                        {this.state.suppliers  && this.props.supplier && this.state.suppliers.length < this.props.supplier.count && (
                            <Button
                                className="color-light-blue cursor-pointer load_more"
                                onClick={this.handleMoreClick.bind(this)}
                                loading={this.state.showSupplierLoader}
                            >
                                Load More
                               {/*  {this.props.auth ?
                                    this.props.auth.isActive ? "Load More"
                                        :
                                        "Upgrade to see the complete list"
                                    : "Load More"} */}
                            </Button>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
