import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
// import { Message } from 'semantic-ui-react';
import { Table, Grid } from 'semantic-ui-react';
import { userActions } from '../../actions';
import { replaceSpaceInName } from '../../common/helperMethods';
import Layout from '../common/layout/Layout';
import SEO from '../seo/SEO';

class Vendors extends React.Component {
    componentDidMount() {
        this.props.getVendors();
    }

    render() {

        const { vendors, userProfileDetails, auth } = this.props;

        return (
              <>
                <SEO robots="noindex, nofollow"
                    canonicalUrl="/vendors"
                />
            <Layout {...this.props} entityId={auth ? auth.id : null} entityType={"user"}>
                <Grid.Column width={16}>
                    {
                        vendors && !vendors.length ?
                            <div className="col-sm-12 headers-section mb-4">
                                <h2>
                                    Please add products on your company page:{" "}
                                    <Link to={`/company/${userProfileDetails && userProfileDetails.companyId}`}>
                                        {userProfileDetails && userProfileDetails.company}
                                    </Link> so that we can build recommendations for you
                                </h2>
                            </div>
                            :
                            <Table padded basic="very" striped unstackable>
                                <Table.Header>
                                    <Table.Row>
                                        {/* <Table.HeaderCell className="toggle-cell-icon"></Table.HeaderCell> */}
                                        <Table.HeaderCell className="color-light-blue">Company Name</Table.HeaderCell>
                                        <Table.HeaderCell className="color-light-blue">Country</Table.HeaderCell>
                                        <Table.HeaderCell className="color-light-blue" textAlign="center">
                                            Listed Contacts
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        this.props.vendors && this.props.vendors.map((vendor, index) =>
                                            <Table.Row key={index}>

                                                <Table.Cell>
                                                    <Link to={`/company/${replaceSpaceInName(vendor.company_name)}/${vendor.company_id}`}>{vendor.company_name}
                                                        {/* {supplier.premium &&
                                                <img style={{ width: 56, marginBottom: -3, marginLeft: 2 }} src={PremiumTag} />
                                            } */}
                                                    </Link>
                                                </Table.Cell>
                                                <Table.Cell>{vendor.company_country}</Table.Cell>
                                                <Table.Cell textAlign="center" style={{ color: '#797979' }}>
                                                    {vendor.company_contacts}
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                    }
                    {/* {this.state.suppliers && this.props.supplier && this.state.suppliers.length < this.props.supplier.count && (
                    <Button
                        className="color-light-blue cursor-pointer load_more"
                        onClick={this.handleMoreClick.bind(this)}
                        loading={this.state.showSupplierLoader}
                    >
                        {this.props.auth ?
                            this.props.auth.isActive ? "Load More"
                                :
                                "Upgrade to see the complete list"
                            : "Load More"}
                    </Button>
                )} */}

                </Grid.Column>
            </Layout>
            </>
        )
    }
}

const mapStateToProps = ({ user, auth }) => {
    return {
        vendors: user.vendors,
        userProfileDetails: user.userProfileDetails,
        auth
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        ...bindActionCreators(userActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Vendors);