import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../../config/config'
import { connect } from 'react-redux';
// import NavbarDefault from '../../common/layout/navbar-default/navbar-default';
// import { commonActions } from '../../../actions';
import './listing-names.scss';
import { emailRegex } from '../../../common/constants';
import ThankyouView from '../thank-you/thank-you';
import Navbar from '../../common/layout/navbar/Navbar';
import LeftSidebar from '../../common/LeftSidebar';
import RightSection from '../../common/RightSection';

class ListingNamesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailIds: [],
            invalidEmailIds: []
        };
    }

    handleChange = (e) => {
        this.setState({
            emailIds: { ...this.state.emailIds, [e.target.name]: e.target.value }
        });
    }
    submit = async () => {
        const { emailIds } = this.state;
        const ids = this.props.auth.subscribedUsers || [];
        for (const email in emailIds) {
            ids.push(emailIds[email])
        }
        // const validationResult = this.validateEmails();
        // if (validationResult) {
        this.setState({ showLoader: true })
        await axios.post(`${BASE_URL}/api/v2/me/subscribedusers`, [...ids,], { withCredentials: true })
        // }
        this.setState({ signUpComplete: true, showLoader: false });
    }

    validateEmails = () => {
        let invalidEmailIds = []
        this.state.emailIds.forEach(email => {
            if (!emailRegex.test(email.value.trim())) {
                invalidEmailIds.push(email.name);
            }
        });
        this.setState({ invalidEmailIds });
        return invalidEmailIds.length === 0;
    }

    render() {
        const { invalidEmailIds, signUpComplete } = this.state;
        const { auth } = this.props;
        let subscribedUsers = auth && auth.subscribedUsers || [];

        return (
            <Navbar {...this.props}>
                <div className="profile-mainwrapper">
                    <div className="container">
                        <div className="profile-mainwrapper-inner">
                            <div className="leftbar">
                                <LeftSidebar />
                            </div>
                            <div className="middle-section">
                                {
                                    signUpComplete ?
                                        <ThankyouView />
                                        :
                                        <div className="row listing-names-section">
                                            <div className="col-sm-11 m-auto listing-names-header">
                                                <h1><b>Congratulations !</b></h1>
                                                <p>We are so glad you are a premium member. Please add your additional team members that you’d like on your account below.</p>
                                            </div>
                                            <div className="col-sm-9 mt-5 mt-3 mx-auto">
                                                {
                                                    subscribedUsers.map(emailId =>
                                                        <div className="ui input large w-100 form-input mb-2">
                                                            <input type="text" name="email2" placeholder="Supply-chain@company.com" className={invalidEmailIds.includes("email2") && 'error'} value={emailId} disabled />
                                                        </div>
                                                    )
                                                }
                                                {
                                                    subscribedUsers.length < 4 &&
                                                    [...Array(4 - subscribedUsers.length).keys()].map(() =>
                                                        <div className="ui input large w-100 form-input mb-2">
                                                            <input type="text" name="email3" onChange={this.handleChange} placeholder="Business-development@company.com" className={invalidEmailIds.includes("email3") && 'error'} />
                                                        </div>
                                                    )
                                                }
                                                <div className="row mt-3">
                                                    <div className="col-sm-6 m-auto text-center">
                                                        <Button className="ui primary button w-100 btn-lg" onClick={this.submit} loading={this.state.showLoader}>
                                                            Finish
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className="mb-4 mt-5 text-center">
                                                    <Link to="/" className="text-dark">
                                                        Skip step
                                            </Link>
                                                </div>
                                            </div>
                                        </div>

                                }
                            </div>
                            <div className="rightbar">
                                <RightSection />
                            </div>
                        </div>
                    </div>
                </div>
            </Navbar>
        );
    }
}

function mapStateToProps({ events, user, auth, common }) {
    return {
        userName: user.userProfileInfo && `${user.userProfileInfo.firstName} ${user.userProfileInfo.lastName}`,
        auth,
    };
}

export default connect(mapStateToProps)(ListingNamesView);