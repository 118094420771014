import React, { Component } from 'react';
import 'draft-js/dist/Draft.css';
import debounce from 'lodash/debounce';
import {
  Editor,
  EditorState,
  RichUtils,
  convertFromRaw,
  convertToRaw,
  stateToHTML
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import HeaderStyleDropdown from './HeaderStyleDropdown';
import { SvgIcon } from '../../common';
import FileUploadControl from '../../common/FileUploadControl';
import { Button } from 'semantic-ui-react';
import CustomRickEditor from '../AddNewPost/NewsRichEditor';
import Previewer from '../AddNewPost/Previewer';

const MAX_LENGTH = 10;

class RichEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
      postMediaType: '',
      postMediaUrl: '',
      filaname: '',
      filevalue: '',
      fileReadCompleted: false,
      showFileUploadControl: true,
      title: '',
      text: '',
      data_filled : false,
    };

    //  this.onChange = this.onChange.bind(this);
    /* this._getLengthOfSelectedText = this._getLengthOfSelectedText.bind(this);
    this._handleBeforeInput = this._handleBeforeInput.bind(this); */

    // this.focus = () => this.refs.editor.focus();

    /* this.handleKeyCommand = (command) => this._handleKeyCommand(command);
    this.onTab = (e) => this._onTab(e);
    this.toggleBlockType = (type) => this._toggleBlockType(type);
    this.toggleInlineStyle = (style) => this._toggleInlineStyle(style); */

    this.assemblePost = this.assemblePost.bind(this);
    this.previewPostMedia = this.previewPostMedia.bind(this);


  }

  /* onChange = (editorState) => {
    const contentState = editorState.getCurrentContent();
    this.saveContent(contentState);
    this.setState({ editorState });
    this.props.handleInputChange('text', draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())))
  }; */

  saveContent = debounce((content) => {
    window.localStorage.setItem(
      'content',
      JSON.stringify(convertToRaw(content))
    );
  }, 1000);

  // saveContent = (content) => {
  //   window.localStorage.setItem('content', JSON.stringify(convertToRaw(content)));
  // }

  /*   _getLengthOfSelectedText = () => {
      console.log('say');
      const currentSelection = this.state.editorState.getSelection();
      const isCollapsed = currentSelection.isCollapsed();
  
      let length = 0;
  
      if (!isCollapsed) {
        const currentContent = this.state.editorState.getCurrentContent();
        const startKey = currentSelection.getStartKey();
        const endKey = currentSelection.getEndKey();
        const isBackward = currentSelection.getIsBackward();
        const blockMap = currentContent.getBlockMap();
        const startBlock = currentContent.getBlockForKey(startKey);
        const endBlock = currentContent.getBlockForKey(endKey);
        const isStartAndEndBlockAreTheSame = startKey === endKey;
        const startBlockTextLength = startBlock.getLength();
        const endBlockTextLength = endBlock.getLength();
        const startSelectedTextLength =
          startBlockTextLength - currentSelection.getStartOffset();
        const endSelectedTextLength = currentSelection.getEndOffset();
        const keyAfterEnd = currentContent.getKeyAfter(endKey);
  
        if (isStartAndEndBlockAreTheSame) {
          length +=
            currentSelection.getEndOffset() - currentSelection.getStartOffset();
        } else {
          let currentKey = startKey;
          let counter = 0;
  
          while (currentKey && currentKey !== keyAfterEnd) {
            if (currentKey === startKey) {
              length += startSelectedTextLength + 1;
            } else if (currentKey === endKey) {
              length += endSelectedTextLength;
            } else {
              length += currentContent.getBlockForKey(currentKey).getLength() + 1;
            }
  
            currentKey = currentContent.getKeyAfter(currentKey);
          }
        }
      }
  
      return length;
    };
  
    _handleBeforeInput = () => {
      console.log('hello');
      const currentContent = this.state.editorState.getCurrentContent();
      const currentContentLength = currentContent.getPlainText('').length;
  
      if (currentContentLength > MAX_LENGTH - 1) {
        console.log('you can type max ten characters');
  
        return 'handled';
      }
    };
  
    _handlePastedText = (pastedText) => {
      const currentContent = this.state.editorState.getCurrentContent();
      const currentContentLength = currentContent.getPlainText('').length;
      const selectedTextLength = this._getLengthOfSelectedText();
  
      if (
        currentContentLength + pastedText.length - selectedTextLength >
        MAX_LENGTH
      ) {
        console.log('you can type max ten characters');
  
        return 'handled';
      }
    };
  
    _handleKeyCommand(command) {
      const { editorState } = this.state;
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        this.onChange(newState);
        return true;
      }
      return false;
    } */

  /*  _onTab(e) {
     const maxDepth = 4;
     this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
   }
 
   _toggleBlockType(blockType) {
     this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
   }
 
   _toggleInlineStyle(inlineStyle) {
     this.onChange(
       RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
     );
   } */

  componentWillReceiveProps(newProps) {
    if (this.props.id!= undefined ){
      if (newProps.postDetails != undefined && newProps.postDetails.post != undefined && this.state.data_filled== false) {
        let articleData = newProps.postDetails.post;
        let fileReadCompleted = (newProps.postDetails.fileReadCompleted != undefined) ? newProps.postDetails.fileReadCompleted : false;
        let showFileUploadControl = (newProps.postDetails.showFileUploadControl != undefined) ? newProps.postDetails.showFileUploadControl : true
       
        if (newProps.postDetails.postMediaUrl == '' && newProps.postDetails.postMediaType == ''){
          showFileUploadControl = true;
        }
  
        this.setState({
          "postMediaType": newProps.postDetails.postMediaType,
          "postMediaUrl": newProps.postDetails.postMediaUrl,
          "filaname": newProps.postDetails.filaname,
          "title": articleData.title,
          "text": articleData.text,
          "showFileUploadControl": showFileUploadControl,
          "fileReadCompleted": fileReadCompleted ,
        })
        
        if(articleData.text!= ''){
          this.setState({ data_filled : true})
        }
        
        
  
      }
    }

  }

  handleFileUpload = (event) => {

    let file = event.target.files[0];
    if (file) {

      let fileType = file.type;
      let name;
      
      if (fileType.indexOf("image") > -1) {
        name = "image";
        let reader = new FileReader();
        this.props.assemblePost(name, file);
        this.setState({
          filaname: name,
          filevalue: file
        });
        let self = this;
        reader.onload = function (e) {
          self.props.previewPostMedia(name, e.target.result);
          self.previewPostMedia(name, e.target.result);
  
          self.setState({ fileReadCompleted: true, showFileUploadControl: false })
        };
        reader.readAsDataURL(file);
      }

    }
  }

  changeSelectedFile = () => {
    
    //show file upload control
    this.setState({ showFileUploadControl: true, fileReadCompleted: false, });

    //hide previewer
    this.props.previewPostMedia('', '');
    this.previewPostMedia('', '');
  }

  popup_tagged_products = (value) => {
    this.props.tagged_products(value);
  }

  popup_tagged_company = (value) => {
    this.props.tagged_company(value);
  }

  assemblePost = (name, value) => {
    this.setState({ post: this.defaultPostState })

    const post = this.state.post;
    post[name] = value;
    this.setState({ post })
  }

  previewPostMedia = (postMediaType, postMediaUrl) => {
    this.setState({
      postMediaType: postMediaType,
      postMediaUrl: postMediaUrl
    })
  }

  handleEditorText = (value) => {
    this.setState({ text: value });
    this.props.assemblePost('text', value);
  }

  handleInputChange = (event) => {
    let current_value = event.target.value;
    this.setState({title: current_value})
    this.props.handleInputChange('title', current_value)
  }


  render() {
    const { editorState, post } = this.state;

    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }

    if (!this.state.editorState) {
      return <h3 className="loading">Loading...</h3>;
    }
    return (
      <div>
        <div className="RichEditor-root">
          {/* <div className='control-wrapper mt-3 mb-3'>
            <BlockStyleControls
              editorState={editorState}
              onToggle={this.toggleBlockType}
            />
            <InlineStyleControls
              editorState={editorState}
              onToggle={this.toggleInlineStyle}
            />
          </div> */}

          <div className={className} onClick={this.focus}>
            {/* <Editor
              blockStyleFn={getBlockStyle}
              customStyleMap={styleMap}
              editorState={editorState}
              handleKeyCommand={this.handleKeyCommand}
              onChange={this.onChange}
              onTab={this.onTab}
              placeholder="Write article"
              ref="editor"
              spellCheck={true}
            /> */}
         
            <div className='editorupper-part mb-3'>
              <div className='imageupload-card'>
                {this.state.showFileUploadControl &&
                  <div className='imageupload-cricle'>
                    <SvgIcon name='noimage' viewbox='0 0 72 72' />
                  </div>
                }

                <p className='mb-0'>
                  {this.state.fileReadCompleted &&
                    <Button icon="delete" className="remove-btn" circular size="tiny" floated='right' onClick={this.changeSelectedFile}>
                      <SvgIcon name='close-alt' viewbox='0 0 15 15.001' />
                    </Button>
                  }
                  {this.state.showFileUploadControl &&
                    <>
                      <p className='mb-1'>
                        Uploading a high-quality image that accurately represents your article before writing it. It attracts more viewers and increases engagement with your content.</p>
                      <div className="mt-0 mb-3">
                        <label>{this.state.label}</label>
                      <FileUploadControl handleFileUpload={this.handleFileUpload} accept="image/*" />
                      </div>
                      <p className='small-p'>
                        Recommended dimensions: 900x480 pixels Supports: .JPG, .JPEG2000, & .PNG (Max. file size allowed: 2 MB)
                      </p>
                    </>
                  }
                </p>
                {this.state.postMediaType && this.state.postMediaUrl &&
                <div className='imgviewer-uploaded'>
                  <Previewer type={this.state.postMediaType} url={this.state.postMediaUrl} postStatus={this.state.postStatus} />
                </div>
                }

              </div>

              <input className='heading-input mt-2' placeholder='Article title / heading' value={this.state.title} onChange={this.handleInputChange} />
              <CustomRickEditor
                onRef={ref => (this.child = ref)}
                value={this.state.text}
                auth={this.props.auth}
                toolbar={true}
                onEditorChange={this.handleEditorText}
                gettagged_products={this.popup_tagged_products}
                gettagged_company={this.popup_tagged_company}
                stripPastedStyles={true} />

            </div>
          </div>

        </div>
      </div>
    );
  }
}

// Custom overrides for "code" style.
/* const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return null;
  }
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let className = 'RichEditor-styleButton ui button';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }

    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
} */

/* const HEADER_TYPES = [
  { label: 'Heading 1', style: 'header-one' },
  { label: 'Heading 2', style: 'header-two' },
  { label: 'Heading 3', style: 'header-three' },
  { label: 'Body text', style: 'header-four' },
  { label: 'Normal', style: 'header-five' },
];

const BLOCK_TYPES = [
  { label: <SvgIcon name='quote-icon' viewbox='0 0 14 9.878' />, style: 'blockquote' },
  { label: <SvgIcon name='ullist-icon' viewbox='0 0 21 20' />, style: 'unordered-list-item' },
  { label: <SvgIcon name='ollist-icon' viewbox='0 0 22 18.76' />, style: 'ordered-list-item' },
];

 */
/* const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      <HeaderStyleDropdown
        headerOptions={HEADER_TYPES}
        active={blockType}
        onToggle={props.onToggle}
      />
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

var INLINE_STYLES = [
  { label: <SvgIcon name='bold-icon' viewbox='0 0 10 14' />, style: 'BOLD' },
  { label: <SvgIcon name='italic-icon' viewbox='0 0 12 14' />, style: 'ITALIC' },
  { label: <SvgIcon name='underline-icon' viewbox='0 0 11.68 17.114' />, style: 'UNDERLINE' },
  { label: <SvgIcon name='addphoto-icon' viewbox='0 0 22 22' /> },
  { label: <SvgIcon name='addvideo-icon' viewbox='0 0 24 21' /> },
];

const InlineStyleControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
}; */

export default RichEditor;