import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Label } from 'semantic-ui-react';
import { replaceSpaceInName } from '../../../common/helperMethods';
// import "./connections.scss";
import Message from '../Message';

class Followers extends Component {
    render() {
        const { followers, auth } = this.props;
        return (
            <div className="row">
                <div className="col-sm-12 p-0 background-white">
                    <h4 className="connections-list-heading px-4 pt-3">Followers</h4>
                    <div className="ui middle aligned divided list connections-list mt-2 px-4 py-1">
                        {followers && followers.followers.map(items => (
                            <div className="item" key={items.key}>
                                <div className="connection-left">
                                    <div className="images">
                                        {items.image ?
                                            <img alt="avtar" className="ui avatar image" src={items.image} />
                                            :
                                            <Label circular color="blue">{items.user_name && items.user_name.substring(0, 1)}</Label>
                                        }
                                    </div>
                                    <div className="content">
                                        <Link to={`/networkFeed/user/${items.user_id}`}><h4>{items.user_name}</h4></Link>
                                        <Link to={`/company/${replaceSpaceInName(items.company_name)}/${items.company_id}`} >
                                            <p>{items.company_name}</p>
                                        </Link>
                                    </div>
                                </div>
                                <div className="right floated content">
                                    <Message auth={auth} userId={items.user_id} userName={items.user_name} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
export default Followers;