import React from 'react';
import { Image } from 'semantic-ui-react';

const ProfileImage = ({ image, linkedinImage }) => {
    return image && linkedinImage ? (
        <Image
            className="user-avtar-image"
            src={image}
            srcChanged={false}
            onError={e => {
                if (!e.target.srcChanged) {
                    e.target.src = linkedinImage;
                }
                e.target.srcChanged = true;
            }}
        />
    ) : image ? (
        <Image className="user-avtar-image" src={image} />
    ) : linkedinImage ? (
        <Image className="user-avtar-image" src={linkedinImage} />
    ) : null;
};
export default ProfileImage;
