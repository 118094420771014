import React, { Component } from 'react';
import { Form, Button, Radio, Dropdown } from 'semantic-ui-react';
import Navbar from '../../common/layout/navbar/Navbar';
import { countryList } from '../../../common/countries';
import LeftSidebar from '../../common/LeftSidebar';
import RightSection from '../../common/RightSection';
import MetaTags from 'react-meta-tags';
import "./index.scss";

import ProfileCompletionImg from '../../../assets/images/profile-completion-img.svg';
import { SvgIcon } from '../../common';

const options = [
    {
      key: 1,
      text: 'Automatic solar cells tabber stringer',
      value: 1
    },
    {
      key: 2,
      text: 'Automatic solar cells tabber stringer',
      value: 2
    },
    {
      key: 3,
      text: 'Automatic solar cells tabber stringer',
      value: 3
    },
]

class ProfileCompletion extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    handleChange = (e, { value }) => this.setState({ value })
    render() {
        return (
            <>
                <MetaTags>
                    <meta name="robots" content="noindex, nofollow" />
                </MetaTags>
                <Navbar {...this.props}>
                    <div className="profile-mainwrapper">
                        <div className="container">
                            <div className="profile-mainwrapper-inner">
                                <div className="leftbar">
                                    <LeftSidebar />
                                </div>
                                <div className="middle-section profilecompletion-section">
                                    <div className='countryselect-card'>
                                        <div className='countryselect-card-inner'>
                                            <h4>Recommended to update</h4>
                                            <h2>Looks like you work at “mappes.io”</h2>
                                            <p>Adding your company will allow you to manage your company page as well as provide you with relevant information about your industry and products.</p>
                                            <div className='mt-2'>
                                                <Form.Field>
                                                    <Radio
                                                        label='Yes! I work at “Mappes.io”'
                                                        name='radioGroup'
                                                        value='this'
                                                        onChange={this.handleChange}
                                                        checked={this.state.value === 'this'}
                                                    />
                                                    </Form.Field>
                                                    <Form.Field>
                                                    <Radio
                                                        label='No. I work somewhere else'
                                                        name='radioGroup'
                                                        value='that'
                                                        onChange={this.handleChange}
                                                        checked={this.state.value === 'that'}
                                                    />
                                                </Form.Field>
                                            </div>
                                            <div className='mt-2'>
                                                <Dropdown
                                                    className='country-dropdown'
                                                    search
                                                    fluid
                                                    selection
                                                    placeholder='Select your country'
                                                    options={countryList}
                                                    name="country"
                                                />
                                            </div>
                                            <div className='text-center mt-4'>
                                                <Button primary className='btn-primary'>Save details</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='industriesproduct-card'>
                                        <img className='right-img' src={ProfileCompletionImg} alt={ProfileCompletionImg} />
                                        <div className='industriesproduct-card-inner'>
                                            <h4>Recommended - Step 1/2</h4>
                                            <h2>There is nothing in your feed</h2>
                                            <p>Follow the industries you would like to receive updates about. With this information, we will be able to create a personalised timeline for you according to the latest industry knowledge.</p>
                                            <div className='mt-4'>
                                                <h3>I’m interested to know more about “Industries”</h3>
                                            </div>
                                            <div className='tag-list'>
                                                <ul>
                                                    <li>+ Optical Fiber and Cable</li>
                                                    <li>+ PhotoVoltaic</li>
                                                    <li>+ UV/EB curing</li>
                                                    <li>+ Lithium-Ion</li>
                                                    <li>+ Lithium-Ion</li>
                                                    <li>+ Carbon Fiber</li>
                                                    <li>+ Semiconductor</li>
                                                    <li>+ Green Hydrogen</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='industriesproduct-card'>
                                        <img className='right-img' src={ProfileCompletionImg} alt={ProfileCompletionImg} />
                                        <div className='industriesproduct-card-inner'>
                                            <h4>Recommended - Step 2/2</h4>
                                            <h2>Can you tell us about the products you are interested in?</h2>
                                            <p>As you are interested in optical fibers and cables and photovoltaics, we suggest you follow the products in these fields that you prefer.</p>
                                            <div className='mt-4'>
                                                <h3><SvgIcon name='star-icon' viewbox='0 0 19 18' /> Most relevant <b>“Products”</b></h3>
                                            </div>
                                            <div className='tag-list'>
                                                <ul>
                                                    <li className='selected'><SvgIcon name='check' viewbox='0 0 11.385 8.685' /> Automatic layer deposition (ALD) system</li>
                                                    <li>+ UV curing systems</li>
                                                    <li>+ UV lamps</li>
                                                    <li>+ Polarizing Optical Elements</li>
                                                    <li className='selected'><SvgIcon name='check' viewbox='0 0 11.385 8.685' /> Photovoltaic Module Turnkey Production Line</li>
                                                </ul>
                                            </div>
                                            <div>
                                                <Dropdown className='country-dropdown' selection fluid options={options} placeholder='Choose an option' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rightbar">
                                    <RightSection entityType={"user"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Navbar>
            </>
        );
    }
}


export default ProfileCompletion;