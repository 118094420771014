import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Grid, Button, Progress } from 'semantic-ui-react';

import NewPostEditor from "./NewPostEditor";
import Popup_PostEditor from "./Popup_PostEditor";
import NewArticle from './NewArticle';
import { networkFeedActions } from '../../../actions';
import Previewer from './Previewer';

class AddNewPostContainer extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            postMediaType: '',
            postMediaUrl: '',
            post: {
                "userId": props.auth.id,
                "visibility": "public",
                "taggedProduct": [],
                "taggedCompanies": []
            }
        }

        this.submitPost = this.submitPost.bind(this);
        this.useTaggedProduct = this.useTaggedProduct.bind(this);
         this.useTaggedCompany = this.useTaggedCompany.bind(this);
        this.assemblePost = this.assemblePost.bind(this);
        this.defaultPostState = JSON.parse(JSON.stringify(this.state.post));
        this.previewPostMedia = this.previewPostMedia.bind(this);
    }

    useTaggedProduct(value) {
        if (value.length > 0) {
            const post = this.state.post;
            let taggedProducts = [];
            for (let item of value){
                taggedProducts.push(item.key);
            }
            post.taggedProduct = taggedProducts;
            //    this.state.post.taggedProduct = value;
        }
    }

    useTaggedCompany(value) {
        if (value.length > 0) {
            const post = this.state.post;
            let taggedCompanies = [];
            for (let item of value) {
                taggedCompanies.push(item.key);
            }
            post.taggedCompanies = taggedCompanies;
            // this.state.post.taggedCompanies = value;
        }
    }
    
    getUnique(array){
        var unique_arr = array.filter(function (item, pos) {
            return array.indexOf(item) == pos;
        });
        console.log('unique_arr ', unique_arr );
        return unique_arr;
    }

    submitPost() {
        const { feedType, entityId, auth, createNewPost } = this.props;
      /*   if (feedType == 'global') {
            console.log('this.state.props ', this.state.post);
          } */

        if (feedType === "product") {
            const post = this.state.post;
            const taggedProducts = this.state.post.taggedProduct.slice();

            taggedProducts.push(entityId);
            
            post.taggedProduct = this.getUnique(taggedProducts);

            this.setState({ post })
        }
        
        if (feedType === "company") {
            const post = this.state.post;
            const taggedCompanies = this.state.post.taggedCompanies.slice();

            taggedCompanies.push(entityId)
            post.taggedCompanies = this.getUnique(taggedCompanies);

            this.setState({ post })
        }
        
        if (this.state.postMediaType== ''){
            this.assemblePost('image', '');
            this.assemblePost('video', '');
            this.assemblePost('pdf', '');
            this.assemblePost('ppt', '');
        }
    
        createNewPost(this.state.post, feedType, entityId, auth);
        this.setState({
            post: {
                "userId": auth.id,
                "visibility": "public",
                "taggedProduct": [],
                "taggedCompanies": []
            }
        })

        this.child.clearInput();
    }

    assemblePost(name, value) {
        this.setState({ post: this.defaultPostState })

        const post = this.state.post;
        post[name] = value;
        this.setState({ post })
    }

    previewPostMedia(postMediaType, postMediaUrl) {
        this.setState({
            postMediaType,
            postMediaUrl
        })
    }

    render() {
        const { auth, postStatus, feedType, uploadProgress, entityId } = this.props;
        if (!auth) return null;
       // const { postMediaType, postMediaUrl } = this.state;
       // const showProgressBar = uploadProgress > 0 && postStatus === 'creating';

        return (
            
            <Grid.Row >
                <Grid.Column className="">
                    <div className="">
                        
                        <Popup_PostEditor auth={auth} assemblePost={this.assemblePost} previewPostMedia={this.previewPostMedia} postStatus={postStatus} feedType={feedType} onRef={ref => (this.child = ref)} submitPost={this.submitPost} tagged_products={this.useTaggedProduct} tagged_company={this.useTaggedCompany} history={this.props.history} entityId={entityId} />
                       
                    </div>
                </Grid.Column>
            </Grid.Row>

        )
    }
}

function mapStateToProps({ networkFeed }) {
    return { postStatus: networkFeed.postStatus, uploadProgress: networkFeed.uploadProgress };
}

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(networkFeedActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewPostContainer);
