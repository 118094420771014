export const Testimonials = [
    {
        content: "Mappes.io is an excellent platform to connect with all stake holders of the Industry. It virtually replaces all the big events & exhibitions where industry players travel thousands of miles to explore options and connecting with stake holders. Biggest beauty is Mappes.io provides an opportunity to explore options for almost everything starting from suppliers of machines & raw materials to prospective customers all the time, every day on a real time basis.",
        name: "Harsh Pagay",
        details: "CEO, HFCL, India"
    },
    {
        content: "Mappes is a useful platform for connecting the raw material suppliers, end users and applications for certain industries. The platform is also useful for marketing and promoting your products publicly as well as limiting the access to selective people.",
        name: "Danny Sohal",
        details: "Chief Operating Officer | UNIGEL(UK) Limited"
    }, {
        content: "Using Mappes is like Linkedin should be. It helps me build a network of contacts in the same industry whilst at the same time allowing me to access other specific industry networks. Much more focused and less cluttered than other networking programmes.",
        name: "John Davidson",
        details: "President, Carbon fiber Technologies International, United Kingdom"
    }, {
        content: "Mappes.io is a great opportunity for us to connect with stakeholders.Especially, in times where most of the physical shows are disrupted and cancelled this platform helps to create business and to keep in touch with our network.I see a big potential for mappes.io as a main sales platform in various industries.",
        name: "Mark Bauer",
        details: "CEO, NBG Systems, Austria"
    }
];