import React from 'react';
import { Button } from 'semantic-ui-react';
import { commonActions } from '../../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class LoginButton extends React.Component {
    render() {
        const { showLoginModal } = this.props;
        return (

            <>
                Please<Button className="color-light-blue text-center cursor-pointer load_more login_button" onClick={() => showLoginModal(true)}>login</Button>to see the complete list
            </>

        )
    }
}

const mapStateToProps = ({ auth, common }) => {
    return {
        auth,
        saveStatus: common.saveStatus
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        ...bindActionCreators(commonActions, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginButton);