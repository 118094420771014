export const industryImageMap = {
    additive: {
        banner: "AdditiveBanner.svg",
        companies: [
            "3dsystems.jpg",
            "eos.jpg",
            "ermaksan.jpg",
            "Incredible_AM.jpg",
            "postprocess.jpg",
            "Renishaw_Logo_NEW.jpg",
        ],
        products: [
            "1_3d_filament.jpg",
            "2_Metal_powder.jpg",
            "3_3D_Printer.jpg",
            "4_3D_Metal_printer.jpg",
            "5_Support_Removal_System.jpg",
            "6_Rapid_Prototype.jpg"
        ]
    },
    carbonFiber: {
        banner: "Carbon_Fibers_banner.svg",
        companies: [
            "cygnet-texkimp-logo-vector.jpg",
            "Formosa plastics.jpg",
            "harper.jpg",
            "Hexcel.jpg",
            "Hyosung.jpg",
            "jinggong science _ technology.jpg",
            "Litzler.jpg",
            "teijin.jpg",
            "SGL_Carbon_Logo.jpg",
            "Shenying.jpg",
            "Taikwang.jpg",
            "Toray.jpg"
        ],
        products: [
            "1_Carbon_fiber_precursor.jpg",
            "2_Carbon_Fibers.jpg",
            "3_Carbon_Fiber_Fabric.jpg",
            "4_Carbon_Fiber_Prepregs.jpg",
            "5_Oxidation_ovens.jpg",
            "6_Carbon_Fiber_production lines.jpg"
        ]
    },
    lithiumIon: {
        banner: "Lithium_Ion_banner.svg",
        companies: [
            "arl.jpg",
            "Asahi_Kasei_Logo.jpg",
            "Gelon_Lib_Group_Co_Ltd.jpg",
            "lg.jpg",
            "nexcharge.jpg",
            "samsung.jpg",
            "sdve.jpg",
            "targray.jpg",
            "techland.jpg",
            "Gelon_Lib_Group_Co_Ltd.jpg",
            "toshiba.jpg",
            "toyo.jpg"
        ],
        products: [
            "1_Anode.jpg",
            "2_Lithium_Ion_Cathode.jpg",
            "3_Battery_Separator.jpg",
            "4_Lithium_Ion_Production_lines.jpg",
            "5_Lithium_Ion_Cell.jpg",
            "6_Battery_Packs.jpg"
        ]
    },
    opticalFiber: {
        banner: "Optical_fiber_banner.svg",
        companies: [
            "Birla.jpg",
            "Heraeus.jpg",
            "Herkula.jpg",
            "HFCL.jpg",
            "Indore.jpg",
            "MS.jpg",
            "NBg.jpg",
            "Nextrom.jpg",
            "pe_fiberoptics.jpg",
            "Prysmian_logo.jpg",
            "Sterlite.jpg",
            "Unigel.jpg"
        ],
        products: [
            "1_EGlass_Spray_UpRoving-1.jpg",
            "4_Fixed_line_Broadband.jpg",
            "2_FRP_Rods.jpg",
            "2_Optical_fibers.jpg",
            "3_Optical_Fiber_cable.jpg",
            "Preform.jpg"
        ]
    },
    semiconductor: {
        banner: "semiconductorsBanner.svg",
        companies: [
            "Aixtron.png",
            "applied-materials.png",
            "Cypress-Logo.png",
            "download.png",
            "Foxconn.png",
            "images.png",
            "asm-technology.png",
            "logo_01.png",
            "BS-blue-oxford.png",
            "maxresdefault.png",
            "SAMCO_Logo-1.png",
            "Tsmc.png",
            // "veeco-instruments-squarelogo-1592229870472.png" 
        ],
        products: [
            "2sd1071s-die-of-chip-wafer.jpg",
            "7218378_orig.jpg",
            "digital-integrated-circuit-chip-500x500.jpg",
            "mocvd.jpg",
            "P-Type-4-Inch-Polished-100mm-Silicon-Wafer-Ssp-DSP.jpg",
            "Wet-Bench-Manufacturer-Semi-Automated-Station-550x318.jpg"
        ]
    },
    photoVoltaic: {
        banner: "photoVoltaicBanner.svg",
        companies: [
            "Adani_Solar.jpg",
            "CETC.jpg",
            "First_Energy.jpg",
            "Hengxing.jpg",
            "HPQ.jpg",
            "LDK_Solar.jpg",
            "Longi.jpg",
            "Meyer_Burger.jpg",
            "QCELLS.jpg",
            "Semilab.jpg",
            "Targray.jpg",
            "Waaree_Logo.jpg"
        ],
        products: [
            "1_polysilicon.jpg",
            "2_Silicon_Ingot.jpg",
            "3_Silicon_wafer.jpg",
            "4_solar_cells.jpg",
            "5_Solar_Panel.jpg",
            "6_Solar_Farm.jpg"
        ]
    },
    UV_EB: {
        banner: "UV_EB_banner.svg",
        companies: [
            "allnex.jpg",
            "Alpha_Cure.jpg",
            "flint_group.jpg",
            "Henkel.jpg",
            "heraeus.jpg",
            "Honle.jpg",
            "miltec_uv.jpg",
            "PCT.jpg",
            "Phoseon.jpg",
            "Sartomer.jpg",
            "Siegwerk.jpg",
            "Toyo.jpg"
        ],
        products: [
            "1_photoinitiator.jpg",
            "2_UV_Coatings.jpg",
            "3_uv_lamps.jpg",
            "4_UV_curing_system.jpg",
            "5_LED_UV_curing_system.jpg",
            "6_Electron_Beam_System.jpg"
        ]
    }
}
export const industryNameMap = { carbonFiber: "Carbon Fiber", additive: "Additive Manufacturing", lithiumIon: "Lithium Ion", opticalFiber: "Optical Fiber", photoVoltaic: "Photo Voltaic", UV_EB: "UV and EB", semiconductor: "Semiconductor" };
export const Testimonials = {
    photoVoltaic: [
        {
            content: "Mappes.io is an excellent platform to connect with all stake holders of the Industry. It virtually replaces all the big events & exhibitions where industry players travel thousands of miles to explore options and connecting with stake holders. Biggest beauty is Mappes.io provides an opportunity to explore options for almost everything starting from suppliers of machines & raw materials to prospective customers all the time, every day on a real time basis.",
            name: "Harsh Pagay",
            details: "CEO, HFCL, India"
        },
        {
            content: "Mappes is a useful platform for connecting the raw material suppliers, end users and applications for certain industries. The platform is also useful for marketing and promoting your products publicly as well as limiting the access to selective people.",
            name: "Danny Sohal",
            details: "Chief Operating Officer | UNIGEL(UK) Limited"
        }, {
            content: "Using Mappes is like Linkedin should be. It helps me build a network of contacts in the same industry whilst at the same time allowing me to access other specific industry networks. Much more focused and less cluttered than other networking programmes.",
            name: "John Davidson",
            details: "President, Carbon fiber Technologies International, United Kingdom"
        }, {
            content: "Mappes.io is a great opportunity for us to connect with stakeholders.Especially, in times where most of the physical shows are disrupted and cancelled this platform helps to create business and to keep in touch with our network.I see a big potential for mappes.io as a main sales platform in various industries.",
            name: "Mark Bauer",
            details: "CEO, NBG Systems, Austria"
        }
    ],
    opticalFiber: [
        {
            content: "Over the years, there has never been a platform that is dedicated to a particular industry. There are market places on the web wherein you can search items and suppliers for general purpose. And now due to recent market changes and the geopolitical conditions, we are looking to revamp our supply chain. This is where Mappes.io comes into the picture. It is a platform specially designed for Optical Fiber Market with a very user-friendly interface. It's a great platform to get the latest product updates and to network with the industry without having to wait for trade-shows.",
            name: "Nikhil Puri",
            details: "Global SCM Head, Sterlite Technologies Limited, India"
        }, {
            content: "Mappes.io is an excellent platform to connect with all stake holders of the Industry.It virtually replaces all the big events & exhibitions where industry players travel thousands of miles to explore options and connecting with stake holders.Biggest beauty is Mappes.io provides an opportunity to explore options for almost everything starting from suppliers of machines & raw materials to prospective customers all the time, every day on a real time basis.",
            name: "Harsh Pagay",
            details: "CEO, HFCL, India"
        }, {
            content: "Mappes is a useful platform for connecting the raw material suppliers, end users and applications for certain industries. The platform is also useful for marketing and promoting your products publicly as well as limiting the access to selective people.",
            name: "Danny Sohal",
            details: "Chief Operating Officer | UNIGEL (UK) Limited"
        }, {
            content: "Mappes.io is a great opportunity for us to connect with stakeholders.Especially, in times where most of the physical shows are disrupted and cancelled this platform helps to create business and to keep in touch with our network.I see a big potential for mappes.io as a main sales platform in various industries.",
            name: "Mark Bauer",
            details: "CEO, NBG Systems, Austria"
        }
    ],
    carbonFiber: [
        {
            content: "Using Mappes is like Linkedin should be.It helps me build a network of contacts in the same industry whilst at the same time allowing me to access other specific industry networks.Much more focused and less cluttered than other networking programmes.",
            name: "John Davidson",
            details: "President, Carbon fiber Technologies International, United Kingdom"
        }
    ],
    UV_EB: [
        {
            content: "Mappes.io is an excellent platform to connect with all stake holders of the Industry.It virtually replaces all the big events & exhibitions where industry players travel thousands of miles to explore options and connecting with stake holders.Biggest beauty is Mappes.io provides an opportunity to explore options for almost everything starting from suppliers of machines & raw materials to prospective customers all the time, every day on a real time basis.",
            name: "Harsh Pagay",
            details: "CEO, HFCL, India"
        }, {
            content: "Mappes is a useful platform for connecting the raw material suppliers, end users and applications for certain industries. The platform is also useful for marketing and promoting your products publicly as well as limiting the access to selective people.",
            name: "Danny Sohal",
            details: "Chief Operating Officer | UNIGEL (UK) Limited"
        }, {
            content: "“Mappes” is a new application tool that enables those in the UV/EB arena to share information and interests with others both within the arena and those that could potentially be interested in UV / EB technology. Networking is frequently identified as the major benefit of trade show and convention participation. “ Mappes” captures that same value through an industry specific digital networking system.",
            name: "David Harbourne",
            details: "President, HCS, LLC, United States"
        }
    ],
    additive: [
        {
            content: "Mappes.io is an excellent platform to connect with all stake holders of the Industry.It virtually replaces all the big events & exhibitions where industry players travel thousands of miles to explore options and connecting with stake holders.Biggest beauty is Mappes.io provides an opportunity to explore options for almost everything starting from suppliers of machines & raw materials to prospective customers all the time, every day on a real time basis.",
            name: "Harsh Pagay",
            details: "CEO, HFCL, India"
        }, {
            content: "Mappes is a useful platform for connecting the raw material suppliers, end users and applications for certain industries. The platform is also useful for marketing and promoting your products publicly as well as limiting the access to selective people.",
            name: "Danny Sohal",
            details: "Chief Operating Officer | UNIGEL (UK) Limited"
        }, {
            content: "Mappes.io is a great opportunity for us to connect with stakeholders.Especially, in times where most of the physical shows are disrupted and cancelled this platform helps to create business and to keep in touch with our network.I see a big potential for mappes.io as a main sales platform in various industries.",
            name: "Mark Bauer",
            details: "CEO, NBG Systems, Austria"
        }
    ],
    lithiumIon: [
        {
            content: "Mappes.io is an excellent platform to connect with all stake holders of the Industry.It virtually replaces all the big events & exhibitions where industry players travel thousands of miles to explore options and connecting with stake holders.Biggest beauty is Mappes.io provides an opportunity to explore options for almost everything starting from suppliers of machines & raw materials to prospective customers all the time, every day on a real time basis.",
            name: "Harsh Pagay",
            details: "CEO, HFCL, India"
        }, {
            content: "Mappes is a useful platform for connecting the raw material suppliers, end users and applications for certain industries. The platform is also useful for marketing and promoting your products publicly as well as limiting the access to selective people.",
            name: "Danny Sohal",
            details: "Chief Operating Officer | UNIGEL (UK) Limited"
        }
    ],
    semiconductor: [
        {
            content: "Mappes.io is an excellent platform to connect with all stake holders of the Industry.It virtually replaces all the big events & exhibitions where industry players travel thousands of miles to explore options and connecting with stake holders.Biggest beauty is Mappes.io provides an opportunity to explore options for almost everything starting from suppliers of machines & raw materials to prospective customers all the time, every day on a real time basis.",
            name: "Harsh Pagay",
            details: "CEO, HFCL, India"
        }, {
            content: "Mappes is a useful platform for connecting the raw material suppliers, end users and applications for certain industries. The platform is also useful for marketing and promoting your products publicly as well as limiting the access to selective people.",
            name: "Danny Sohal",
            details: "Chief Operating Officer | UNIGEL (UK) Limited"
        }, {
            content: "“Mappes” is a new application tool that enables those in the UV/EB arena to share information and interests with others both within the arena and those that could potentially be interested in UV / EB technology. Networking is frequently identified as the major benefit of trade show and convention participation. “ Mappes” captures that same value through an industry specific digital networking system.",
            name: "David Harbourne",
            details: "President, HCS, LLC, United States"
        }
    ],
}
export const suggestedProducts = {
    opticalFiber: [
        { name: 'FRP RODS', id: 'BknEWE31Riz' },
        { name: 'Optical Fiber', id: 'SkW9l-VnJRsG' },
        { name: 'Optical fiber Cable', id: 'rJEFl-Vn1Ajz' },
        { name: 'Aramid Yarn', id: 'ryTIWNny0iG' },
        { name: 'Sz Stranding Line', id: 'SJDgxZEhyAiG' },
    ],
    UV_EB: [
        { name: 'UV Curing system', id: 'HJpXxbE2yRjG' },
        { name: 'Electron Beam System', id: 'SyjnZE3JCof' },
        { name: 'UV Ink', id: 'SJwzbEnkCjM' },
        { name: 'Photoinitiator', id: 'HyW0GZNn1Aoz' },
        { name: 'UV Lamps', id: 'SJQHmVLGWv6wm' },
        { name: 'UV LED System', id: 'SygQlWE31Rjf' }
    ],
    carbonFiber: [
        { name: 'Carbon Fibers', id: 'S1aD-N21Ciz' },
        { name: 'Carbon fiber production line', id: 'BJ96VDqNH' },
        { name: 'PAN Precursor', id: 'S1kDbV3kAiG' },
        { name: 'Carbon Fiber Prepregs', id: 'HkiwZ4210jM' }
    ],
    photoVoltaic: [
        { name: 'Solar Photovoltaic Module', id: 'ByPXlbNh10oG' },
        { name: 'Solar Cells', id: 'ryh4YXLfWP6DX' },
        { name: 'Solar Wafer', id: 'HyDTwvfum' },
        { name: 'Polysilicon', id: 'B1FG07L9m' },
        { name: 'Silicon Ingot', id: 'BJPY6QUq7' }
    ],
    additive: [
        { name: '3D Metal Printers', id: 'rkqFtIfcL' },
        { name: 'Rapid Prototype', id: 'HyeRSYosU' },
        { name: 'Support Removal System', id: 'B1u8_FssU' },
        { name: 'Patient Specific Implants (PSI)', id: 'r1vow2m9I' }
    ],
    lithiumIon: [
        { name: 'Lithium-ion battery Cell', id: 'SyG_WFgFX' },
        { name: 'Lithium-ion battery packs', id: 'B14sTOSrr' },
        { name: 'Cylindrical Cell Production Line', id: 'HydvEd2xL' },
        { name: 'Solar Battery', id: 'HyXzpRWKX' }
    ],
    semiconductor: [
        { name: 'Integrated Circuit (ic Or Chip)', id: 'rkdtOZ8MZPavm' },
        { name: 'Wet Bench', id: 'H1r2HcoKw' },
        { name: 'Die Bonder', id: 'S1BeB9qYD' },
        { name: 'Atomic Layer Deposition (ALD) System', id: 'ryk0AF5Kw' },
        { name: 'Ion Implanter', id: 'S1WMR6t5m' },
        { name: 'Mask Aligner', id: 'BkNZx-EnyCjz' },
        { name: 'Metal Organic Chemical Vapor Deposition (MOCVD) system', id: 'SyGZwVDwr' }
    ]
}