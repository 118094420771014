import React, { Component } from 'react';
import { Button, Header, Dropdown, Modal, Loader } from 'semantic-ui-react';
import { ReactMultiEmail } from "react-multi-email";
import { SvgIcon } from '../../common';
import "react-multi-email/style.css";
import './invite.scss';
import Email from './Email';
import { sendInvite } from './api';
import { emailRegex } from '../../../common/constants';

interface IProps {}
interface IState {
  emails: string[];
}

class InviteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputsCount: 1,
            inviteStatus : '',
            modalOpen: false,
            emails: []
        };
        
        
    }
    handleAddMoreClick = () => {
        this.setState({ inputsCount: this.state.inputsCount + 1 })
    }

    handleOpen = () => {
        if (!this.props.auth) {
            this.props.showLoginModal(true);
        } else {
            this.setState({ modalOpen: true })
        }
    }
    handleClose = () => {
        this.setState({ modalOpen: false })
    } 
    
    sendInvite = () => {
       
        if (this.state.emails.length > 0){
            for(let email of this.state.emails){
                console.log('email  ', email  )
                if (!emailRegex.test(email.trim())) {
                    this.setState({ invalidEmail: true });
                }
                else {
                    this.setState({ invalidEmail: false, inviteStatus: "IN_PROGRESS" });
                    sendInvite(email, this.props.type, this.props.entityId)
                        .then(() => {
                            this.setState({ invalidEmail: false, inviteStatus: "SENT" });
                            setTimeout(() => {
                                this.handleClose();
                                this.setState({ invalidEmail: false, inviteStatus: "", emails : [] });
                            }, 1500);
                        })
                        .catch(() => {
                            this.setState({ invalidEmail: false, inviteStatus: "ERROR" });
                        });
                }
            }
        }
        
    }

    render() {
        const { emails, inviteStatus, invalidEmail } = this.state;
        return (
            <Modal
                open={this.state.modalOpen}
                closeIcon={< SvgIcon className="modal-close" name="close" viewbox="0 0 23.126 23.126" />}
                onClose={this.handleClose}
                trigger={<Dropdown.Item onClick={this.handleOpen} icon={<SvgIcon name="invitemore-icon" viewbox='0 0 15.059 16.6' />} text='Invite more' />}
                className="invite-modal"
            >
                <Modal.Header>Invite to Mappes</Modal.Header>
                <Modal.Content image>
                    <div className="row px-1">
                        <div className="col-sm-12 invite-header mb-2">
                            <h1> Invite your colleagues and peers who would <br /> be interested in this {this.props.type}</h1>
                        </div>
                        <div className="col-sm-12 mt-3">
                            <div className='inputemail-row'>
                                <ReactMultiEmail
                                    placeholder="name@email.com. Use comma (,) to separated"
                                    emails={emails}
                                    onChange={(_emails: string[]) => {
                                        this.setState({ emails: _emails });
                                    }}
                                    getLabel={(
                                        email: string,
                                        index: number,
                                        removeEmail: (index: number) => void
                                    ) => {
                                        return (
                                        <div data-tag key={index}>
                                            {email}
                                            <span data-tag-handle onClick={() => removeEmail(index)}>
                                            ×
                                            </span>
                                        </div>
                                        );
                                    }}
                                    />
                                <Button primary className="custom-btn text-inherit ml-2 px-4" onClick={this.sendInvite} disabled={inviteStatus === "SENT"}>
                                    Send invite
                                </Button>
                                <div className="message">
                                    {
                                        invalidEmail && <h3>Invalid email id</h3>
                                    }
                                    {
                                        inviteStatus === "IN_PROGRESS" && <Loader inline active />
                                    }
                                    {
                                        inviteStatus === "SENT" && <> <i className="icon-checked"></i> <b> Invitation Sent Successfully</b></>
                                    }
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </Modal.Content>
               
            </Modal>
        );
    }
}

export default InviteModal;