import React from 'react';
import { List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import AvatarSmall from '../../common/AvatarSmall';
import User from './user';
import { replaceSpaceInName } from '../../../common/helperMethods';

export default (notification, index) => (
    <List.Item key={index}>
        <AvatarSmall id={notification.actor_id} name={notification.actor_name} />
        <List.Content>
            <List.Description>
                Product <Link to={`/product/${replaceSpaceInName(notification.product_name)}/${notification.product_id}`}>{notification.product_name}</Link> added to company{' '}
                <Link to={`/company/${replaceSpaceInName(notification.company_name)}/${notification.company_id}`}>{notification.company_name}</Link>
                {/* <a className='color-light-blue'>Lorem</a> ipsum dolor <a className='color-light-blue'>sit</a> amet */}
            </List.Description>
        </List.Content>
    </List.Item>
);
