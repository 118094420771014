import React from 'react';
import { SvgIcon } from '../common';
import { Link } from 'react-router-dom';
import FollowButton from './FollowButton';
import EditProduct from './EditProduct';
import { Button, Dropdown, Popup } from 'semantic-ui-react';
import { BottomPopup } from '../common/layout/navbar/BottomPopup';
import { apiStatus } from '../../common/constants';
import CroppingModal from '../common/fileUpload/CroppingModal';
import InviteModal from '../common/invite/inviteModal';
import SharePost from '../networkFeed/SharePost';

import * as actions from '../../actions/';
import { commonActions } from '../../actions'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShowMoreLess from '../networkFeed/showMoreLess';

class ProductInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            productImage: '',
            open: false,
            uploadModal: false,
            sharePostPopup : false,
        }
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.saveStatus !== this.props.saveStatus) && this.props.saveStatus === apiStatus.COMPLETED) {
            this.setState({ open: false, uploadModal: false })
        }
    }

    handleImageClick = (src) => {
        this.setState({ productImage: src })
    }

    setOpen = (open) => this.setState({ open })

    handleRequestQuote = (data) => {
        const { id, submitInquiry } = this.props;
        submitInquiry({
            ...data,
            product_id: id
        })
    }

    handleUploadImage = (uploadModal) => {
        if (this.props.auth) {
            this.setState({ uploadModal })
        }
    }

    handleSubmit = (formData) => {
        const { id, uploadProductImage } = this.props;
        formData.append("product_id", id);
        uploadProductImage(formData);
    }
    
    toggleshareProductPopup = () => {
        const { auth, history, showLoginModal } = this.props;
        if (!auth) {
            showLoginModal(true);
        } else {
            this.setState({ sharePostPopup: !this.state.sharePostPopup })
        }

    }
    hideShareProduct = () => {
        this.setState({ sharePostPopup: false })
    }
    
    handleRFQ = () => {
        const { info } = this.props.product;
        this.props.history.push(`/request-quote/product/` + info.Item.id);
    }
     
    check_mappes_user = () => {
        const { auth } = this.props;
        return auth.email.includes('@mappes.io') 
    }

    render() {
        const { id, auth, saveStatus } = this.props;
        let productName, description, crop_images = [];
        const { info, followers, images } = this.props.product;
        if (info != undefined && info.Item != undefined ) {
            description = (info.Item!= undefined ) ? info.Item.description : '';
            productName = (info.Item!= undefined ) ? info.Item.name : '';
            crop_images = (info.Item!= undefined ) ? info.Item.crop_images && [...info.Item.crop_images] : '';
        } else {
            if (info) {
                description = (info != undefined) ? info.description : '';
                productName = (info != undefined) ? info.name : '';
                crop_images = (info != undefined) ? info.crop_images && [...info.crop_images] : '';
            }
        }
        const userName = auth && auth.displayName;
        const productImageMain = this.state.productImage || crop_images && crop_images.length && crop_images[0].value;


        return (
            <div className="product-upper">
                <div className="product-upper-inner">
                    <div className="productupper-left text-center">
                        <div className="productimage-main">
                            {productImageMain ?
                                <img alt={productName} title={productName} src={productImageMain} />
                                : <div className="no-image-avlbl">
                                    <SvgIcon name='upload-image' viewbox='0 0 79 70' onClick={() => this.handleUploadImage(true)} />
                                </div>}
                        </div>
                        <div className="productimage-thumbs mb-2">
                            <ul>
                                {crop_images
                                    && crop_images.map(image =>
                                        <li>
                                            <img alt={productName} title={productName} src={image.value} onClick={() => this.handleImageClick(image.value)} />
                                        </li>
                                    )
                                }
                                {auth
                                    && <li title='Add a display picture for this technology' id="product-image-upload" onClick={() => this.handleUploadImage(true)}>
                                        <SvgIcon name="plus" viewbox="0 0 23 23" />
                                    </li>
                                }
                            </ul>
                        </div>
                        {/* <div className="text-left">
                            <Link to={`/product/${id}`} className="viewll-media-link">View all photos/ media</Link>
                        </div> */}
                    </div>
                    <div className="productupper-right">
                        <div className="namerow">
                            <div>
                                <h1 alt={productName} title={productName}>{productName}</h1>
                                <ul className="connection-subhead">
                                    <li>
                                        <Link id="product-followers" to={`/product/${productName}/${id}/followers`}>
                                            <span>{followers && followers.count}</span> Followers
                                        </Link>
                                    </li>
                                    {/* <li><span>14</span> Connections in your network</li> */}
                                    {/* <li className="followuser">
                                         <Link to={`${id}/invite`}>
                                            <i className="user icon" /> Invite More
                                        </Link> 
                                        <InviteModal />
                                         <InviteModal /> 
                                    </li> */}
                                </ul>
                            </div>
                            <div className='d-flex align-items-start'>
                                <Dropdown className="product-topright-action" floating="right" text={<div className='menu-icon m-0'><SvgIcon name="ellipsis-vertical" viewbox='0 0 10.125 38.875' /></div>}>
                                    <Dropdown.Menu>
                                        <InviteModal type='followProduct' entityId={id} auth={this.props.auth} showLoginModal={this.props.showLoginModal}  />
                                       
                                        <Popup
                                            basic
                                            trigger={<Dropdown.Item onClick={this.toggleshareProductPopup} icon={<SvgIcon name="share" viewbox='-21 0 512 512' />} text='Share product' />}
                                            on="click"
                                            flowing
                                            className="sharepost-popup-wrapper"
                                            open={this.state.sharePostPopup}
                                            hideOnScroll={true}
                                            position='top right'
                                            content={
                                                <SharePost
                                                    type='product'
                                                    postId={id}
                                                    postTitle={productName}
                                                    closePopup={this.toggleshareProductPopup}
                                                    hideShareProduct={this.hideShareProduct}
                                                    userName={userName} />
                                            }
                                        />
                                        {/* <Dropdown.Item icon={<SvgIcon name="edit" viewbox='0 0 31.5 31.5' />} text='Edit info' /> */}
                                        
                                        {auth && this.check_mappes_user() &&
                                            <EditProduct id={id} description={description} />
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="actionbtns d-flex justify-content-start">
                            <FollowButton id={id} name={productName} type="product" />
                            <Button id="product-request-quote" primary size="small" onClick={() => this.handleRFQ()}>Request Quote</Button>  {/* onClick={() => this.setOpen(true)} */}
                        </div>
                        <div className="detail-text">
                            <p>
                                <ShowMoreLess content={description}  ></ShowMoreLess>
                            </p>
                            {/* <p>{description}</p> */}
                            {/*   {auth && this.check_mappes_user() &&
                                <EditProduct id={id} description={description} />
                            } */}
                        </div>
                        <div className="text-right mt-auto mb-2 details-view">
                            {/* <SvgIcon name="eye" viewbox="0 0 35.001 19.028" /> <span className="text-secondary">139</span> */}
                            {/* <Button primary basic size="small" onClick={() => this.setOpen(true)}>Request Quote</Button> */}
                            {/* <Button primary basic size="small" onClick={() => this.handleRFQ()}>Request Quote</Button> */}
                        </div>
                    </div>
                </div>
                 {/* <BottomPopup open={this.state.open} setOpen={this.setOpen} userDetails={auth} handleRequestQuote={this.handleRequestQuote} />  */}
                {/* <Button primary size="small" onClick={() => this.handleRFQ()}>Request Quote</Button> {/* onClick={() => this.setOpen(true)} */} 
                <CroppingModal
                    open={this.state.uploadModal}
                    setOpen={() => this.handleUploadImage(false)}
                    handleSubmit={this.handleSubmit}
                    dropBoxText={
                        <div>
                            <div className='upload-circle'>
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none">
                                    <path d="M57.5 47.5C57.5 48.8261 56.9732 50.0979 56.0355 51.0355C55.0978 51.9732 53.8261 52.5 52.5 52.5H7.5C6.17392 52.5 4.90215 51.9732 3.96447 51.0355C3.02678 50.0979 2.5 48.8261 2.5 47.5V20C2.5 18.6739 3.02678 17.4021 3.96447 16.4645C4.90215 15.5268 6.17392 15 7.5 15H17.5L22.5 7.5H37.5L42.5 15H52.5C53.8261 15 55.0978 15.5268 56.0355 16.4645C56.9732 17.4021 57.5 18.6739 57.5 20V47.5Z" fill="#CBD7E3"/>
                                    <path d="M30 42.5C35.5228 42.5 40 38.0228 40 32.5C40 26.9772 35.5228 22.5 30 22.5C24.4772 22.5 20 26.9772 20 32.5C20 38.0228 24.4772 42.5 30 42.5Z" stroke="#E9EEF3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <h4>Drag and drop here to upload image</h4>
                            <i>Note: Only upload .jpeg and .png format files</i>
                            <div className='or-div'>
                                - or -
                            </div>
                            <div className='text-center mt-3'>
                                <Button primary basic>Upload from computer</Button>
                            </div>
                        </div>
                    }
                    modalHeaderText='Select a Photo'
                    disableSave={saveStatus === apiStatus.IN_PROGRESS}
                />
            </div>
        )
    }
}

function mapStateToProps({ auth,  common }) {
    return { auth,  common };
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators({ ...actions, ...commonActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductInfo);