export const networkFeedMessages={
    'user': 'Post which you like, share, comment or post, will show up here.',
    'product': 'There is no feed here, be the first one to share knowledge about this product.',
    'global': 'Please follow the product, technology or company page to get latest udpates about them',
    'company': 'There is no feed here, be the first one to share knowledge about this company.'
}
export const suggestedProducts = {
    opticalFiber: [
        { name: 'FRP RODS', id: 'BknEWE31Riz' },
        { name: 'Optical Fiber', id: 'SkW9l-VnJRsG' },
        { name: 'Optical fiber Cable', id: 'rJEFl-Vn1Ajz' },
        { name: 'Aramid Yarn', id: 'ryTIWNny0iG' },
        { name: 'Sz Stranding Line', id: 'SJDgxZEhyAiG' },
    ],
    UV: [
        { name: 'UV Curing system', id: 'HJpXxbE2yRjG' },
        { name: 'Electron Beam System', id: 'SyjnZE3JCof' },
        { name: 'UV Ink', id: 'SJwzbEnkCjM' },
        { name: 'Photoinitiator', id: 'HyW0GZNn1Aoz' },
        { name: 'UV Lamps', id: 'SJQHmVLGWv6wm' },
        { name: 'UV LED System', id: 'SygQlWE31Rjf' }
    ],
    carbonFiber: [
        { name: 'Carbon Fibers', id: 'S1aD-N21Ciz' },
        { name: 'Carbon fiber production line', id: 'BJ96VDqNH' },
        { name: 'PAN Precursor', id: 'S1kDbV3kAiG' },
        { name: 'Carbon Fiber Prepregs', id: 'HkiwZ4210jM' }
    ],
    photoVoltaic: [
        { name: 'Solar Photovoltaic Module', id: 'ByPXlbNh10oG' },
        { name: 'Solar Cells', id: 'ryh4YXLfWP6DX' },
        { name: 'Solar Wafer', id: 'HyDTwvfum' },
        { name: 'Polysilicon', id: 'B1FG07L9m' },
        { name: 'Silicon Ingot', id: 'BJPY6QUq7' }
    ],
    additiveManufacturing: [
        { name: '3D Metal Printers', id: 'rkqFtIfcL' },
        { name: 'Rapid Prototype', id: 'HyeRSYosU' },
        { name: 'Support Removal System', id: 'B1u8_FssU' },
        { name: 'Patient Specific Implants (PSI)', id: 'r1vow2m9I' }
    ],
    lithiumIon: [
        { name: 'Lithium-ion battery Cell', id: 'SyG_WFgFX' },
        { name: 'Lithium-ion battery packs', id: 'B14sTOSrr' },
        { name: 'Cylindrical Cell Production Line', id: 'HydvEd2xL' },
        { name: 'Solar Battery', id: 'HyXzpRWKX' }
    ],
    semiconductor: [
        { name: 'Integrated Circuit (ic Or Chip)', id: 'rkdtOZ8MZPavm' },
        { name: 'Wet Bench', id: 'H1r2HcoKw' },
        { name: 'Die Bonder', id: 'S1BeB9qYD' },
        { name: 'Atomic Layer Deposition (ALD) System', id: 'ryk0AF5Kw' },
        { name: 'Ion Implanter', id: 'S1WMR6t5m' },
        { name: 'Mask Aligner', id: 'BkNZx-EnyCjz' },
        { name: 'Metal Organic Chemical Vapor Deposition (MOCVD) system', id: 'SyGZwVDwr' }
    ],
    Greenhydrogen : []
}
export const suggestedCompanies = {
    opticalFiber: [
        { name: 'Sterlite Technologies', id: 'Hyjhml_sy0oz' },
        { name: 'NBG systems', id: 'ry-Dzgdo1RiM' },
        { name: 'HFCL', id: 'HkkQ-gdsJAiz' },
        { name: 'Farbwerke Herkula Sa/Ag', id: 'rJldxeOo10iG' },
        { name: 'Prysmian Group', id: 'Bk6J7ldo1CsG' },
    ],
    UV: [
        { name: 'Heraeus Noblelight America', id: 'By7zZlOs10jG' },
        { name: 'Miltec', id: 'BkaLbJuEL' },
        { name: 'Toyo Arets', id: 'BJL4zj4Y0ym' },
        { name: 'PCT Engineered Systems', id: 'HJooItS-P' }
    ],
    carbonFiber: [
        { name: 'Taekwang Industrial Co., Ltd', id: 'H1SLifC7Q' },
        { name: 'Harper International', id: 'SySaOAnfX' },
        { name: 'SIMCO Materials International', id: 'SkeimlOo10if' },
        { name: 'Hindoostan Mills Limited', id: 'S1nrZ1pGX' }
    ],
    photoVoltaic: [
        { name: 'Henan Hengxing Science & Technology', id: 'r1DWWldj1AiG' },
        { name: 'Cetc Electronics Equipment Group', id: 'HyWjZQT_H1tm' },
        { name: 'Mundra Solar Pv Limited', id: 'ByXVzEauBkt' }
    ],
    additiveManufacturing: [
        { name: 'Incredible AM Pvt Ltd', id: 'HJj6rp7qU' },
        { name: 'ERMAKSAN', id: 'BkNpxOz58' },
        { name: 'PostProcess Technologies', id: 'ryCBvKooI' }
    ],
    lithiumIon: [
        { name: 'Techland', id: 'SyrJrdheU' },
        { name: 'Exide Industries Limited', id: 'HJCPzV6_SkKX' },
        { name: 'Amara Raja Batteries', id: 'HkZoxkcTPaDQ' }
    ]
}
export const industryName = {
    opticalFiber: "Optical Fiber",
    UV: "UV",
    carbonFiber: "Carbon Fiber",
    photoVoltaic: "Photo Voltaic",
    additiveManufacturing: "Additive  Manufacturing",
    lithiumIon: "Lithium Ion"
}