import React, { Component } from 'react';
import { SvgIcon } from '../../common';
import InviteUser from '../InviteUser';
import './getting-started.scss';

class GettingStarted extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <div className="gettingstarted-section py-80 mt-4">
                    <div className="container">
                        <div className="row align-items-center gettingstarted-row">
                            <div className="col-sm-7 common-heading text-left">
                                <b className='h2-bold'>Getting started is easy</b>
                                <p className="text-1">
                                    Join the manufacturing community that relates to your product’s value chain.
                                </p>
                                <div className="mt-5">
                                    {/* <Link to='/signup'>
                                    <Button primary className="btn-lg mr-4 custom-btn">CREATE A FREE ACCOUNT</Button>
                                    </Link>
                                    <Link to='/pricing'>
                                        <Button basic primary className="btn-lg custom-btn">SEE PRICING</Button>
                                    </Link> */}
                                    <InviteUser />
                                </div>
                            </div>
                            <div className="col-sm-5 connect-word">
                                <SvgIcon name="connected-world" viewbox="0 0 433.948 372.829" />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default GettingStarted
