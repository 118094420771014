import React, { Component } from 'react';
import { Button, Card, Image } from 'semantic-ui-react'
import { TestimonialCard, SvgIcon } from '../common';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Industry.scss';

import Navbar from '../common/layout/navbar/Navbar';
import Footer from '../common/layout/footer/footer';
import { Link } from 'react-router-dom';
// import { Testimonials } from './Testimonials';
import { industryImageMap, industryNameMap, Testimonials, suggestedProducts } from './types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { eventActions } from '../../actions';
import { formatIsoMilisecondsWithoutTime } from '../../common/helperMethods';
import InviteUser from '../common/InviteUser';

class Industry extends Component {
    componentDidMount() {
        const { auth, history, getEventsListForIndustry, match } = this.props;
        if (auth) {
            history.push('/networkFeed/global')
        }
        else {
            this.getEvents();
            const industry = match.params.id;
            const productIds = suggestedProducts[industry].map(item => item.id);
            getEventsListForIndustry(productIds);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.getEvents();
        }
    }

    getEvents = () => {
        const industry = this.props.match.params.id;
        const productIds = suggestedProducts[industry].map(item => item.id);
        this.props.getEventsListForIndustry(productIds);
    }

    render() {
        const industry = this.props.match.params.id;
        const settings = {
            dots: true,
            infinite: true,
            className: "center",
            centerPadding: "120px",
            centerMode: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        centerPadding: "40px",
                        centerMode: true
                    }
                }
            ]
        };

        const industryName = industryNameMap[industry];
        const companyLogos = industryImageMap[industry].companies;
        const productImages = industryImageMap[industry].products;
        const banner = industryImageMap[industry].banner;

        let eventStartDate, eventStartDay, eventStartMonth, eventEndDate;
        const upcomingEventsData = this.props.events.industryEventsList && this.props.events.industryEventsList.map((event, index) => {
            eventStartDate = formatIsoMilisecondsWithoutTime(+event.startDate);
            eventStartDay = eventStartDate.split(',')[0].split(' ')[1];
            eventStartMonth = eventStartDate.split(',')[0].split(' ')[0];

            eventEndDate = formatIsoMilisecondsWithoutTime(+event.endDate);
            return {
                key: index,
                image: event.image,
                name: event.name,
                date: `${eventStartDate} - ${eventEndDate}`,
                address: event.location,
            }
        })

        return (
            <Navbar {...this.props}>
                <div className="home-main">
                    <div className="product-banner-section">
                        <div className="container">
                            <div className="row align-items-center banner-row">
                                <div className="col-sm-6">
                                    <h1>{industryName} Ecosystem</h1>
                                    <p className="text-1 mt-4">Join the {industryName} Community and discover new applications, customers and suppliers of machinery and raw materials in the {industryName} value chain.</p>
                                    <div className="mt-5 button-section">
                                        {/* <Link to='/signup'>
                                            <Button primary className="btn-lg mr-4 custom-btn">CREATE A FREE ACCOUNT</Button>
                                        </Link>
                                        <Link to='/pricing'>
                                            <Button basic primary className="btn-lg custom-btn">SEE PRICING</Button>
                                        </Link> */}
                                        {/* <div className="ui action input invitation-action"> */}
                                            <InviteUser />
                                            {/* <input type="text" placeholder="Email Address" />
                                            <Button primary className="custom-btn text-inherit">Get an invitation</Button> */}
                                        {/* </div> */}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <img alt="product-banner" className="product-banner" src={`/images/${industry}/${banner}`} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="common-section aboutproduct-section industry-about">
                        <div className="container-common">
                            <div className="row aboutsection-row">
                                <div className="col-md-6 products-images">
                                    <ul>
                                        {productImages.map((productImage, index) => (
                                            <li key={index}>
                                                <img alt="product" src={`/images/${industry}/products/${productImage}`} />
                                            </li>
                                        )
                                        )}
                                    </ul>
                                </div>
                                <div className="col-md-6 products-text">
                                    <h2>Explore products in the value chain</h2>
                                    <p>
                                        Join and follow products related to {industryName} to receive product updates directly into your inbox. Get notified about new suppliers, customers, raw materials and applications related to your products in the {industryName} value chain. Share articles, videos, case studies related to your products to engage your customers and get notified when the latest product and industry information is shared by other product experts from the industry.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="common-section py-80">
                        <div className="container common-heading text-center text-small-left">
                            <div className="row">
                                <div className="col-lg-9 col-md-12 m-auto">
                                    <h2>Companies in the ecosystem</h2>
                                    <p className="text-1 mt-4">
                                        Explore the product and services provided by the companies in this network. Network with industry colleagues and educate the industry about your technology and applications.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 companylogos">
                                    <ul>
                                        {companyLogos.map((companyLogo, index) => (
                                            <li key={index}>
                                                <img alt="company-logo" src={`/images/${industry}/companies/${companyLogo}`} />
                                            </li>
                                        ))
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-sm-12 text-center">
                                    <Link to="/otp-login">
                                        <Button primary className="btn-sm custom-btn">JOIN NOW</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="testimonial-section py-50">
                        <div className="testimonial-wrapper">
                            <div className="row">
                                <div className="col-sm-12">
                                    <Slider {...settings}>
                                        {
                                            Testimonials[industry].map(item =>
                                                <TestimonialCard
                                                    content={item.content}
                                                    name={item.name}
                                                    details={item.details}
                                                />
                                            )}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="common-section py-50 upcoming-section">
                        <div className="container common-heading text-left">
                            <div className="row">
                                <div className="col-sm-9">
                                    <h2>Upcoming Events</h2>
                                    <p className="text-1">
                                        Explore the exhibitors and attendees who plan to be part of the upcoming trade-shows related to your industry. Explore and understand their products ahead of your in-person meeting during the show.
                                    </p>
                                </div>
                                <div className="col-sm-3 text-right pt-4">
                                    <Link to="/otp-login">
                                        <Button secondary className="btn-lg explore-btn custom-btn">EXPLORE NOW</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 upcoming-events">
                                    <Card.Group itemsPerRow={3}>
                                        {upcomingEventsData && upcomingEventsData.slice(0, 3).map(upcomingEvents => (
                                            <Card key={upcomingEvents.id}>
                                                <Image className="eventcardmain" src={upcomingEvents.image} wrapped ui={false} />
                                                <Card.Content>
                                                    <Card.Header>{upcomingEvents.name}</Card.Header>
                                                    <Card.Description>
                                                        <div>
                                                            {upcomingEvents.date}
                                                        </div>
                                                        <div>
                                                            {upcomingEvents.address}
                                                        </div>
                                                    </Card.Description>
                                                    <Link to="/otp-login">EXPLORE</Link>
                                                </Card.Content>
                                            </Card>
                                        ))}
                                    </Card.Group>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="gettingstarted-section py-80 mt-4">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-sm-7 common-heading text-left">
                                    <b className='h2-bold'>Getting started is easy</b>
                                    <p className="text-1">
                                        Join the manufacturing community that relates to your product’s value chain.
                                    </p>
                                    <div className="mt-5">
                                        <InviteUser />
                                        {/* <Link to='/signup'>
                                            <Button primary className="btn-lg mr-4 custom-btn">CREATE A FREE ACCOUNT</Button>
                                        </Link>
                                        <Link to='/pricing'>
                                            <Button basic primary className="btn-lg custom-btn">SEE PRICING</Button>
                                        </Link> */}
                                    </div>
                                </div>
                                <div className="col-sm-5 connect-word">
                                    <SvgIcon name="connected-world" viewbox="0 0 433.948 372.829" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Navbar>
        );
    }
}

const mapStateToProps = ({ auth, events }) => {
    return { auth, events };
}
const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ ...eventActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Industry);