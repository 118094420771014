import React from 'react';
import { Comment } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { SvgIcon } from '../../../common';

const CommentActions = (props) => {
    return (
        <Comment.Actions>
            <Comment.Action></Comment.Action>
            {/* <Comment.Action>Like</Comment.Action> */}
            <Comment.Action></Comment.Action>
            {/* <Comment.Action>Reply</Comment.Action> */}
            <Comment.Metadata>
                <div>{props.commentDate}</div>
            </Comment.Metadata>
        </Comment.Actions>
    )
};

const CommentContent = (props) => {
    const { comment, userInfo } = props;
    const commentDate = new Date(comment.added).toLocaleString(undefined, { day: "numeric", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit" });
    return (
        <div className='comment-rightdtl'>
            <Link className='commenter-name' to={`/networkFeed/user/${userInfo.id}`}> {userInfo.displayName}</Link>
            <div className='dasignation'>
                {userInfo.designation} | {userInfo.company}
            </div>
            <div className='comment-datetime'>{commentDate}</div>
            <div className='comment-text'>{comment.text}</div>
            <div className='comment-likes'>
                <a id="post-like" > {/* className={`${isPostLiked ? "active" : ""}`}  */}
                    {/* Like <SvgIcon name='thumb-unfilled' viewbox='0 0 20 20' /> <span className='counts'>1</span> */}
                </a>
            </div>
        </div>
    );
}
class IndividualComment extends React.Component {

    render() {
        const userInfo = this.props.getUserInfo(this.props.comment.user_id);
        const { comment } = this.props;
        const image = userInfo ? userInfo.image : '';
        const userName = userInfo.displayName ? userInfo.displayName : '';
        var userInitials = userName != '' && userName != null ? userName.split(" ").map((n) => n[0]).join("") : ''; // loggedInUserInfo.displayName.substring(0, 1)
        return (
            <Comment className="commented-list">
                {
                    image ?
                        <Comment.Avatar src={image} />
                        :
                        <div className="comment-initials-img">{userInitials} </div>
                }
                <CommentContent comment={comment} userInfo={userInfo} />
                {/* {
                                comment.replies.length > 0 &&
                                <Comment.Group>
                                    {
                                        comment.replies.map(reply =>
                                            <React.Fragment>
                                                <Comment>
                                                    <Comment.Avatar src={reply.avatarUrl} />
                                                    <CommentContent comment={reply} />
                                                </Comment>
                                            </React.Fragment>
                                        )
                                    }
                                </Comment.Group>
                            } */}
            </Comment>
        )
    }
}

export default IndividualComment;