import mappesIcon from '../../../assets/images/mappes-icon.png';
import React, { useState } from 'react';
import { Button, Input, Dimmer, Loader } from 'semantic-ui-react';
import './index.scss'
import { Message } from 'semantic-ui-react';
import axios from 'axios';
import { BASE_URL } from '../../../config/config';
import { SvgIcon } from '../../common';

export const Password = ({ handlePasswordChange, email, password, navigateToEmailScreen, formSubmitLoading, navigateToOTPScreen, error , setError}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [showPassword, setShowPassword] = useState(false);

    const handleForgotPassword = () => {
        setError('')
        setIsLoading(true)
        axios.post(`${BASE_URL}/api/v1/user/password/forgot`, { email, }, { withCredentials: false })
            .then(() => {
                setIsLoading(false)
                setMessage('Please click on the link that has just been sent to your email account to reset your password.')
            })
            .catch((err) => {
                setIsLoading(false)
                if (err.response && err.response.data && err.response.data.error) {
                    setMessage(err.response.data.error)
                }
            });
    };

    const handleMouseDownPassword = (event) => event.preventDefault()

    return (
        <div className='lwo-card-inner'>
            <Dimmer active={formSubmitLoading || isLoading}>
                <Loader indeterminate>Please Wait.!</Loader>
            </Dimmer>
            <Button type="button" onClick={navigateToEmailScreen} className='back-btn'><SvgIcon name='chevron-left' viewbox="0 0 8 14" /> Back</Button>
            <div className='upper-head mt-4 pt-2'>
                <h1>Log in with Password</h1>
                <p className='text-center mb-0'>Please enter password for your registered email</p>
                <p className='sentemail'><span>{email}</span> <button type='button' onClick={navigateToEmailScreen}>Edit</button></p>
            </div>
            <div className='form-item mt-3'>
                <label>Enter password</label>
                <div className='password-form-item'>
                    <Input
                        type={showPassword ? "text" : "password"}
                        onChange={handlePasswordChange}
                        value={password}
                        placeholder='**********'
                        id="password"
                    />
                    <div
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={handleMouseDownPassword}
                    >
                        {showPassword ? <SvgIcon name='password-show' viewbox="0 0 17 12" /> : <SvgIcon name='password-hide' viewbox="0 0 16 14" />}
                    </div>
                </div>
            </div>
            <div className='resend-row'>
                <p>Forgot Password?</p>
                <p className='resend-otp'><button type='button' onClick={handleForgotPassword}>Click here</button></p>
            </div>
            {message
                && <Message info>
                    {message}
                </Message>
            }
            {error
                && <Message error>
                    {error}
                </Message>
            }
            <Button className='submit-btn' primary type="submit">
                Continue
            </Button>
            <div className='bottom-action'>
                <Button onClick={navigateToOTPScreen}>Log in with OTP</Button>
            </div>
        </div>
    )
}