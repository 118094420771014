import React, { Component } from 'react';
import './profileFeed.css';
import Layout from '../common/layout/Layout';
import NetworkFeed from './NetworkFeed';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';
import SEO from '../seo/SEO';

class NetworkFeedLayout extends Component {
    feedType;
    entityId;

    constructor(props) {
        super(props);
        this.state = {};
        this.scrollRef = React.createRef();
    }

    componentDidMount() {
        window.scrollTo({ left: 0, top: 0 });
        // this.setFeedTypeAndEntityId();
    }

    componentDidUpdate(prevProps) {
        // this.setFeedTypeAndEntityId();
        // let { feedType, entityId } = this.props.match.params;

        // if (prevProps.match.params.feedType !== this.feedType || prevProps.match.params.entityId !== this.entityId) {
        //     this.scrollRef.current.scrollIntoView();
        // }
    }

    getFeedType = () => {
        return this.props.feedType === "company" || this.props.feedType === "product" ?
            this.props.feedType
            :
            this.props.match.params.feedType;
    }

    getEntityId = () => {
        return this.props.feedType === "company" || this.props.feedType === "product" ?
            this.props.entityId
            :
            this.props.match.params.entityId;
    }
    showNewUserView = () => {
        this.setState({ showNewUserView: true })
    };

    render() {
        const feedType = this.getFeedType();
        const entityId = this.getEntityId();
        let eventEntityId, eventEntityType;
        const { auth, history } = this.props;

        if (feedType === "global") {
            eventEntityType = "user";
            if (auth)
                eventEntityId = auth.id;
        }
        else if (feedType === "new") {
            eventEntityType = "user";
            if (auth)
                eventEntityId = auth.id;
        }
        else {
            eventEntityType = feedType;
            eventEntityId = entityId;
        }
        return (
            <>
                {
                    feedType === "global" || feedType === "user" ?
                        <Layout {...this.props} entityType={eventEntityType} entityId={eventEntityId}>
                            <SEO robots="noindex, nofollow"
                                canonicalUrl={`/networkFeed/${feedType}/${entityId}`}
                            />
                            <NetworkFeed feedType={feedType} entityId={entityId} history={history} />
                        </Layout>
                        :
                        <NetworkFeed feedType={feedType} entityId={entityId} history={history} />
                }

                <div ref={this.scrollRef} id="scrollRef" />
            </>
        );
    }
}

function mapStateToProps({ auth }) {
    return { auth };
}

export default connect(mapStateToProps)(NetworkFeedLayout);
