import React, { useRef } from 'react';
import Cropper from 'react-cropper';
import { Button } from 'semantic-ui-react';

const ImageCropper = ({ cropperProps, setCroppedImage, croppedImage }) => {
    const cropRef = useRef(null);

    const getCropData = () => {
        if (cropRef.current) {
            cropRef.current.getCroppedCanvas().toBlob((blob) => {
                setCroppedImage(blob)
            })
        }
    };
    return (
        <div id="image-cropper" className='d-flex align-items-center justify-content-space-between mB-20 img-cropper-view'>
            <Cropper
                {...cropperProps}
                ref={cropRef}
            />
            <Button
                onClick={getCropData}
                primary
                basic
                className="mt-4"
                name="crop-image">
                Crop Image
            </Button>
            <div className='cropper-img mt-3 mL-10'>
                {croppedImage && <img className='img-responsive' src={URL.createObjectURL(croppedImage)} />}
            </div>
        </div>
    )
}

export default ImageCropper;