import React from 'react';
import { Link } from 'react-router-dom'
import { BASE_URL } from '../../config/config';
import { isHtmlCheck } from '../../common/helperMethods';

class ShowMoreLess extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
         show_more: false,
         text_data: '',
         truncated_data: '',
         default_length: 200, 
      }

      if (this.props.content != undefined && this.props.content != '' && this.props.content != null) {
         this.setState({ text_data: this.props.content })
      }
      
   }
   
   componentDidMount(){
      
      if (this.props.character != undefined && this.props.character != '' && this.props.character != null) {
         this.setState({ default_length: this.props.character })
      }
   }

   show_hide() {
      const { content } = this.props
      var content_length = (content != undefined && content!= null && content!= '') ? content.length : 0;
      var show_more_button = false;
      var strip_cont_len = '';
      if (content != undefined && content != '' && content != null) {
         show_more_button = true;

         if (!isHtmlCheck(content) && content_length < this.state.default_length) {
            show_more_button = false;
         }

         if (isHtmlCheck(content)) {
            if (content_length < this.state.default_length) {
               show_more_button = false;
            }

            strip_cont_len = (content != undefined && content != '') ? content.replace(/<\/?[^>]+(>|$)/g, "").trim() : '';
         }
      }

      return (
         <>
            <div className={'content-css feed-text-only ' + (!this.state.show_more && content_length > this.state.default_length ? 'show_three_lines' : 'show_complete_content')}>
               <div className="art-body-text" dangerouslySetInnerHTML={{ __html: content }}></div>
               {this.state.show_more == true ? (<a onClick={() => this.show_hide_more()} >Show Less</a>) : show_more_button == true && (<a href="#" onClick={() => this.show_hide_more()} >Show More</a>)}
            </div>

         </>
      );
   }
   
   show_hide_more = () => {
      var curr_state = this.state.show_more;
      curr_state = !curr_state;
      this.setState({ show_more: curr_state })
   }

   render() {
      return (
         <React.Fragment>
            {this.show_hide()}
         </React.Fragment >
      )
   }
}


export default ShowMoreLess;