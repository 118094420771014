import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { Form, Button, Loader, Modal } from 'semantic-ui-react';

import "./profile.scss";
import Navbar from '../common/layout/navbar/Navbar';
import { countryList } from '../../common/countries';
import { getUserProfileInfo, userActions } from '../../actions';
import CompanySearch from '../common/CompanySearch';
import LeftSidebar from '../common/LeftSidebar';
import RightSection from '../common/RightSection';
import UserProfileHeader from './UserProfileHeader';
// import { updateUserProfile } from './api';
import { emailRegex, apiStatus } from '../../common/constants';
import { Message } from 'semantic-ui-react';
import { Dropdown } from 'semantic-ui-react';
import SEO from '../seo/SEO';
import DeleteProfileConfirmationModal from './DeleteProfileConfirmationModal';
// import SweetAlerts from '../common/sweetalerts/sweetalert';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userProfileDetails: this.props.userProfileDetails,
            errors: [],
            saving: false,
            successMessage: '',
            validationErrorMessage: '',
        }
    }


    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ userProfileDetails: { ...this.state.userProfileDetails, [name]: value } })
        this.updateErrors(name, value);
    }

    handleCountrySelect = (e, { value }) => {
        this.setState({ userProfileDetails: { ...this.state.userProfileDetails, country: value } })
    }

    onCompanySelect = (data) => {
        const { company, companyId, country } = data;
        this.setState({ userProfileDetails: { ...this.state.userProfileDetails, country, company, companyId } });
    }

    componentDidMount() {
        this.props.getUserProfileDetails();
    }

    componentDidUpdate() {
        const { user, saveStatus } = this.props;
        if (!this.state.userProfileDetails && this.props.user.userProfileDetails) {
            this.setState({ userProfileDetails: this.props.user.userProfileDetails });
        }

        if (saveStatus === apiStatus.UNDEFINED && this.state.saving == true) {
            setTimeout(() => {
                this.setState({ saving: false });
                if (user.profileUpdateResponse != undefined) {
                    this.setState({
                        validationErrorMessage: user.profileUpdateResponse
                    });
                }
            }, 500);
        }
    }

    componentWillReceiveProps() {
        const { user, saveStatus } = this.props;

        if (saveStatus === apiStatus.COMPLETED) {
            this.setState({ saving: false });
            if (user.profileUpdateResponse != undefined) {
                this.setState({
                    successMessage: 'User Profile Updated Successfully '
                });
            }
            this.props.getUserProfileDetails();
        }
    }


    saveChanges = () => {
        if (!this.validateForm()) return;
        const { updateUserProfile, common } = this.props
        this.setState({ saving: true, errors: [], validationErrorMessage: "" });
        const { image, ...details } = this.state.userProfileDetails;
        updateUserProfile(details)/* .then(() => {
            this.setState({ saving: false });
            this.props.getUserProfileDetails();
        }); */
    }

    uploadUserImage = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            this.props.uploadUserImage(file);
        }
    }

    updateErrors(name, value) {
        const reqFields = ["firstName", "lastName", "country", "company"];
        let errors = [...this.state.errors];

        if (!value && reqFields.includes(name)) {
            errors.push(name);
        }
        else {
            const index = errors.indexOf(name);
            if (index > -1) {
                errors.splice(index, 1);
            }
        }
        this.setState({ errors });
    }


    validateForm = () => {
        const { userProfileDetails } = this.state;
        const { primaryEmail, secondaryEmail, password, confirmPassword } = userProfileDetails;

        const reqFields = ["firstName", "lastName", "country", "company"];
        const filledFields = Object.keys(userProfileDetails).filter(item => { if (userProfileDetails[item]) return item })

        let difference = (reqFields).filter(x => !filledFields.includes(x));

        if (difference.length > 0) {
            this.setState({
                errors: [...this.state.errors, ...difference],
                validationErrorMessage: "Please fill all the required fields "
            });
            return false;
        }
        if (password) {
            if (password !== confirmPassword) {
                this.setState({ validationErrorMessage: "password and confirm password are different" })
                return false;
            }
            if (password.length < 8) {
                this.setState({ validationErrorMessage: "password must be atleast 8 characters long" });
                return false;
            }
        }
        if (primaryEmail && !emailRegex.test(primaryEmail.trim())) {
            this.setState({ validationErrorMessage: "invalid primary email id" })
            return false;
        }
        if (secondaryEmail && !emailRegex.test(secondaryEmail.trim())) {
            this.setState({ validationErrorMessage: "invalid secondary email id" })
            return false;
        }
        return true;
    }

    handleClose = () => {
        this.setState({ open: false })
    } 

    render() {
        const { userProfileDetails, saving, validationErrorMessage, errors, successMessage, message, open, loading, showAlert } = this.state;
        const { auth, uploadUserImage, user, deactivateAccount } = this.props;
        return (
            <>
                <SEO robots="noindex, nofollow"
                    canonicalUrl="/profile"
                />
                <Navbar {...this.props}>
                    <div className="profile-mainwrapper">
                        <div className="container">
                            <div className="profile-mainwrapper-inner">
                                <div className="leftbar">
                                    <LeftSidebar />
                                </div>
                                <div className="middle-section">
                                    {
                                        userProfileDetails ?
                                            <>
                                                <div className="row">
                                                    <Form>
                                                        <div className="col-sm-12 edit-profile-form">
                                                            <h3 className="main-heading">General Information</h3>
                                                            <div className="edit-prfile-head">
                                                                <UserProfileHeader
                                                                    userProfileDetails={this.props.user.userProfileDetails}
                                                                    uploadUserImage={uploadUserImage}
                                                                    auth={auth}
                                                                    userImageUploadStatus={user.userImageUploadStatus}
                                                                />
                                                            </div>

                                                            <h4 className="bule-heading">Basics</h4>

                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <label>First Name *</label>
                                                                    <div className="ui input large w-100 form-input mb-3">
                                                                        <input type="text" placeholder="First Name" name="firstName" value={userProfileDetails.firstName} onChange={this.handleInputChange} className={errors.includes("firstName") && 'error'} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label>Last Name *</label>
                                                                    <div className="ui input large w-100 form-input mb-3">
                                                                        <input type="text" placeholder="Last Name" name="lastName" value={userProfileDetails.lastName} onChange={this.handleInputChange} className={errors.includes("lastName") && 'error'} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <label>Job title</label>
                                                                    <div className="ui input large w-100 form-input mb-3">
                                                                        <input type="text" placeholder="Eg. Sales Manager or Purchase Head" name="designation" value={userProfileDetails.designation} onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <h4 className="bule-heading">Security</h4>

                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <label>Password</label>
                                                                    <div className="ui input large w-100 form-input mb-3">
                                                                        <input type="password" placeholder="Password" name="password" value={userProfileDetails.password} onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label>Confirm Password</label>
                                                                    <div className="ui input large w-100 form-input mb-3">
                                                                        <input type="password" placeholder="Confirm Password" name="confirmPassword" value={userProfileDetails.confirmPassword} onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <h4 className="bule-heading">Contact</h4>

                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <label>Work Email (primary)</label>
                                                                    <div className="ui input large w-100 form-input mb-3">
                                                                        <input type="text" placeholder="Work Email (primary)" name="primaryEmail" value={userProfileDetails.primaryEmail} onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label>Personal Email (optional)</label>
                                                                    <div className="ui input large w-100 form-input mb-3">
                                                                        <input type="text" placeholder="Personal Email (optional)" name="secondaryEmail" value={userProfileDetails.secondaryEmail} onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <label>Phone Number</label>
                                                                    <div className="ui input large w-100 form-input mb-3">
                                                                        <input type="text" placeholder="Phone Number" name="phone" value={userProfileDetails.phone} onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <h4 className="bule-heading">Company</h4>

                                                            <div className="row mb-4">
                                                                <div className="col-sm-6">
                                                                    <label>Company Name *</label>
                                                                    <div className={`ui input large w-100 form-input mb-3 ${errors.includes("company") ? "error" : ""}`}>
                                                                        <CompanySearch initialValue={userProfileDetails.company} onSelect={this.onCompanySelect} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label>Country *</label>
                                                                    <div className={`ui input large w-100 form-input mb-3 ${errors.includes("country") ? "error" : ""}`}>

                                                                        <Dropdown
                                                                            search
                                                                            fluid
                                                                            placeholder='Select your country'
                                                                            value={userProfileDetails.country}
                                                                            onChange={this.handleCountrySelect}
                                                                            options={countryList}
                                                                            disabled={userProfileDetails.companyId}
                                                                            name="country"
                                                                            // onChange={this.handleCountrySelect}
                                                                            // value={this.state.formData.country}
                                                                            // options={countryList}
                                                                            // disabled={this.state.disableCountrySelect}
                                                                            error={errors.includes("country")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </Form>
                                                </div>
                                                <div className="row">
                                                    {validationErrorMessage &&
                                                        <Message warning size="mini">
                                                            <Message.Header>{validationErrorMessage}</Message.Header>
                                                        </Message>
                                                    }
                                                    {successMessage &&
                                                        <Message success size="mini">
                                                            <Message.Header>{successMessage}</Message.Header>
                                                        </Message>
                                                    }
                                                    <div className="col-sm-12 profile-button-action m-auto text-center">
                                                        <Button className="mt-3 btn-outline">Cancel</Button>
                                                        <Button className="mt-3" primary onClick={this.saveChanges} loading={saving}>Save Changes</Button>
                                                    </div>
                                                </div>
                                                
                                                <div className="row mb-3 delete-profile-account mt-4">
                                                    <div className="col-sm-12">
                                                        <DeleteProfileConfirmationModal deactivateAccount={deactivateAccount} />
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <Loader active />
                                    }
                                </div>
                                <div className="rightbar">
                                    <RightSection entityId={auth ? auth.id : null} entityType={"user"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Navbar>
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators({ getUserProfileInfo, ...userActions }, dispatch) }
}
function mapStateToProps({ auth, user, common }) {
    return { auth, user, common, saveStatus: common.saveStatus };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);