import React from 'react';
import Info from './productHomeContainer/Info';

const ProductInfoHeader = ({ productName, info, reload, auth, history, currentView, handleViewChange, followersCount }) => {

    return (
        <>
            {(currentView === "followers" || currentView === "invite") && (
                <div className="col-sm-12 back-link cursor-pointer" onClick={() => handleViewChange("productDetails")}>
                    <i className="chevron left icon"></i> Back to {productName}
                </div>
            )}
            {info && currentView !== "invite" && (
                <Info
                    info={info}
                    id="product_info_heading"
                    reload={reload}
                    auth={auth}
                    history={history}
                />
            )}
            {(currentView === "productDetails") && (

                <div className="col-sm-12">
                    <ul className="connection-subhead">
                        <li onClick={() => handleViewChange("followers")}><span>{followersCount}</span> Followers</li>
                        {/* <li><span>14</span> Connections in your network</li> */}
                        <li className="followuser" onClick={() => handleViewChange("invite")}><i className="user icon" /> Invite More</li>
                    </ul>
                </div>
            )}
        </>
    )
}

export default ProductInfoHeader;