import axios from 'axios';
import { BASE_URL } from '../../config/config';
import { CREATE_REQUEST_QUOTE, GET_REQUEST_QUOTE } from '../../config/types';

import { setSaveStatus } from '../common/common';
import { apiStatus } from '../../common/constants';

export const createRequestQuote = (formData) => async (dispatch) => {
   const bodyFormData = new FormData();
   let value;
   Object.keys(formData).forEach(field => {
         if(field == 'attachment'){
            // multiple file upload
            if (formData[field]!= '' && formData[field].length > 0){
               for (let i = 0; i < formData[field].length; i++) {
                  bodyFormData.append(`attachment[${i}]`, formData[field][i])
               }
            }
         } else {
            value = formData[field];
            bodyFormData.append(field, value)
            
         }
      
   })
   

   dispatch(setSaveStatus(apiStatus.IN_PROGRESS));
   const requ_quote = await axios.post(`${BASE_URL}/api/v2/inquiry`, bodyFormData, {
      headers: { "content-type": "multipart/form-data" },
      withCredentials: true
   });
   dispatch({ type: CREATE_REQUEST_QUOTE, payload: true })
   dispatch({ type: GET_REQUEST_QUOTE, payload: requ_quote })

   dispatch(setSaveStatus(apiStatus.COMPLETED));
   setTimeout(() => {
      dispatch(setSaveStatus(apiStatus.UNDEFINED));
   }, 1500);
   
}