//TODO: Refrefactor needed: all list views are similar, check if it can be refactored
import React, { useState } from 'react';
import NoRawMaterials from './NoRawMaterials';
import RawMaterials from './RawMaterials';
import AddRawMaterials from './AddRawMaterials'

function RawMaterialsView({ rawMaterials, productId }) {
    const [showAddRawMaterials, setShowAddRawMaterials] = useState(false)

    return (
        <div className="supplierslist-wrapper border-1-radius">
            {rawMaterials!= undefined && rawMaterials.length > 0
                ? 
                <>
                    <AddRawMaterials productId={productId} />
                    <RawMaterials rawMaterials={rawMaterials} />
                </>
                : showAddRawMaterials
                    ? <AddRawMaterials productId={productId} />
                    : <NoRawMaterials setShowAddRawMaterials={setShowAddRawMaterials} />
            }
        </div>
    )
}

export default RawMaterialsView;