import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Label } from 'semantic-ui-react';
import AvatarImg from '../../assets/images/avatar.png';
import Message from '../common/Message';

const peopleListdata = [
    { key: 1, peopleimage: AvatarImg, name: 'Jane Smith', details: 'Marketing Manager - India' },
    { key: 2, peopleimage: AvatarImg, name: 'Amanda Crystal', details: 'Marketing Director - India' },
    { key: 3, peopleimage: AvatarImg, name: 'Jane Doemaal', details: 'Marketing Manager - India' },
    { key: 4, peopleimage: AvatarImg, name: 'Ankit Singhal', details: 'Marketing Manager - India' },
    { key: 5, peopleimage: AvatarImg, name: 'Mikaela Arora-Jones', details: 'Marketing Director - India' },
    { key: 6, peopleimage: AvatarImg, name: 'Jane Smith', details: 'Marketing Manager - India' }
]

class PeopleTabView extends React.Component {
    getInitials = (userName) => {
        const fullName = userName.split(' ');
        const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
        return initials.toUpperCase();
    }

    render() {
        const { employees, auth } = this.props;
        return (
            <React.Fragment>
                <div className="py-2 px-0 border-1-radius background-white">
                    <div className="companypeople-list">
                        {employees && employees.Items.map(employee => (
                            <div className="item px-2" key={employee.key}>
                                <div className="companypeople-left">
                                    <div className="images">
                                        {employee.image ?
                                            <img alt={employee.image} className="ui avatar image" src={employee.image} />
                                            :
                                            <Label circular color="grey">{this.getInitials(employee.user_name)}</Label>
                                        }
                                    </div>
                                    <Link to={`/networkFeed/user/${employee.user_id}`}>
                                        <div className="content">
                                            <h4>{employee.user_name}</h4>
                                            <p>{employee.designation}</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="right floated content">
                                    <Message auth={auth} userId={employee.user_id} userName={employee.user_name} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PeopleTabView;