import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const SvgIcon = (props ) => {
    const classes = classNames(
        props.className,
        "icon"
    );
    return (
        <React.Fragment>
            <svg id={`${props.id}`} viewBox={props.viewbox} className={classes} {...props.attrs} onClick={props.onClick} style={{ width:props.width, height:props.height, fill:props.fill }}>
                <use xlinkHref={"#"+props.name}></use>
            </svg>
        </React.Fragment>
    );
}

SvgIcon.propTypes = {
    /**
     * Icon Id name for svg sprite.
     */
    name: PropTypes.string.isRequired,
    /**
     * Svg icon viewBox value.
     */
    viewbox: PropTypes.string.isRequired,
    /**
     * Icon width.
     */
    width: PropTypes.string,
    /**
     * Icon height.
     */
    height: PropTypes.string,
    /**
     * Icon color.
     */
    fill: PropTypes.string,
    /**
     * Icon onClick handler.
     */
    onClick: PropTypes.func,

    className: PropTypes.any,

    title: PropTypes.string
};

export default SvgIcon;