import { FETCH_USER } from '../../config/types'

export default function (state = null, action) {
    switch (action.type) {
        case FETCH_USER:
            if (action.payload) {
                let user = action.payload;
                //prevent browser from rendering cached image if the image at the server has changed
                if (user.image) {
                    user.image = `${user.image}?${new Date().getTime()}`;
                }
                return user;
            }
            else {
                return false;
            }
        default:
            return state;
    }
}