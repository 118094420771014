import addRaw from './add_raw';
import addApplication from './add_application';
import addSupplier from './add_supplier';
import addSupplierLinkedRaw from './add_supplier_linked_raw';
import addSupplierLinkedApplication from './add_supplier_linked_application';
import addUser from './add_user';
import addUserLinkedRaw from './add_user_linked_raw';
import addUserLinkedApplication from './add_user_linked_application';
import follow from './follow';
import followLinkedRaw from './follow_linked_raw';
import followLinkedApplication from './follow_linked_application';
import postComments from './post_comments';
import postLike from './post_like';
import likeLikedPost from './post_like_liked';
import likeCommentedPost from './post_like_commented';
import commentOnLikedPost from './post_comment_liked';
import commentOnCommentedPost from './post_comment_commented';
import followCompany from './follow_company';
import productAdded from './product_added';
import inviteAccept from './invite_accept';
import exhibitorAdded from './exhibitor_added';
import attendeeAdded from './attendee_added';
import networkConnection from './network_connection.js';

export default (notification, index) => {
    switch (notification.type) {
        case 'ADD_RAW':
            return addRaw(notification, index);
        case 'ADD_APPLICATION':
            return addApplication(notification, index);
        case 'ADD_SUPPLIER':
            return addSupplier(notification, index);
        case 'ADD_SUPPLIER_LINKED_RAW':
            return addSupplierLinkedRaw(notification, index);
        case 'ADD_SUPPLIER_LINKED_APPLICATION':
            return addSupplierLinkedApplication(notification, index);
        case 'ADD_USER':
            return addUser(notification, index);
        case 'ADD_USER_LINKED_RAW':
            return addUserLinkedRaw(notification, index);
        case 'ADD_USER_LINKED_APPLICATION':
            return addUserLinkedApplication(notification, index);
        case 'FOLLOW':
            return follow(notification, index);
        case 'FOLLOW_LINKED_RAW':
            return followLinkedRaw(notification, index);
        case 'FOLLOW_LINKED_APPLICATION':
            return followLinkedApplication(notification, index);
        case 'POST_COMMENT':
            return postComments(notification, index);
        case 'POST_COMMENT_LIKED':
            return commentOnLikedPost(notification, index);
        case 'POST_COMMENT_COMMENTED':
            return commentOnCommentedPost(notification, index);
        case 'POST_LIKE':
            return postLike(notification, index);
        case 'POST_LIKE_LIKED':
            return likeLikedPost(notification, index);
        case 'POST_LIKE_COMMENTED':
            return likeCommentedPost(notification, index);
        case 'COMPANY_FOLLOW':
            return followCompany(notification, index);
        case 'PRODUCT_ADDED':
            return productAdded(notification, index);
        case 'INVITE_ACCEPT':
            return inviteAccept(notification, index);
        case 'EXHIBITOR_ADDED':
            return exhibitorAdded(notification, index);
        case 'ATTENDEE_ADDED':
            return attendeeAdded(notification, index);
        case 'NETWORK_CONNECTION':
            return networkConnection(notification, index);
        default:
            return null;
    }
};
