export const Schema = (props) => {
    const { pageType } = props;

    switch (pageType) {
        case "product":
            return (
                {
                    "@context": "https://schema.org/",
                    "@type": "WebPage",
                    "name": `${props.name}`,
                    "image": `${props.image}`,
                    "url": `${props.url}`,
                    "description": `${props.description}`
                }

            );
        case "event":
            return (
                {
                    "@context": "https://schema.org",
                    "@type": "Event",
                    "name": `${props.name}`,
                    "description": `${props.description}`,
                    "image": `${props.image}`,
                    "startDate": `${props.startDate}`,
                    "endDate": `${props.endDate}`,
                    "eventStatus": "https://schema.org/EventScheduled",
                    "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
                    "performer": {
                        "@type": "PerformingGroup",
                        "name": "Chirag Sahni"
                    }
                }
            );
        case "homepage":
            return (
                {
                    "@context": "http://schema.org",
                    "@type": "Organization",
                    "name": "Mappes",
                    "url": "https://www.mappes.io/",
                    "logo": "https://fc-use1-00-pics-bkt-00.s3.amazonaws.com/50cbbfb2918364821825268611da798c9f47ea29fc6e469f2c542f46058952f3/f_marketingpicFull/u_86b089051bdb9a42c4970074a1cb834e1fdff25b67c7ed44119233a3a9cb647d/img_697d2q2pbr_ad909c6338001b2558f79136f8788a9a7b5366b24bda3cb35ca5407ce38d9064.png",
                    "contactPoint":
                    {
                        "@type": "ContactPoint",
                        "telephone": "09650840033",
                        "contactType": "Sales",
                        "areaServed": "IN"
                    },

                    "sameAs":
                        [
                            "https://www.facebook.com/profile.php?id=100063911949326",
                            "https://twitter.com/mappesInc",
                            "https://www.youtube.com/@mappesinc4987",
                            "https://www.linkedin.com/company/mappesio/"
                        ]
                }

            )

        case "company":
            return (
                {
                    "@context": "https://schema.org/",
                    "@type": "WebPage",
                    "name": `${props.name}`,
                    "image": `${props.image}`,
                    "url": `${props.url}`,
                    "description": `${props.description}`
                }

            );
        case "article":
            return {
                "@context": "https://schema.org/",
                "@type": "NewsArticle",
                "name": props.name,
                "headline": props.headline,
                "image": props.image,
                "datePublished": props.datePublished,
                "dateModified": props.dateModified,
                "author": [{
                    "@type": "Person",
                    "name": props.author.user_name,
                    "url": props.author.image,
                }],
                "description": `${props.description}`
            }

    }
}