import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './thank-you.scss';
class ThankyouView extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (

            <div className="row listing-names-section">
                <div className="col-sm-11 m-auto listing-names-header">
                    <h1>Thank you</h1>
                    <p>Now that you’ve successfully signed up. You may continue to your feed.</p>
                </div>
                <div className="col-sm-9 mt-5 mt-3 mx-auto">
                    <div className="row mt-3">
                        <div className="col-sm-6 m-auto text-center">
                            <Link to="/networkfeed/global">
                                <button className="ui primary button w-100 btn-lg">
                                    Go To Feed
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}



export default ThankyouView;
