import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import './index.scss'
import { Button, Message } from 'semantic-ui-react';
import axios from 'axios';
import { BASE_URL } from '../../../config/config';
import { useOTPTimer } from './useOTPTimer';

export const LinkAuthenticate = () => {
    const userId = window.location.pathname.split('/').splice(-1)
    const failure = localStorage.getItem('loginFailure') // to check if re-directed from failure screen
    const [error, setError] = useState('')

    const [email, setEmail] = useState('')
    const [otp, setOtp] = useState('')
    const [newUser, setNewUser] = useState(false)
    const [emailLoading, setEmailLoading] = useState(false) // Set true when you fetch email and only set false till second call of OTP is also done

    const { seconds, setSeconds } = useOTPTimer()

    const sendOTP = (email_id) => {
        axios.post(`${BASE_URL}/api/login/initiate`, {
            email_id,
        }).then((response) => {
            const { data } = response.data
            setSeconds(60)
            setNewUser(data.newUser)
            localStorage.setItem('newUser', data.newUser)
        }).finally(() => {
            setEmailLoading(false)
        })
    }

    const fetchUserEmail = () => {
        setEmailLoading(true)
        axios.get(`${BASE_URL}/api/v2/users/mail/${userId}`).then((response) => {

            const emailId = response.data.email
            sendOTP(emailId)

            setEmail(emailId)
            localStorage.setItem('email', emailId)

        })
    }

    useEffect(() => {
        fetchUserEmail()

        localStorage.setItem('userId', userId)
        localStorage.setItem('loginFailUrl', window.location.pathname)

        if (failure && failure === "otp-failed") { // GET API HERE and show/clear error 
            localStorage.removeItem('loginFailure')

            const formData = {
                verification_token: sessionStorage.getItem('otp'),
                email_id: localStorage.getItem('email')
            }
            axios.post(`${BASE_URL}/api/v2/otp/verify`, formData).then((response) => {
                const { data, messages, success } = response.data
                if (!success) {
                    if (data.reason) {
                        setError(messages[0].message)
                    }
                }
            })
        }
    }, [])

    useEffect(() => {
        // Used this as needed after error to call validate API for proper error message
        sessionStorage.setItem('otp', otp)
    }, [otp])

    const reDirectUrl = newUser ? `${window.location.origin}/otp-new-login` : `${window.location.origin}/networkFeed/global`

    return (
        <div className='lwo-body'>
            <form
                name='login'
                action={`${BASE_URL}/registration/verify`}
                method='GET' // GET for login using password and POST for OTP login
            >
                <div className='lwo-card'>
                    <div className='lwo-card-inner'>
                        <div className='upper-head mt-4 pt-2'>
                            <h1>We have sent you OTP</h1>
                            <p className='text-center mb-0'>Enter the verification code sent to:</p>
                            <p className='sentemail'><span>{email}</span>
                            </p>
                        </div>
                        <div className='form-item mt-3'>
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                containerStyle="otp-input"
                                numInputs={6}
                                renderInput={(props) => <input {...props} />}
                                isInputNum
                            />
                        </div>
                        <div className='resend-row'>
                            <p>Didn’t get code?</p>
                            {seconds > 0
                                ? <b>Resend 0:{seconds} sec</b>
                                : <p className='resend-otp'><button type='button' onClick={sendOTP}>Resend OTP</button></p>
                            }
                        </div>
                        {error
                            && <Message error>
                                {error}
                            </Message>
                        }
                        <Button loading={emailLoading} className='submit-btn' primary disabled={otp.length < 6} type="submit">
                            Submit OTP
                        </Button>
                    </div>
                </div>
                <input type="hidden" name="token" value={otp} />
                <input type="hidden" name="email" value={email} />
                <input type="hidden" name="redirect" value={reDirectUrl} />
            </form>
        </div>
    )
}

