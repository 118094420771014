import React from 'react';
import { connect } from 'react-redux';

import InfiniteScroll from 'react-infinite-scroll-component';
import { Grid, Dimmer, Loader, Button, Feed, Progress } from 'semantic-ui-react'
import Article from './post/Article';
import { networkFeedMessages } from './types';
import LoginButton from '../common/LoginButton'
import './AddNewPost/feed-new.scss';

class TopStories extends React.Component {
    render() {
        const { auth, feed, feedType, handleLoadMoreClick, feedPagination, FEED_UPDATE_STATUS, postStatus } = this.props;
        if (!feed) {
            return (
                <>
                  
                    <div className="d-flex">
                        <div>Please wait while we fetch relevant posts for you....</div>
                        <Dimmer active inverted className="mT-10 mB-10" style={{ background: 'transparent' }}>
                            <Loader className="loading-loader"></Loader>
                        </Dimmer>
                    </div>

                    {/* <Feed>
                        <Progress percent={this.props.uploadProgress} color='blue' progress />
                    </Feed> */}
                </>
            )
        }
        else if (feed.length === 0) {
            return <span>{networkFeedMessages[feedType]}</span>
        }

        return (
            <>
                 <React.Fragment >
                    {
                        FEED_UPDATE_STATUS == 'fetching' ? <div className="d-flex"> <Dimmer active inverted className="mT-10 mB-10" style={{ background: 'transparent', "height": "10px" }}> <Loader></Loader> </Dimmer> </div> : ''
                    }
                    <Feed>
                        {postStatus && 
                        <div className='video-progress-wrapper'>
                            <div className='title'>
                                {postStatus == 'uploading' ? 'Publishing Video' : ''}
                                {postStatus == 'creating' ? 'Saving Post' : ''}
                            </div>
                            {
                                postStatus == 'uploading' || postStatus == 'creating' /*  || FEED_UPDATE_STATUS == 'fetching' */ ?
                                    <Progress percent={this.props.uploadProgress} progress /> : ''
                            }
                            {postStatus == <p>Note: Please, don’t close the browser while post is getting updated</p>}
                        </div>
                        }
                        <InfiniteScroll
                            dataLength={feed.length}
                            next={handleLoadMoreClick}
                            hasMore={feed.length < feedPagination.total}
                            scrollableTarget="sidebar-pushable"
                            loader={<Loader active inline center />}
                            endMessage={
                                <p style={{ textAlign: 'center' }}>
                                    {
                                        auth ?
                                            <b>That's all for now!</b>
                                            :
                                            <LoginButton />
                                    }
                                </p>
                            }
                        >
                            {
                                feed && feed.map(article =>
                                    <Article article={article} key={article.id} {...this.props} />
                                )
                            }
                        </InfiniteScroll>
                    </Feed>
                </React.Fragment >
            </>


        );
    }
}

const mapStateToProps = (state) => {
    return { FEED_UPDATE_STATUS: state.networkFeed.FEED_UPDATE_STATUS, ...state.networkFeed, ...state.user, ...state.likedPosts }
}

export default connect(mapStateToProps)(TopStories);
