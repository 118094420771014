import React from 'react';
import { Link } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { Button } from 'semantic-ui-react';

class UserProfileHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    uploadUserImage = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.setState({ src: reader.result });
            }
            this.props.uploadUserImage(file);
        }
    }
    render() {
        const { userProfileDetails, auth, userImageUploadStatus } = this.props;

        const isPaidUser = auth && auth.isActive;
        const image = this.state.src || userProfileDetails && userProfileDetails.image;

        return (
            <>
                <div className="ui items">
                    <div className="item">
                        <div className="image">
                            <img alt={image} src={image} />
                        </div>
                        <div className="content">
                          {/*   <div className="extra">
                                {isPaidUser ? "Premium User" : "Free User"}
                            </div> */}
                            <div className="header">{userProfileDetails.firstName} {userProfileDetails.lastName}</div>
                            <div className="userImage">
                                <label htmlFor="upload-user-image">Change Photo</label>
                                <input id="upload-user-image" type="file" onChange={this.uploadUserImage} />
                                <Loader inline size="small" active={userImageUploadStatus === "uploading"} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div>
                    {!isPaidUser &&
                        <Link to="/upgrade">
                            <Button basic primary className="upgrade-btn"> Upgrade to Paid </Button>
                        </Link>
                    }
                </div> */}
            </>

        )
    }
}
export default UserProfileHeader;