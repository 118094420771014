export const countries = [
"Algeria",
"Australia",
"Austria",
"Belarus",
"Bosnia and Herzegovina",
"Canada",
"Chile",
"China",
"Czech Republic",
"Egypt",
"France",
"Germany",
"Greece",
"Hong Kong",
"India",
"Indonesia",
"Iran",
"Ireland",
"Israel",
"Italy",
"Japan",
"Jordan",
"Korea, Republic Of",
"Malaysia",
"Netherlands",
"North Macedonia",
"Oman",
"Russia",
"Saudi Arabia",
"Slovenia",
"Spain",
"Sweden",
"Switzerland",
"Taiwan",
"Thailand",
"Tunisia",
"Turkey",
"United Arab Emirates",
"United Kingdom",
"United States of America",
"Vietnam",
"Afghanistan",
"Åland Islands",
"Argentina",
"Armenia",
"Azerbaijan",
"Bahamas",
"Bahrain",
"Bangladesh",
"Belgium",
"Bolivia",
"Botswana",
"Brazil",
"British Indian Ocean Territory",
"British Virgin Islands",
"Bulgaria",
"Cambodia",
"Cameroon",
"Colombia",
"Cook Islands",
"Costa Rica",
"Croatia",
"Cyprus",
"Denmark",
"Dominican Republic",
"Estonia",
"Ethiopia",
"Finland",
"Gambia",
"Georgia",
"Ghana",
"Hungary",
"Iceland",
"Ivory Coast",
"Kazakhstan",
"Kenya",
"Kosovo",
"Kuwait",
"Kyrgyzstan",
"Lagos",
"Latvia",
"Lebanon",
"Liechtenstein",
"Lithuania",
"Luxembourg",
"Macao",
"Malta",
"Mauritius",
"Mexico",
"Moldova",
"Mongolia",
"Morocco",
"Myanmar",
"Nairobi",
"Nepal",
"New Zealand",
"Niger",
"Nigeria",
"Norway",
"Pakistan",
"Peru",
"Philippines",
"Poland",
"Portugal",
"Qatar",
"Republic of Korea",
"Republic of Maldives",
"Reunion",
"Riyadh",
"Romania",
"San Marino",
"Scotland",
"Serbia",
"Seychelles",
"Singapore",
"Slovakia",
"South Africa",
"Sri Lanka",
"Tanzania",
"Uganda",
"Ukraine",
"United Kingdom of Great Britain and Northern Ireland",
"Urugay",
"Uzbekistan",
"Venezuela",
"Wrexham, Wales",
"Zambia",
"Zimbabwe",
];
export const countryList = countries.map(country => {
    return {
        key: country, value: country, text: country
    }
});