import React, { useState } from 'react'
import { NodeLinkGraph } from './data-viz/NodeLinkGraph';
import { Button, Modal } from 'semantic-ui-react';

import GraphImage from '../../assets/images/graph-img.png';

const dataVizButtons = [
    { label: 'Tree graph visualization', value: 'NODE_LINK', image: GraphImage }
]

export const DataVisualizations = (props) => {

    const [open, setOpen] = useState(false);
    const [selectedViz, setSelectedViz] = useState('');

    const handleButtonClick = (value) => {
        setSelectedViz(value);
        setOpen(true);
    }

    const handleCloseClick = () => {
        setSelectedViz('');
        setOpen(false)
    }

    const getComponent = () => {
        switch (selectedViz) {
            case 'NODE_LINK': return <NodeLinkGraph {...props} />
            default: return null;
        }
    }

    const getTitle = () => {
        switch (selectedViz) {
            case 'NODE_LINK': return 'Node Link Diagram'
            default: return null;
        }
    }

    return (
        <>
            <div className="supplierslist-wrapper border-1-radius">
                <div className='visualiz-listing'>
                    {dataVizButtons.map(({ label, value, image }) => (
                        <div className='card-box' key={value} onClick={() => handleButtonClick(value)}>
                            <div className='card-upper'>
                                <img src={image} />
                            </div>
                            <div className='card-bottom'>
                                <p>{label}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <Modal
                    className="createa-supplier"
                    onClose={handleCloseClick}
                    open={open}
                    size="fullscreen"
                    closeIcon
                >
                    <Modal.Content>
                        <Modal.Description>
                            <div className="mb-3"><b>{getTitle()}</b></div>
                            {getComponent()}
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        </>
    )
}
