import React from 'react';
import { Feed } from 'semantic-ui-react';
import { connect } from 'react-redux';
import ArticleHeaderNew from './ArticleHeaderNew';
import ArticleBodyNew from './ArticleBodyNew';
import ArticleFooterNew from './ArticleFooterNew';
import './../AddNewPost/feed-new.scss';
import { showLoginModal } from '../../../actions/common/common';

const Article = (props) => {
    return (
        <Feed.Event>
            <ArticleHeaderNew {...props} />
            <Feed.Content>
                <ArticleBodyNew {...props} />
                <ArticleFooterNew {...props} />
            </Feed.Content>
        </Feed.Event>
    )
    
    
}

const mapDispatchToProps = dispatch => {
    return {
        showLoginModal: () => dispatch(showLoginModal(true))
    }
}
export default connect(null, mapDispatchToProps)(Article);