import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { List } from 'semantic-ui-react'
import SvgIcon from '../../svg-icon/svg-icon';

import './footer.scss';


class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        var datef = new Date();
        return (
            <React.Fragment>
                <div className="main-footer">
                    <div className="footer-wrapper">
                        <div className="footer-left">
                            <div className="footer-logo lg-device">
                                <SvgIcon name="logo-m" viewbox="0 0 54.943 49.287" />
                            </div>
                            <List className="footer-links">
                                <List.Item><Link to='/aboutus'> About Us</Link></List.Item>
                            </List>
                            <List className="footer-links">
                                <List.Item>
                                    <a href='https://www.facebook.com/Mappesio-353276055520717' target="_blank" rel="noopener noreferrer">
                                        <SvgIcon width="11" name="facebook" viewbox="0 0 7.311 14" /> Facebook
                                    </a>
                                </List.Item>
                                <List.Item>
                                    <a href='https://twitter.com/mappesInc' target="_blank" rel="noopener noreferrer">
                                        <SvgIcon width="16" name="twitter" viewbox="0 0 14 11.356" /> Twitter
                                    </a>
                                </List.Item>
                                <List.Item >
                                    <a href='https://www.linkedin.com/company/mappesio/?viewAsMember=true' target="_blank" rel="noopener noreferrer">
                                        <SvgIcon width="16" name="linkedin" viewbox="0 0 31.5 31.499" /> Linkedin
                                    </a>
                                </List.Item>
                            </List>
                        </div>
                        <div className="footer-right">
                            <div className="small-device">
                                <SvgIcon name="logo-m" viewbox="0 0 54.943 49.287" />
                            </div>
                            <div>
                                <a href="mailto:ankit.singhal@mappes.io">info@mappes.io</a>
                                <List horizontal className="footer-right-link">
                                    <List.Item>© Mappes {datef.getFullYear()}</List.Item>
                                    {/* <List.Item as='a'>Terms and Conditions</List.Item>
                                    <List.Item as='a'>Privacy policy</List.Item> */}
                                </List>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}



export default Footer;
