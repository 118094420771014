import {
    NETWORK_USER_FEED,
    NETWORK_PRODUCT_FEED,
    NETWORK_COMPANY_FEED,
    POST_STATUS,
    GLOBAL_USER_FEED,
    UPLOAD_PROGRESS,
    NETWORK_USER_LIKED_POSTS,
    SET_POST_COMMENTS,
    POST_LIKES_COUNT,
    ADD_POST_COMMENT_STATUS,
    POST_DETAILS,
    NETWORK_PRODUCT_FEED_SUMMARY,
    NETWORK_COMPANY_FEED_SUMMARY,
    POST_SHARED,
    SET_NETWORKFEED_PAGINATION,
    POST_TAGGED,
    NETWORK_FEED_LOADING_STATUS,
    FEED_UPDATE_STATUS,
    RESET_ARTICLE,
    NEWS,
    POST_TAGGED_FAILED
} from '../../config/types';

export default function (state = {
    uploadProgress: 0,
    postCommentsCollection: {},
    feedPagination: {
        start: 0,
        size: 10
    },
    networkFeedUsers: []
}, action) {
    switch (action.type) {
        case NETWORK_USER_FEED:
            return { ...state, userFeed: updateFeedCollection("userFeed", state, action) }
        case NETWORK_PRODUCT_FEED:
            return { ...state, productFeed: updateFeedCollection("productFeed", state, action) }
        case GLOBAL_USER_FEED:
            return { ...state, globalFeed: updateFeedCollection("globalFeed", state, action) }
        case NETWORK_COMPANY_FEED:
            return { ...state, companyFeed: updateFeedCollection("companyFeed", state, action) }
        case POST_STATUS:
            return { ...state, postStatus: action.payload }
        case UPLOAD_PROGRESS:
            return { ...state, uploadProgress: action.payload }
        case NETWORK_USER_LIKED_POSTS:
            return { ...state, likedPosts: action.payload }
        case SET_POST_COMMENTS:
            const postCommentsCollection = updatePostCommentsCollection(state, action);
            return { ...state, postCommentsCollection }
        case POST_LIKES_COUNT:
            return { ...state, postLikeCount: action.payload }
        case ADD_POST_COMMENT_STATUS:
            return { ...state, addPostCommentStatus: action.payload }
        case POST_DETAILS:
            return { ...state, postDetails: action.payload }
        case NETWORK_COMPANY_FEED_SUMMARY:
            return { ...state, companyFeedSummary: action.payload }
        case NETWORK_PRODUCT_FEED_SUMMARY:
            return { ...state, productFeedSummary: action.payload }
        case POST_SHARED:
            return { ...state, postShareStatus: action.payload }
        case SET_NETWORKFEED_PAGINATION:
            return { ...state, feedPagination: action.payload }
        case POST_TAGGED:
            return { ...state, postTagged: action.payload }
        case POST_TAGGED_FAILED:
            return { ...state, postTaggedFailed: action.payload }
        case NETWORK_FEED_LOADING_STATUS:
            return { ...state, networkFeedLoadingStatus: action.payload }
        case FEED_UPDATE_STATUS:
            return { ...state, FEED_UPDATE_STATUS: action.payload }
        case "NETWORKFEED_USERS":
            return { ...state, networkFeedUsers: action.payload }
        case RESET_ARTICLE:
            return { ...state, postDetails: null }
        case NEWS:
            return { ...state, news: action.payload }
        default:
            return state;
    }

}
const updatePostCommentsCollection = (state, action) => {
    //if postcomments exists then update else insert a new object
    const postCommentsCollection = { ...state.postCommentsCollection };
    const postId = action.payload.postId;
    postCommentsCollection[postId] = action.payload.comments;

    return postCommentsCollection;
}

const updateFeedCollection = (feedType, state, action) => {
    const feed = state[feedType] ? [...state[feedType]] : [];
    if (state.feedPagination.start === 0 || feed.length === 0) {
        return action.payload;
    }
    else {
        feed.push(...action.payload);
        return feed;
    }
}
