import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions';
import { networkFeedActions, commonActions } from '../../actions';
import { connect } from 'react-redux';
import {
    Container,
    Form,
    Table,
    Dropdown,
    Label,
    Image,
    Modal,
    List,
    Search,
    Button,
    Icon,
    Grid,
    Header,
    Divider,
    Responsive,
    Input,
} from 'semantic-ui-react';

import Navbar from '../common/layout/navbar/Navbar';
import LeftSidebar from '../common/LeftSidebar';
import RightSection from '../common/RightSection';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../config/config';
import '../product/product.css';
// import { Message } from 'semantic-ui-react';
import NetworkFeedSummary from '../common/NetworkFeedSummary';
import Message from '../common/Message';
import Followers from '../common/followers/Followers';
import InviteView from '../common/invite/invite';
import { replaceSpaceInName } from '../../common/helperMethods';
import SEO from '../seo/SEO';


let companyId = null;

const resultRenderer = result => <Label key={result._source.id} content={result._source.name} />;

class Company extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            messages: [],
            modalProductOpen: false,
            modalAddProductOpen: false,
            modalEmailOpen: false,
            raws: [],
            newProducts: [],
            description: '',
            company_updated: '',
            otherUser: '',
            currentRoom: '',
            chatMessage: '',
            addRawButtonLoading: false,
            open: false,
            animation: 'fly down',
            duration: 700,
            loading: true,
            currentView: "companyDetails"
        };
        this.scrollToTable = this.scrollToTable.bind(this);
    }

    componentDidMount() {
        this.props.getCompanyFeedUnauthenticated(companyId);
    }

    notify = () => {
        toast.success('Successfully added product !', {
            autoClose: 3000,
        });
    };

    handleClose = () => this.setState({ modalOpen: false });

    handleProductOpen = () => this.setState({ modalProductOpen: true });

    handleProductClose = () => this.setState({ modalProductOpen: false });

    handleResultSelect = (e, { result }) => {
        let newRaw = this.state.raws;
        newRaw.push({
            id: result._source.id,
            name: result._source.name,
        });
        this.setState({
            raws: newRaw,
            value: '',
            description: '',
        });
    };

    handleSearchChange = (e, { value }) => {
        this.setState({ value });
        if (value.length > 4) {
            this.setState({ isLoading: true });
            axios.get(`${BASE_URL}/api/v1/product/search/${value}`, { withCredentials: true }).then(response => {
                this.setState({
                    isLoading: false,
                    results: response.data,
                });
            });
        }
    };

    addRawMaterials() {
        this.setState({
            addRawButtonLoading: true,
        });
        Promise.all(
            this.state.newProducts.map(newProduct =>
                axios.post(
                    `${BASE_URL}/api/v1/product/id`,
                    { name: newProduct.name, desc: newProduct.description },
                    { withCredentials: true },
                ),
            ),
        )
            .then(responses => {
                let raws = this.state.raws.concat(responses.map(response => response.data));
                return Promise.all(
                    raws.map(raw => {
                        let data = {
                            company_id: companyId,
                            company_name: this.props.company.info.Item.name,
                            product_id: raw.id,
                            product_name: raw.name,
                        };
                        return axios.post(`${BASE_URL}/api/v1/product/supplier`, data, { withCredentials: true });
                    }),
                );
            })
            .then(() => {
                this.setState({
                    addRawButtonLoading: false,
                    raws: [],
                    newProducts: [],
                    modalAddProductOpen: false,
                });
                this.getSuppliedProducts();
            })
            .catch(err => {
                this.setState({ err: err, modalAddProductOpen: false });
                this.getSuppliedProducts();
            });
    }

    removeItem(id, name) {
        if (id) {
            this.setState({
                raws: this.state.raws.filter(e => e.id !== id),
            });
        } else {
            this.setState({
                newProducts: this.state.newProducts.filter(e => e.name !== name),
            });
        }
    }

    renderRawMaterials() {
        let endMaterials = this.state.raws.concat(this.state.newProducts);
        return endMaterials.map((raw, index) => {
            return (
                <List.Item key={index}>
                    <List.Content floated="right">
                        <Button style={{ width: '40px' }} icon onClick={this.removeItem.bind(this, raw.id, raw.name)} color={'red'}>
                            <Icon name="delete" />
                        </Button>
                    </List.Content>
                    <List.Content style={{ fontSize: '19px', color: '#979499' }}>{raw.name}</List.Content>
                </List.Item>
            );
        });
    }

    handleAddProduct = e => {
        let products = this.state.newProducts;
        products.push({ name: this.state.value, description: this.state.description });
        this.setState({
            newProducts: products,
            value: '',
            description: '',
        });
    };

    addCompany() {
        this.setState({
            addRawButtonLoading: true,
        });
        const data = {
            name: this.refs.name.value,
            desc: this.refs.desc.value,
        };
        axios.post(`${BASE_URL}/api/v1/product`, data, { withCredentials: true });
        const that = this;
        setTimeout(function () {
            that.setState({
                addRawButtonLoading: false,
                modalProductOpen: false,
            });
            that.notify();
        }, 3000);
    }

    getSuppliedProducts() {
        axios.get(`${BASE_URL}/api/v1/company/${companyId}/products`, { withCredentials: true }).then(response => {
            this.setState({
                suppliedProducts: response.data,
            });
        });
    }

    getEmployees() {
        axios.get(`${BASE_URL}/api/v1/company/${companyId}/employees`, { withCredentials: true }).then(response => {
            this.setState({
                employees: response.data,
            });
        });
    }

    componentWillMount() {
        // let loginInfo = sessionStorage.getItem('login');
        // if (!loginInfo) {
        //     sessionStorage.setItem('login', 0);
        // } else {
        //     loginInfo++;
        //     sessionStorage.setItem('login', loginInfo);
        //     if (loginInfo > 3) {
        //         this.props.showLoginModal();
        //     }
        // }

        companyId = this.props.match.params.id;
        this.props.getCompanyInfo(companyId);
        this.getSuppliedProducts();
        this.getEmployees();
        if (this.props.auth) {
            this.getProfileData(this.props.auth);
            this.props.getCompanyFollowers(companyId);
        }
    }

    reload = () => {
        companyId = this.props.match.params.id;
        this.props.getCompanyInfo(companyId);
        this.props.fetchUser();
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.company.info) {
            this.setState({ loading: false });
        }

        if (this.props.match.params.id !== nextProps.match.params.id) {
            // let loginInfo = sessionStorage.getItem('login');
            // if (!loginInfo) {
            //     sessionStorage.setItem('login', 0);
            // } else {
            //     loginInfo++;
            //     sessionStorage.setItem('login', loginInfo);
            //     if (loginInfo > 3) {
            //         this.props.showLoginModal();
            //     }
            // }
            companyId = nextProps.match.params.id;
            this.props.getCompanyInfo(companyId);
            this.getSuppliedProducts();
            this.getEmployees();
            this.props.getCompanyFeedUnauthenticated(companyId);

            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
            this.setState({ currentView: "companyDetails" });

        }
        if (!this.props.auth && nextProps && nextProps.auth) {
            // alert("Tr");
            this.getProfileData(nextProps.auth);
        }
        if (!this.props.networkFeed.companyFeed && nextProps.auth) {
            this.props.getCompanyFeed(nextProps.auth.id, this.props.match.params.id);
        }
    }

    scrollToBottom = () => {
        const { messageList } = this.refs;
        const scrollHeight = messageList.scrollHeight;
        const height = messageList.clientHeight;
        const maxScrollTop = scrollHeight - height;
        ReactDOM.findDOMNode(messageList).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    };

    subscribeToChatRoom() {
        this.setState({ messages: [] });
        return this.props.chatUser
            .subscribeToRoom({
                roomId: this.state.currentRoom.id,
                messageLimit: 100,
                hooks: {
                    onMessage: message => {
                        this.setState({
                            messages: [...this.state.messages, message],
                        });
                        this.scrollToBottom();
                    },
                },
            })
            .catch(error => console.error('error', error));
    }

    renderSuppliedProducts() {
        return this.state.suppliedProducts.Items.map((supp, index) => {
            return (
                <Table.Row key={index}>
                    <Table.Cell>
                        <Link to={`/product/${replaceSpaceInName(supp.product_name)}/${supp.product_id}`}>{supp.product_name}</Link>
                    </Table.Cell>
                    {/* <Table.Cell></Table.Cell> */}
                    {/* <Table.Cell textAlign='center'>
                        <Icon onClick={this.handleOpen} className="color-green cursor-pointer font-18" name='mail' />

                    </Table.Cell> */}
                </Table.Row>
            );
        });
    }

    renderEmployees() {
        return this.state.employees.Items.map((e, index) => {
            return (
                <Table.Row key={index}>
                    <Table.Cell>
                        <Link to={'/networkFeed/user/' + e.user_id}>{e.user_name}</Link>
                    </Table.Cell>
                    <Table.Cell>{e.designation}</Table.Cell>
                    <Table.Cell textAlign="center">
                        <Message auth={this.props.auth} userId={e.user_id} userName={e.user_name} />
                    </Table.Cell>
                </Table.Row>
            );
        });
    }

    handleEmailOpen = () => this.setState({ modalEmailOpen: true });

    handleEmailClose = () => this.setState({ modalEmailOpen: false });

    handleAddProductOpen = () => this.setState({ modalAddProductOpen: true });

    handleAddProductClose = () => this.setState({ modalAddProductOpen: false });

    renderProfileImage() {
        const { auth } = this.props;
        if (auth && auth._json && auth._json.pictureUrls) {
            return <Image className="avatar-40" avatar src={auth._json.pictureUrls.values[0]} />;
        }
    }

    getProfileData(auth) {
        axios
            .get(`${BASE_URL}/api/v1/user/profile/byId/${auth.id}`, { withCredentials: true })
            .then(user => {
                this.setState({
                    company_updated: user.data.Item.company_updated,
                });
            })
            .catch(err => {
                console.log(err);
            });
    }
    scrollToTable(tableName) {
        this[tableName].scrollIntoView({ block: 'center', behavior: 'smooth' });
    }

    OpenUpdateCompany = () => {
        this.setState({
            modalCompanyOpen: true,
            updatedCompanyName: this.props.company.info.Item.name,
            updatedCompanyAddress: this.props.company.info.Item.address,
            updatedCompanyUrl: this.props.company.info.Item.url,
            updatedCompanyDescription: this.props.company.info.Item.description,
            updatedCompanyCountry: this.props.company.info.Item.country,
        });
    };

    updateCompany() {
        this.setState({
            addRawButtonLoading: true,
        });
        let data = {
            name: this.state.updatedCompanyName,
            address: this.state.updatedCompanyAddress,
            url: this.state.updatedCompanyUrl,
            description: this.state.updatedCompanyDescription,
            country: this.state.updatedCompanyCountry,
        };
        axios
            .put(`${BASE_URL}/api/v1/company/${this.props.company.info.Item.id}`, data, { withCredentials: true })
            .then(response => {
                if (response.status == 200) {
                    toast.success('Company updated!', {
                        autoClose: 3000,
                    });
                    this.setState({ addRawButtonLoading: false, modalCompanyOpen: false });
                    this.reload();
                } else {
                    toast.error('Failed to update company', {
                        autoClose: 3000,
                    });
                }
            })
            .catch(err => {
                toast.error('Failed to update company', {
                    autoClose: 3000,
                });
            });
    }

    uploadLogo = event => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            this.props.uploadCompanyLogo(companyId, file);
        }
    };

    followCompany = async () => {
        try {
            this.setState({ followLoading: true });
            if (!this.props.auth) {
                return this.props.history.push("/otp-login");
            }
            if (this.props.auth.followedCompanies.hasOwnProperty(companyId)) {
                await axios.delete(`${BASE_URL}/api/v1/me/followedCompanies/${companyId}`, { withCredentials: true });
            } else {
                await axios.post(
                    `${BASE_URL}/api/v1/me/followedCompanies`,
                    { company_id: companyId },
                    { withCredentials: true },
                );
            }
            this.reload();
        } finally {
            this.setState({ followLoading: false });
        }
    };
    handleViewChange = (currentView) => {
        if (this.props.auth) {
            this.setState({ currentView })
        }
        else {
            this.props.showLoginModal(true);
        }
    }

    render() {
        const { isLoading, value, results, description, currentView } = this.state;
        const { auth, company, match } = this.props;
        let companyUrl =
            (company.info && company.info.Item && company.info.Item.url) || '';
        if (companyUrl && !(companyUrl.startsWith('http://') || companyUrl.startsWith('https://'))) {
            companyUrl = '//' + companyUrl;
        }
        const companyId = match.params.id;
        const companyName =
            company.info && company.info.Item && company.info.Item.name;
        const companyLogo =
            company.info && company.info.Item && company.info.Item.logo;


        return (
            <Navbar {...this.props}>

                <SEO robots="index, follow"
                    canonicalUrl={`/company/${replaceSpaceInName(company.name)}/${companyId}`}
                />
                <div className="primary-background">
                    <Container className="body-background palm-nudge-sides">
                        <Grid padded stackable>
                            <Responsive as={Grid.Column} className="left-content" minWidth={768}>
                                <LeftSidebar />
                            </Responsive>
                            <Grid.Column className="main-content-area">
                                <div className="main-content">
                                    {!this.props.company.info || !this.props.company.info.Item ? (
                                        this.state.loading || (
                                            <div className="main-content_inner">
                                                <Message negative>
                                                    <Message.Header>
                                                        Sorry, the company you requested doesn't exist in our system
                                                    </Message.Header>
                                                    <p>
                                                        Please search your company or go back to{' '}
                                                        <a href="/">home page</a>
                                                    </p>
                                                </Message>
                                            </div>
                                        )
                                    ) : (
                                        <div className="main-content_inner">
                                            <Grid verticalAlign="top" padded className="full-width">
                                                {(currentView === "followers" || currentView === "invite") && (
                                                    <div className="col-sm-12 back-link cursor-pointer py-3 mb-0" onClick={() => this.handleViewChange("companyDetails")}>
                                                        <i className="chevron left icon"></i> Back to {companyName}
                                                    </div>
                                                )}
                                                {
                                                    currentView === "companyDetails" &&
                                                    <Grid.Row className="extra-padding-sides pD-t-30">

                                                        <>
                                                            <Grid.Column width={13}>


                                                                <div className="company_infoL-left-main">
                                                                    {companyLogo ? (
                                                                        <div className="company_infoL-left-main-image">
                                                                            <div className="company_infoL-left-main-image-inner">
                                                                                <Image
                                                                                    src={companyLogo}
                                                                                    className="company_detail--logo"
                                                                                    circular
                                                                                    verticalAlign="top"></Image>
                                                                                <div className="uploadLogo-imgae">
                                                                                    <input
                                                                                        id="update-company--logo"
                                                                                        type="file"
                                                                                        onChange={this.uploadLogo}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="upload-company-control">
                                                                            {auth ? (
                                                                                <React.Fragment>
                                                                                    <label for="upload-company-logo">
                                                                                        click to upload logo
                                                                                    </label>
                                                                                    <input
                                                                                        id="upload-company-logo"
                                                                                        type="file"
                                                                                        onChange={this.uploadLogo}
                                                                                    />
                                                                                </React.Fragment>
                                                                            ) : (
                                                                                <label for="upload-company-logo">
                                                                                    no logo uploaded
                                                                                </label>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    <div className="company_detail-info">
                                                                        <div className="h4 mB-5 d-flex">
                                                                            {this.props.company.info &&
                                                                                this.props.company.info.Item.name}
                                                                            <div>

                                                                                {/* please use thisbelow code for following button */}

                                                                                {/* <Button basic primary className=" follow-btn background-light-blue" primary size={'mini'} animated>
                                                                                <Button.Content visible>Following</Button.Content>
                                                                                <Button.Content hidden>
                                                                                    Unfollow
                                                                                </Button.Content>
                                                                            </Button>
                                                                            <Button basic className=" follow-btn background-light-blue" primary size={'mini'} animated>
                                                                                <Button.Content visible>Follow</Button.Content>
                                                                                <Button.Content hidden>
                                                                                    Follow
                                                                                </Button.Content>
                                                                            </Button> */}

                                                                                <Button
                                                                                    onClick={this.followCompany}
                                                                                    className="follow-btn background-light-blue"
                                                                                    primary
                                                                                    size={'mini'}
                                                                                    animated
                                                                                    basic
                                                                                    loading={this.state.followLoading}
                                                                                    disabled={this.state.followLoading}>
                                                                                    <Button.Content visible>
                                                                                        {auth &&
                                                                                            auth.followedCompanies.hasOwnProperty(
                                                                                                companyId,
                                                                                            )
                                                                                            ? 'Following'
                                                                                            : 'Follow'}
                                                                                    </Button.Content>
                                                                                    <Button.Content hidden>
                                                                                        {auth && auth.followedCompanies.hasOwnProperty(companyId)
                                                                                            ?
                                                                                            "Unfollow"
                                                                                            :
                                                                                            "Follow"
                                                                                        }
                                                                                    </Button.Content>
                                                                                </Button>

                                                                            </div>
                                                                            {/* <div>
                                                                                <Modal
                                                                                    open={this.state.modalCompanyOpen}
                                                                                    onClose={this.handleClose}
                                                                                    size={'large'}
                                                                                    trigger={
                                                                                        <Button onClick={this.OpenUpdateCompany} basic className="edit-btn">Edit</Button>
                                                                                    }>
                                                                                    <Modal.Header>Update Company</Modal.Header>
                                                                                    <Modal.Content>
                                                                                        <Modal.Description>
                                                                                            <Form>
                                                                                                <Form.Field>
                                                                                                    <label>Name</label>
                                                                                                    <input
                                                                                                        value={
                                                                                                            this.state
                                                                                                                .updatedCompanyName
                                                                                                        }
                                                                                                        onChange={e =>
                                                                                                            this.setState({
                                                                                                                updatedCompanyName:
                                                                                                                    e.target.value,
                                                                                                            })
                                                                                                        }
                                                                                                    />
                                                                                                </Form.Field>
                                                                                                <Form.Field>
                                                                                                    <label>Address</label>
                                                                                                    <input
                                                                                                        value={
                                                                                                            this.state
                                                                                                                .updatedCompanyAddress
                                                                                                        }
                                                                                                        onChange={e =>
                                                                                                            this.setState({
                                                                                                                updatedCompanyAddress:
                                                                                                                    e.target.value,
                                                                                                            })
                                                                                                        }
                                                                                                    />
                                                                                                </Form.Field>
                                                                                                <Form.Field>
                                                                                                    <label>Url</label>
                                                                                                    <input
                                                                                                        value={
                                                                                                            this.state
                                                                                                                .updatedCompanyUrl
                                                                                                        }
                                                                                                        onChange={e =>
                                                                                                            this.setState({
                                                                                                                updatedCompanyUrl:
                                                                                                                    e.target.value,
                                                                                                            })
                                                                                                        }
                                                                                                    />
                                                                                                </Form.Field>
                                                                                                <Form.Field>
                                                                                                    <label>Country</label>
                                                                                                    <input
                                                                                                        value={
                                                                                                            this.state
                                                                                                                .updatedCompanyCountry
                                                                                                        }
                                                                                                        onChange={e =>
                                                                                                            this.setState({
                                                                                                                updatedCompanyCountry:
                                                                                                                    e.target.value,
                                                                                                            })
                                                                                                        }
                                                                                                    />
                                                                                                </Form.Field>
                                                                                                <Form.Field>
                                                                                                    <label>Description</label>
                                                                                                    <input
                                                                                                        value={
                                                                                                            this.state
                                                                                                                .updatedCompanyDescription
                                                                                                        }
                                                                                                        onChange={e =>
                                                                                                            this.setState({
                                                                                                                updatedCompanyDescription:
                                                                                                                    e.target.value,
                                                                                                            })
                                                                                                        }
                                                                                                    />
                                                                                                </Form.Field>
                                                                                            </Form>

                                                                                            <div
                                                                                                style={{
                                                                                                    textAlign: 'center',
                                                                                                    paddingTop: '20px',
                                                                                                }}>
                                                                                                <Button
                                                                                                    loading={
                                                                                                        this.state
                                                                                                            .addRawButtonLoading
                                                                                                    }
                                                                                                    primary
                                                                                                    animated
                                                                                                    className="background-light-blue"
                                                                                                    onClick={this.updateCompany.bind(
                                                                                                        this,
                                                                                                    )}>
                                                                                                    <Button.Content visible>
                                                                                                        Submit
                                                                                                    </Button.Content>
                                                                                                    <Button.Content hidden>
                                                                                                        <Icon name="right arrow" />
                                                                                                    </Button.Content>
                                                                                                </Button>
                                                                                                <Button
                                                                                                    animated
                                                                                                    color="red"
                                                                                                    onClick={() =>
                                                                                                        this.setState({
                                                                                                            modalCompanyOpen: false,
                                                                                                        })
                                                                                                    }>
                                                                                                    <Button.Content visible>
                                                                                                        Cancel
                                                                                                    </Button.Content>
                                                                                                    <Button.Content hidden>
                                                                                                        <Icon name="delete" />
                                                                                                    </Button.Content>
                                                                                                </Button>
                                                                                            </div>
                                                                                        </Modal.Description>
                                                                                    </Modal.Content>
                                                                                </Modal>
                                                                            </div> */}

                                                                        </div>
                                                                        <div>
                                                                            <a
                                                                                rel="noopener noreferrer"
                                                                                target="_blank"
                                                                                className="color-light-blue fw-600"
                                                                                href={companyUrl}>
                                                                                {this.props.company.info &&
                                                                                    this.props.company.info.Item.url}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </Grid.Column>
                                                            <>
                                                                {
                                                                    auth &&
                                                                    <Grid.Column floated="right" textAlign="right" width={3}>

                                                                        <Dropdown
                                                                            trigger={
                                                                                <Icon size="large" name={'ellipsis horizontal'} />
                                                                            }>
                                                                            <Dropdown.Menu
                                                                                direction="right"
                                                                                style={{ borderRadius: '10px' }}>
                                                                                <Modal
                                                                                    open={this.state.modalCompanyOpen}
                                                                                    onClose={this.handleClose}
                                                                                    size={'large'}
                                                                                    trigger={
                                                                                        <Dropdown.Item
                                                                                            onClick={this.OpenUpdateCompany}
                                                                                            className="dropDownThreeDots"
                                                                                            text="Update Company"
                                                                                        />
                                                                                    }>
                                                                                    <Modal.Header>Update Company</Modal.Header>
                                                                                    <Modal.Content>
                                                                                        <Modal.Description>
                                                                                            <Form>
                                                                                                <Form.Field>
                                                                                                    <label>Name</label>
                                                                                                    <input
                                                                                                        value={
                                                                                                            this.state
                                                                                                                .updatedCompanyName
                                                                                                        }
                                                                                                        onChange={e =>
                                                                                                            this.setState({
                                                                                                                updatedCompanyName:
                                                                                                                    e.target.value,
                                                                                                            })
                                                                                                        }
                                                                                                    />
                                                                                                </Form.Field>
                                                                                                <Form.Field>
                                                                                                    <label>Address</label>
                                                                                                    <input
                                                                                                        value={
                                                                                                            this.state
                                                                                                                .updatedCompanyAddress
                                                                                                        }
                                                                                                        onChange={e =>
                                                                                                            this.setState({
                                                                                                                updatedCompanyAddress:
                                                                                                                    e.target.value,
                                                                                                            })
                                                                                                        }
                                                                                                    />
                                                                                                </Form.Field>
                                                                                                <Form.Field>
                                                                                                    <label>Url</label>
                                                                                                    <input
                                                                                                        value={
                                                                                                            this.state
                                                                                                                .updatedCompanyUrl
                                                                                                        }
                                                                                                        onChange={e =>
                                                                                                            this.setState({
                                                                                                                updatedCompanyUrl:
                                                                                                                    e.target.value,
                                                                                                            })
                                                                                                        }
                                                                                                    />
                                                                                                </Form.Field>
                                                                                                <Form.Field>
                                                                                                    <label>Country</label>
                                                                                                    <input
                                                                                                        value={
                                                                                                            this.state
                                                                                                                .updatedCompanyCountry
                                                                                                        }
                                                                                                        onChange={e =>
                                                                                                            this.setState({
                                                                                                                updatedCompanyCountry:
                                                                                                                    e.target.value,
                                                                                                            })
                                                                                                        }
                                                                                                    />
                                                                                                </Form.Field>
                                                                                                <Form.Field>
                                                                                                    <label>Description</label>
                                                                                                    <input
                                                                                                        value={
                                                                                                            this.state
                                                                                                                .updatedCompanyDescription
                                                                                                        }
                                                                                                        onChange={e =>
                                                                                                            this.setState({
                                                                                                                updatedCompanyDescription:
                                                                                                                    e.target.value,
                                                                                                            })
                                                                                                        }
                                                                                                    />
                                                                                                </Form.Field>
                                                                                            </Form>

                                                                                            <div
                                                                                                style={{
                                                                                                    textAlign: 'center',
                                                                                                    paddingTop: '20px',
                                                                                                }}>
                                                                                                <Button
                                                                                                    loading={
                                                                                                        this.state
                                                                                                            .addRawButtonLoading
                                                                                                    }
                                                                                                    primary
                                                                                                    animated
                                                                                                    className="background-light-blue"
                                                                                                    onClick={this.updateCompany.bind(
                                                                                                        this,
                                                                                                    )}>
                                                                                                    <Button.Content visible>
                                                                                                        Submit
                                                                                                    </Button.Content>
                                                                                                    <Button.Content hidden>
                                                                                                        <Icon name="right arrow" />
                                                                                                    </Button.Content>
                                                                                                </Button>
                                                                                                <Button
                                                                                                    animated
                                                                                                    color="red"
                                                                                                    onClick={() =>
                                                                                                        this.setState({
                                                                                                            modalCompanyOpen: false,
                                                                                                        })
                                                                                                    }>
                                                                                                    <Button.Content visible>
                                                                                                        Cancel
                                                                                                    </Button.Content>
                                                                                                    <Button.Content hidden>
                                                                                                        <Icon name="delete" />
                                                                                                    </Button.Content>
                                                                                                </Button>
                                                                                            </div>
                                                                                        </Modal.Description>
                                                                                    </Modal.Content>
                                                                                </Modal>
                                                                                <Modal
                                                                                    open={this.state.modalProductOpen}
                                                                                    onClose={this.handleProductClose}
                                                                                    size={'large'}
                                                                                    trigger={
                                                                                        <Dropdown.Item
                                                                                            onClick={this.handleProductOpen}
                                                                                            className="dropDownThreeDots"
                                                                                            text="Create Product"
                                                                                        />
                                                                                    }>
                                                                                    <Modal.Header>Create Product</Modal.Header>
                                                                                    <Modal.Content>
                                                                                        <Modal.Description>
                                                                                            <Form>
                                                                                                <Form.Field>
                                                                                                    <label>Name</label>
                                                                                                    <input
                                                                                                        placeholder="Name"
                                                                                                        ref="name"
                                                                                                    />
                                                                                                </Form.Field>
                                                                                                <Form.Field>
                                                                                                    <label>Description</label>
                                                                                                    <input
                                                                                                        placeholder="description"
                                                                                                        ref="desc"
                                                                                                    />
                                                                                                </Form.Field>
                                                                                            </Form>

                                                                                            <div
                                                                                                style={{
                                                                                                    textAlign: 'center',
                                                                                                    paddingTop: '20px',
                                                                                                }}>
                                                                                                <Button
                                                                                                    loading={
                                                                                                        this.state
                                                                                                            .addRawButtonLoading
                                                                                                    }
                                                                                                    primary
                                                                                                    animated
                                                                                                    className="background-light-blue"
                                                                                                    onClick={this.addCompany.bind(
                                                                                                        this,
                                                                                                    )}>
                                                                                                    <Button.Content visible>
                                                                                                        Submit
                                                                                                    </Button.Content>
                                                                                                    <Button.Content hidden>
                                                                                                        <Icon name="right arrow" />
                                                                                                    </Button.Content>
                                                                                                </Button>
                                                                                                <Button
                                                                                                    animated
                                                                                                    color="red"
                                                                                                    onClick={this.handleClose}>
                                                                                                    <Button.Content visible>
                                                                                                        Cancel
                                                                                                    </Button.Content>
                                                                                                    <Button.Content hidden>
                                                                                                        <Icon name="delete" />
                                                                                                    </Button.Content>
                                                                                                </Button>
                                                                                            </div>
                                                                                        </Modal.Description>
                                                                                    </Modal.Content>
                                                                                </Modal>

                                                                                <Dropdown.Item
                                                                                    className="dropDownThreeDots update-image"
                                                                                    text="Update Company Logo">
                                                                                    <label for="update-company--logo">
                                                                                        Update company logo
                                                                                    </label>
                                                                                    <input
                                                                                        id="update-company--logo"
                                                                                        type="file"
                                                                                        onChange={this.uploadLogo}
                                                                                    />
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </Grid.Column>
                                                                }
                                                            </>

                                                        </>
                                                    </Grid.Row>
                                                }
                                            </Grid>

                                            {


                                                currentView === "companyDetails" &&
                                                <div className="col-sm-12">
                                                    <ul className="connection-subhead">
                                                        <li onClick={() => this.handleViewChange("followers")}><span>{(company.followers && company.followers.followers.length) || 0}</span> Followers</li>
                                                        {/* <li><span>14</span> Connections in your network</li> */}
                                                        <li className="followuser" onClick={() => this.handleViewChange("invite")}><i className="user icon" /> Invite More</li>
                                                    </ul>
                                                </div>
                                            }

                                            {
                                                currentView === "followers" ?
                                                    <Followers followers={this.props.company.followers} auth={auth} />
                                                    :
                                                    currentView === "invite" ?
                                                        <InviteView type="company" id={companyId} />
                                                        :

                                                        <>
                                                            <Grid padded>
                                                                <Grid.Row className="extra-padding-sides">
                                                                    <Grid.Column width={16}>
                                                                        {this.props.company.info &&
                                                                            this.props.company.info.Item.description}
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                            <Grid padded divided>
                                                                <Grid.Row className="extra-padding-sides">
                                                                    <Grid.Column mobile={16} tablet={8} computer={8}>
                                                                        <div className="state-head">Company Address</div>
                                                                        <div className="state-address">
                                                                            {this.props.company.info &&
                                                                                this.props.company.info.Item.address}
                                                                        </div>
                                                                    </Grid.Column>
                                                                    <Grid.Column
                                                                        className="cursor-pointer"
                                                                        mobile={16}
                                                                        tablet={4}
                                                                        computer={4}
                                                                        onClick={() => this.scrollToTable('contacts')}>
                                                                        <div className="state-head">Listed Contacts</div>
                                                                        <div className="state-mid">
                                                                            {this.state.employees && this.state.employees.Items
                                                                                ? this.state.employees.Items.length
                                                                                : 0}
                                                                        </div>
                                                                    </Grid.Column>
                                                                    <Grid.Column
                                                                        className="cursor-pointer"
                                                                        mobile={16}
                                                                        tablet={4}
                                                                        computer={4}
                                                                        onClick={() => this.scrollToTable('suppliedProducts')}>
                                                                        <div className="state-head">Listed Products</div>
                                                                        <div className="state-mid">
                                                                            {this.state.suppliedProducts &&
                                                                                this.state.suppliedProducts.Items
                                                                                ? this.state.suppliedProducts.Items.length
                                                                                : 0}
                                                                        </div>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>

                                                            <Grid
                                                                padded
                                                                className="network-feed-summary"
                                                                style={{ borderTop: 'solid 8px white' }}>
                                                                <Grid.Row>
                                                                    <NetworkFeedSummary
                                                                        entityType="company"
                                                                        entityId={companyId}
                                                                        entityName={companyName}
                                                                        activity={this.props.networkFeed.companyFeedSummary}
                                                                        history={this.props.history}
                                                                    />
                                                                </Grid.Row>
                                                            </Grid>

                                                            <Grid padded>
                                                                <Grid.Row className="background-white extra-padding-sides pD-b-3">
                                                                    {/* {
                                                                this.props.networkFeed.companyFeed && */}
                                                                    {/* <NetworkFeedSummary entityType="company" entityId={companyId} entityName={companyName} activity={this.props.networkFeed.companyFeedSummary} history={this.props.history} /> */}
                                                                    {/* } */}

                                                                    <Grid.Column width={16}>
                                                                        <Grid>
                                                                            <Grid.Column floated="left" width={13}>
                                                                                <div
                                                                                    ref={element => {
                                                                                        this.contacts = element;
                                                                                    }}>
                                                                                    <Header as={'h4'} className="table-heading pD-b-10">
                                                                                        Contacts
                                                                                    </Header>
                                                                                </div>
                                                                            </Grid.Column>
                                                                        </Grid>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={16}>
                                                                        <Divider className="tableDivider" />
                                                                    </Grid.Column>
                                                                    <Grid.Column width={16}>
                                                                        <Table padded basic="very" striped unstackable>
                                                                            <Table.Header>
                                                                                <Table.Row>
                                                                                    <Table.HeaderCell className="color-light-blue">
                                                                                        Name
                                                                                    </Table.HeaderCell>
                                                                                    <Table.HeaderCell className="color-light-blue">
                                                                                        Designation
                                                                                    </Table.HeaderCell>
                                                                                    {/* <Table.HeaderCell className="color-light-blue">Number of Companies</Table.HeaderCell> */}
                                                                                    <Table.HeaderCell
                                                                                        textAlign="center"
                                                                                        className="color-light-blue">
                                                                                        Send Message
                                                                                    </Table.HeaderCell>
                                                                                </Table.Row>
                                                                            </Table.Header>

                                                                            <Table.Body>
                                                                                {this.state.employees && this.renderEmployees()}
                                                                            </Table.Body>
                                                                        </Table>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row className="background-white extra-padding-sides pD-b-3">
                                                                    <Grid.Column width={16}>
                                                                        <Grid>
                                                                            <Grid.Column floated="left" width={13}>
                                                                                <div
                                                                                    ref={element => {
                                                                                        this.suppliedProducts = element;
                                                                                    }}>
                                                                                    <Header as={'h4'} className="table-heading pD-b-10">
                                                                                        Supplied Products
                                                                                    </Header>
                                                                                </div>
                                                                            </Grid.Column>
                                                                            <Grid.Column textAlign="right" floated="right" width={3}>
                                                                                <Dropdown
                                                                                    direction="right"
                                                                                    trigger={
                                                                                        <Icon
                                                                                            className="color-light-blue pD-t-15"
                                                                                            size="large"
                                                                                            name={'ellipsis horizontal'}
                                                                                        />
                                                                                    }>
                                                                                    <Dropdown.Menu
                                                                                        direction="right"
                                                                                        style={{ borderRadius: '10px' }}>
                                                                                        <Modal
                                                                                            open={this.state.modalAddProductOpen}
                                                                                            onClose={this.handleAddProductClose}
                                                                                            size={'large'}
                                                                                            trigger={
                                                                                                <Dropdown.Item
                                                                                                    onClick={this.handleAddProductOpen}
                                                                                                    className="dropDownThreeDots"
                                                                                                    text="Add Product"
                                                                                                />
                                                                                            }>
                                                                                            <Modal.Header>
                                                                                                Add supplied products
                                                                                            </Modal.Header>
                                                                                            <Modal.Content>
                                                                                                <Modal.Description>
                                                                                                    <Grid>
                                                                                                        <Grid.Column
                                                                                                            width={2}></Grid.Column>
                                                                                                        <Grid.Column width={12}>
                                                                                                            <Grid>
                                                                                                                <Grid.Column width={7}>
                                                                                                                    <Search
                                                                                                                        fluid
                                                                                                                        loading={
                                                                                                                            isLoading
                                                                                                                        }
                                                                                                                        onResultSelect={
                                                                                                                            this
                                                                                                                                .handleResultSelect
                                                                                                                        }
                                                                                                                        onSearchChange={
                                                                                                                            this
                                                                                                                                .handleSearchChange
                                                                                                                        }
                                                                                                                        results={
                                                                                                                            results
                                                                                                                        }
                                                                                                                        value={value}
                                                                                                                        resultRenderer={
                                                                                                                            resultRenderer
                                                                                                                        }
                                                                                                                    />
                                                                                                                </Grid.Column>
                                                                                                                <Grid.Column width={7}>
                                                                                                                    <Input
                                                                                                                        placeholder="Desription"
                                                                                                                        fluid
                                                                                                                        value={
                                                                                                                            description
                                                                                                                        }
                                                                                                                        onChange={e =>
                                                                                                                            this.setState(
                                                                                                                                {
                                                                                                                                    description:
                                                                                                                                        e
                                                                                                                                            .target
                                                                                                                                            .value,
                                                                                                                                },
                                                                                                                            )
                                                                                                                        }
                                                                                                                    />
                                                                                                                </Grid.Column>
                                                                                                                <Grid.Column width={2}>
                                                                                                                    <Button
                                                                                                                        style={{ width: '40px' }}
                                                                                                                        icon
                                                                                                                        primary
                                                                                                                        onClick={
                                                                                                                            this
                                                                                                                                .handleAddProduct
                                                                                                                        }>
                                                                                                                        <Icon name="add" />
                                                                                                                    </Button>
                                                                                                                </Grid.Column>
                                                                                                            </Grid>
                                                                                                            <List
                                                                                                                style={{
                                                                                                                    paddingTop: '20px',
                                                                                                                }}
                                                                                                                animated
                                                                                                                divided
                                                                                                                verticalAlign="middle">
                                                                                                                {this.renderRawMaterials()}
                                                                                                            </List>
                                                                                                        </Grid.Column>
                                                                                                        <Grid.Column
                                                                                                            width={2}></Grid.Column>
                                                                                                    </Grid>

                                                                                                    <div
                                                                                                        style={{
                                                                                                            textAlign: 'center',
                                                                                                            paddingTop: '20px',
                                                                                                        }}>
                                                                                                        <Button
                                                                                                            loading={
                                                                                                                this.state
                                                                                                                    .addRawButtonLoading
                                                                                                            }
                                                                                                            primary
                                                                                                            animated
                                                                                                            className="background-light-blue"
                                                                                                            onClick={this.addRawMaterials.bind(
                                                                                                                this,
                                                                                                            )}>
                                                                                                            <Button.Content visible>
                                                                                                                Submit
                                                                                                            </Button.Content>
                                                                                                            <Button.Content hidden>
                                                                                                                <Icon name="right arrow" />
                                                                                                            </Button.Content>
                                                                                                        </Button>
                                                                                                        <Button
                                                                                                            animated
                                                                                                            color="red"
                                                                                                            onClick={
                                                                                                                this
                                                                                                                    .handleAddProductClose
                                                                                                            }>
                                                                                                            <Button.Content visible>
                                                                                                                Cancel
                                                                                                            </Button.Content>
                                                                                                            <Button.Content hidden>
                                                                                                                <Icon name="delete" />
                                                                                                            </Button.Content>
                                                                                                        </Button>
                                                                                                    </div>

                                                                                                    {/* <button onClick={this.addRawMaterials.bind(this)}>Done</button> */}
                                                                                                </Modal.Description>
                                                                                            </Modal.Content>
                                                                                        </Modal>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </Grid.Column>
                                                                        </Grid>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={16}>
                                                                        <Divider className="tableDivider" />
                                                                    </Grid.Column>
                                                                    <Grid.Column width={16}>
                                                                        <Table padded basic="very" striped unstackable>
                                                                            <Table.Header>
                                                                                <Table.Row>
                                                                                    <Table.HeaderCell className="color-light-blue">
                                                                                        Product Name
                                                                                    </Table.HeaderCell>
                                                                                    {/* <Table.HeaderCell className="color-light-blue">Number of Companies</Table.HeaderCell> */}
                                                                                    {/* <Table.HeaderCell textAlign='center' className="color-light-blue">Send Enquiry</Table.HeaderCell> */}
                                                                                </Table.Row>
                                                                            </Table.Header>

                                                                            <Table.Body>
                                                                                {this.state.suppliedProducts &&
                                                                                    this.renderSuppliedProducts()}
                                                                            </Table.Body>
                                                                        </Table>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </>
                                            }
                                            {/* <Grid>
                                            <Grid.Column floated='left' width={14}>
                                                <Header className="table-heading" >Supplied products</Header>
                                            </Grid.Column>
                                            <Grid.Column floated='right' width={2}>
                                                <Icon className="color-light-blue" size='big' name={'ellipsis horizontal'} />
                                            </Grid.Column>
                                        </Grid>
                                        <Divider />
                                        <Table color='grey' padded={true} basic='very'>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell className="color-light-blue" >Name</Table.HeaderCell>
                                                    <Table.HeaderCell className="color-light-blue">Country</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell>Optical Fibre</Table.Cell>
                                                    <Table.Cell></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>Optical Fibre</Table.Cell>
                                                    <Table.Cell></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>Optical Fibre</Table.Cell>
                                                    <Table.Cell></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>Optical Fibre</Table.Cell>
                                                    <Table.Cell></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>Optical Fibre</Table.Cell>
                                                    <Table.Cell></Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table> */}
                                        </div>
                                    )}
                                </div>
                            </Grid.Column>
                            <RightSection entityType="company" entityId={companyId} />
                        </Grid>
                    </Container >
                </div >
                {/* <div className="other-page-login-bar">
                    <LoginBar
                        className="other-page-login-bar"
                        location={this.props.location}
                        loginModelOpenCallback={() => {
                            this.setState({ modalOpen: false });
                        }}
                    />
                </div> */}
            </Navbar >
        );
    }
}

function mapStateToProps({ singleCompany, auth, chatUser, networkFeed }) {
    return { company: singleCompany, auth, chatUser, networkFeed };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({ ...actions, ...networkFeedActions, ...commonActions }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Company);
